<template>
  <div class="flex ctx">
    <div class="submenus">
      <div :class="{'flex':true, 'submenu':true, 'cursubmenu':$route.name=='profile'}" @click="$router.push('/profile')">
        <div class="smicon"><img :src="iconSmprofile"/></div>
        <div class="smtext">My profile</div>
      </div>
      <div :class="{'flex':true, 'submenu':true, 'cursubmenu':$route.name=='points'}" @click="$router.push('/points')">
        <div class="smicon"><img :src="iconSmpoints"/></div>
        <div class="smtext">My Points</div>
      </div>
      <div class="line"></div>
      <div class="flex submenu disablesubmenu">
        <div class="smicon"><img :src="iconSmetaworld"/></div>
        <div class="smtext">Eta World</div>
        <div class="soon">soon</div>
      </div>
      <div class="flex submenu disablesubmenu">
        <div class="smicon"><img :src="iconSmnfts"/></div>
        <div class="smtext">NFTs</div>
        <div class="soon">soon</div>
      </div>
      <div class="flex submenu disablesubmenu">
        <div class="smicon"><img :src="iconSmsearch"/></div>
        <div class="smtext">Search</div>
        <div class="soon">soon</div>
      </div>
    </div>
    <div class="subcontent">
      <router-view/>
    </div>
  </div>
</template>
<script>
import iconSmProfile from "../assets/imgs/icon_sm_profile.png";
import iconSmProfileCur from "../assets/imgs/icon_sm_profile_cur.png";
import iconSmPoint from "../assets/imgs/icon_sm_point.png";
import iconSmPointCur from "../assets/imgs/icon_sm_point_cur.png";
import iconSmEtaworld from "../assets/imgs/icon_sm_etaworld.png";
import iconSmEtaworldCur from "../assets/imgs/icon_sm_etaworld_cur.png";
import iconSmEtaworldDis from "../assets/imgs/icon_sm_etaworld_dis.png";
import iconSmNfts from "../assets/imgs/icon_sm_nfts.png";
import iconSmNftsCur from "../assets/imgs/icon_sm_nfts_cur.png";
import iconSmNftsDis from "../assets/imgs/icon_sm_nfts_dis.png";
import iconSmSearchaddr from "../assets/imgs/icon_sm_searchaddr.png";
import iconSmSearchaddrCur from "../assets/imgs/icon_sm_searchaddr_cur.png";
import iconSmSearchaddrDis from "../assets/imgs/icon_sm_searchaddr_dis.png";

export default {
  name:"account",
  data(){
    return {
      iconSmprofile:iconSmProfileCur,
      iconSmpoints:iconSmPoint,
      iconSmetaworld:iconSmEtaworldDis,
      iconSmnfts:iconSmNftsDis,
      iconSmsearch:iconSmSearchaddrDis,
      activesubIcon:{"profile":iconSmProfileCur, "points":iconSmPointCur, "etaworld":iconSmEtaworldCur, "search":iconSmSearchaddrCur},

    }
  },
  watch:{
    "$route.name":function (newVal) {
      this.resetSubMenuIcon(newVal);
    }
  },
  mounted(){
    this.resetSubMenuIcon(this.$route.name);
  },
  methods:{

    resetSubMenuIcon(routeName){
      this.iconSmprofile=iconSmProfile;
      this.iconSmpoints = iconSmPoint;
      this.iconSmetaworld = iconSmEtaworldDis;
      this.iconSmnfts = iconSmNftsDis;
      this.iconSmsearch=iconSmSearchaddrDis;

      this['iconSm'+routeName]=this.activesubIcon[routeName];
    }
  }
}
</script>
<style lang="less" scoped>
.ctx{
  width: 1180px;
  height: calc(~"100vh - 130px");
  margin: 20px auto 0;
  justify-content: flex-start;
  align-items: flex-start;
  .submenus{
    width: 276px;
    height: 403px;
    background: url("../assets/imgs/bg_submenu.png") no-repeat;
    -webkit-background-size: contain;
    background-size: contain;
    background-position: center;
    padding-top: 65px;
    padding-left: 22px;
    border-radius: 10px;
    .submenu{
      width: 256px;
      height: 64px;
      padding: 22px 30px;
      border-radius: 13px;
      color: #5F5FA3;
      justify-content: flex-start;
      font-size: 17px;
      cursor: pointer;
      .smicon{
        >img{
          width: 19px;
        }
      }
      .smtext{
        margin-left: 32px;
      }
      .soon{
        width: 53px;
        height: 19px;
        margin-left: 10px;
        font-size: 17px;
        text-align: center;
        line-height: 17px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        background: #262643;
        color: #5F5FA3;
      }
    }
    .submenu:hover{
      background: rgba(#5F5FA3, 0.2);
    }
    .cursubmenu{
      background: rgba(#5F5FA3, 0.2);
      color: #36BAC6;
    }
    .disablesubmenu{
      color: #474756;
    }
    .line{
      height: 1px;
      margin-top: 10px;
      margin-bottom: 10px;
      background: #414183;
    }
  }
  .subcontent{
    width: 100%;
    min-height: calc(~"100vh - 130px");
  }
}
@media only screen and (max-width: 670px) {
.ctx{
  width: 100%;
  height: max-content;
  .submenus{
    display: none;
  }
}
}
</style>

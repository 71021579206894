<template>
  <div class="points">
    <div class="flex data">
      <div class="poiinfo">
<!--        <div class="ptitle">My Profile</div>-->
        <div class="flex proindetail">
          <div class="portrait"><img src="@/assets/imgs/icon_portrait.png"/></div>
          <div class="addrinfo">
            <div class="domain">ENS:{{$store.state.walletInfo.name}}</div>
            <div class="address" id="copyAddress">
              <span>{{$store.state.walletInfo.simpleAddress}}</span>
              <img src="@/assets/imgs/icon_copy.png"/>
            </div>
          </div>
        </div>
      </div>
      <div class="friends">
        <div class="flex fdata">
          <div class="fdataitem">
            <div class="fdititle">My Points</div>
            <div class="fditext">
              <span>{{ myPoints }}</span>
              <img class="qusmark" @click="showTip($event)" src="@/assets/imgs/icon_qusmark.png"/>
            </div>
          </div>
          <div class="fdataitem">
            <div class="fdititle">My Invites</div>
            <div class="fditext textCenter">{{ myInvites }}</div>
          </div>
          <div class="fdataitem">
            <div class="fdititle">My Rank</div>
            <div class="fditext textRight"># {{ myRank }}</div>
          </div>
        </div>
        <div class="btnearnmore" @click="showInviteLink">Earn More Points</div>

        <div class="tip">
          <div class="direction"></div>
          <div class="ttctx">
            <p>1. Points are obtained from transactions, and the rules vary according to different stages;</p>
            <p>2. Get 10,000 points for every friend you invite;</p>
            <p>3. You can get 30% of the points gained from friend interaction.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="friendslist">
      <div class="ftitle">My Friends</div>
      <div class="ftable">
        <div class="theader">
          <div class="flex tr">
            <div class="th trank">Rank</div>
            <div class="th taddress textCenter">Address</div>
            <div class="th tinvitepoints textCenter">Invite Points</div>
            <div class="th ttranspoints textCenter">Transaction Points</div>
            <div class="th ttranstime textRight">Last transaction time</div>
          </div>
        </div>
        <div class="tbody" v-if="friendsList.length>0">
          <div class="flex tr" v-for="(item, index) in friendsList" :key="index">
            <div class="td trank">{{item.rank}}</div>
            <div class="td taddress textCenter">{{item.simpleAddress}}</div>
            <div class="td tinvitepoints textCenter">{{item.invitePoint}}</div>
            <div class="td ttranspoints textCenter">{{item.transPoint}}</div>
            <div class="td ttranstime textRight">{{item.lastTransTime}}</div>
          </div>
        </div>
        <div class="flex nodata" v-if="friendsList.length<=0">
          <div class="nodataicon"><img src="@/assets/imgs/icon_nodata.png"/></div>
          <div class="nodatadesc">You have no transaction records yet</div>
        </div>
      </div>
    </div>


    <div class="minfo">
      <div class="massest">
        <div class="flex masrow">
          <div class="masitem">
            <div class="mattext">{{ myPoints }}</div>
            <div class="matdesc">Total ETA Points</div>
          </div>
          <div class="masitem">
            <div class="mattext">11</div>
            <div class="matdesc">Transactions</div>
          </div>
        </div>
        <div class="flex masrow">
          <div class="masitem">
            <div class="mattext"># {{ myRank }}</div>
            <div class="matdesc">My Rank</div>
          </div>
          <div class="masitem">
            <div class="mattext">{{ myInvites }}</div>
            <div class="matdesc">My invites</div>
          </div>
        </div>
      </div>

      <div class="invirules">
        <div class="ittitle">Rules:</div>
        <div class="itcontent">
          <p>1.Invite a friend to get 10000 points;</p>
          <p>2.30% of the points earned by friends from transactions will be credited to your points;</p>
          <p>3.Get 10,000 points for trading every week;</p>
        </div>
      </div>

      <div class="mbtninvite">Earn ETA Points with invites</div>

      <div class="minvites">
        <div class="mtitle">My Invites</div>
        <div class="mtable">
          <div class="mtheader">
            <div class="flex mtr">
              <div class="mth">Address</div>
              <div class="mth">ETA Points</div>
            </div>
          </div>
          <div class="mtbody" v-if="friendsList.length>0">
            <div class="flex mtr" v-for="(item, index) in friendsList" :key="index">
              <div class="mtd">{{item.simpleAddress}}</div>
              <div class="mtd">{{item.invitePoint +item.transPoint}}</div>
            </div>
          </div>
          <div class="flex nodata" v-if="friendsList.length<=0">
            <div class="nodataicon"><img src="@/assets/imgs/icon_nodata.png"/></div>
            <div class="nodatadesc">You have no transaction records yet</div>
          </div>
        </div>
      </div>
    </div>

    <transition name="fadein" v-on:enter="++copyInviteLinkKey">
      <copy-invite-link v-show="copyInviteLinkCpFlag" v-on:close="copyInviteLinkCpFlag=false" :copyInviteLinkKey="copyInviteLinkKey"/>
    </transition>

  </div>
</template>
<script>
import $ from "jquery";
import {mapState, mapActions} from 'vuex'

import * as stringUtils from "@/assets/js/stringutils";

import CopyInviteLink from "../components/CopyInviteLink";
export default {
  name:"points",
  components: {CopyInviteLink},
  data(){
    return {
      myPoints:0,
      myInvites:0,
      myRank:0,

      tipFlag:false,
      friendsList:[],

      copyInviteLinkKey:0,
      copyInviteLinkCpFlag:false,
    }
  },
  computed: {
    ...mapState(['walletInfo'])
  },
  watch:{
    walletInfo: function (walletInfo) {
      this.initInfo();
    },
  },
  mounted(){
    this.initInfo();
  },
  methods:{
    initInfo(){
      this.getUserInfo();
      this.initCopyAddress();
      this.getFriendsInfo(1);
    },

    showTip(event){
      if(this.tipFlag){
        return ;
      }
      $(".tip").show();
      this.tipFlag=true;
      setTimeout(()=>{
        $(".tip").hide();
        this.tipFlag=false;
      },3000);
    },
    initCopyAddress(){
      const ClipboardJS=require("clipboard");
      var copyLinkBtn =new ClipboardJS('#copyAddress',{text:(trigger)=>{
          if(stringUtils.isEmpty(this.$store.state.walletInfo.address)){
            this.$store.dispatch("showMsg","Please connect wallet.");
            return "";
          }
          return this.$store.state.walletInfo.address;
        }
      });
      copyLinkBtn.on("success", (e)=>{
        this.$store.dispatch("showMsg","Copy success.");
      });
    },
    getUserInfo(){
      const address=this.$store.state.walletInfo.address;
      if(stringUtils.isEmpty(address)){
        return ;
      }
      $.ajax({
        url:this.$appConfig.queryCenter().url+this.$appConfig.queryUrl().getUserInfo,
        data:{"address":address},
        success:(result)=>{
          if(result.status=="200"){
            const userInfo=result.data;
            this.myPoints=userInfo.point;
            this.myRank=userInfo.rank;
            this.myInvites=userInfo.inviteNum;
          }
        }
      })
    },
    getFriendsInfo(pageNum){
      const address=this.$store.state.walletInfo.address;
      if(stringUtils.isEmpty(address)){
        return ;
      }
      $.ajax({
        url:this.$appConfig.queryCenter().url+this.$appConfig.queryUrl().getInviteList,
        data:{"address":address, "pageNum":pageNum},
        success:(result)=>{
          if(result.status=="200"){
            const resultData=result.data;
            for(var i=0;i<resultData.length;i++){
              const x=resultData[i];
              x.simpleAddress=stringUtils.formatAddress(x.address);
              this.friendsList.push(x);
            }
          }
        }
      })
    },
    showInviteLink(){
      if(stringUtils.isEmpty(this.$store.state.walletInfo.address)){
        this.$store.dispatch("showMsg","Please connect wallet.");
        return ;
      }
      this.copyInviteLinkCpFlag=true;
    }
  }
}
</script>
<style lang="less" scoped>
.points{
  margin-left: 45px;
  .data{
    width: 860px;
    .poiinfo{
      width: 420px;
      height: 150px;
      background: #1D1D3C;
      border-radius: 21px;
      .ptitle{
        display: none;
      }
      .proindetail{
        width: 100%;
        height: 100%;
        padding-left: 20px;
        justify-content: flex-start;
        .portrait{
          >img{
            width: 64px;
          }
        }
        .addrinfo{
          margin-left: 20px;
          font-size: 13px;
          >div{
            height: 32px;
          }
          .address{
            cursor: pointer;
            >img{
              margin-left: 10px;
              width: 26px;
            }
          }
        }
      }
    }
    .friends{
      width: 420px;
      height: 150px;
      padding: 17px 20px;
      background: #1D1D3C;
      border-radius: 21px;
      .fdata{
        .fdataitem{
          .fdititle{
            color: #5F5FA3;
            font-size: 13px;
            line-height: 21px;
          }
          .fditext{
            font-size: 19px;
            line-height: 32px;
            .qusmark{
              margin-left: 10px;
              width: 17px;
            }
          }
        }
      }
      .btnearnmore{
        width: 100%;
        height: 45px;
        margin-top: 10px;
        background: #2B97A1;
        border-radius: 9px;
        font-size: 15px;
        text-align: center;
        line-height: 45px;
        cursor: pointer;
      }
    }
  }
  .invitips{
    display: none;
  }

  .friendslist{
    min-height: 500px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 18px;
    padding-bottom: 18px;
    background: #1D1D3C;
    border-radius: 21px;
    .ftitle{
      padding-left:  20px;
      padding-right:  20px;
      font-size: 21px;
      font-weight: 500;
    }
    .ftable{
      margin-top: 10px;
      .theader, .tbody{
        padding-left:  20px;
        padding-right:  20px;
        .tr{
          height: 30px;
          font-size: 13px;
          line-height: 30px;
          .th{
            color: #7676B1;
          }
          .trank{
            width: 60px;
          }
          .taddress, .tinvitepoints, .ttranstime, .ttranspoints{
            width: 180px;
          }
        }
      }
      .tbody{
        max-height: calc(~"100vh - 420px");
        overflow-y: auto;
      }
      .nodata{
        margin-top: 150px;
        flex-direction: column;
        .nodataicon{
          >img{
            width: 64px;
          }
        }
        .nodatadesc{
          line-height: 32px;
        }
      }
    }
  }
  .minfo{
    display: none;
  }
  .tip{
    display: none;
    margin-top: -50px;
    position: absolute;
    font-size: 12px;
    z-index: 110;
    .direction{
      width: 0;
      height: 0;
      margin-left: 80px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #414183;
    }
    .ttctx{
      width: 250px;
      padding: 10px;
      background: #414183;
      border-radius: 6px;
    }
  }
}

@media only screen and (max-width: 670px) {
@screenWidth: calc(~"100vw - 40px");
.points{
  margin-left:0;
  margin-bottom: 40px;
  .data{
    width: 100%;
    .poiinfo{
      width: 100%;
      height: max-content;
      background: none;
      .ptitle{
        width: 100%;
        height: 40px;
        margin-bottom: 20px;
        line-height: 20px;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        display: block;
      }
    }
    .friends{
      display: none;
    }
  }
  .friendslist{
    display: none;
  }

  .minfo{
    width: @screenWidth;
    margin: 40px auto 0;
    display: block;
    .massest{
      width: 100%;
      min-height: 190px;
      padding: 10px 20px;
      background: #1D1D3C;
      border-radius: 20px;
      .masrow{
        margin-top: 30px;
        .masitem{
          line-height: 20px;
          .mattext{
            font-size: 18px;
          }
          .matdesc{
            font-size: 12px;
            color: #7676B1;
          }
        }
      }
    }
    .invirules{
      margin-top: 20px;
      display: block;
      .ittitle{
        font-size: 14px;
        font-weight: 500;
        color: #E6E6FF;
      }
      .itcontent{
        font-size: 12px;
        color: #7676B1;
        line-height: 20px;
      }
    }
    .mbtninvite{
      height: 50px;
      margin-top: 10px;
      text-align: center;
      line-height: 50px;
      font-size: 16px;
      background: #2B97A1;
      border-radius: 8px;
    }
    .minvites{
      width: 100%;
      height: 500px;
      margin-top: 20px;
      background: #1D1D3C;
      border-radius: 20px;
      padding-top: 18px;
      .mtitle{
        padding-left: 20px;
        padding-right: 20px;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 10px;
      }
      .mtable{
        .mtheader, .mtbody{
          .mtr{
            height: 30px;
            line-height: 30px;
            padding-left: 20px;
            padding-right: 20px;
            font-size: 12px;
            .mth{
              color: #7676B1;
            }
          }
        }
        .mtbody{
          height: 390px;
          overflow-y: auto;
        }
        .nodata{
          flex-direction: column;
          font-size: 12px;
          background: #1D1D3C;
          .nodataicon{
            margin-top: 80px;
            >img{
              width: 40px;
            }
          }
          .nodatadesc{
            line-height: 30px;
            color: #5F5FA3;
          }
        }
      }
    }
  }
 }
}
</style>

<template>
  <div class="profile">
    <div class="flex data">
      <div class="proinfo">
<!--        <div class="ptitle">My Profile</div>-->
        <div class="flex proindetail">
          <div class="portrait"><img src="@/assets/imgs/icon_portrait.png"/></div>
          <div class="addrinfo">
            <div class="domain">ENS: {{$store.state.walletInfo.name}}</div>
            <div class="address" id="copyAddress">
              <span>{{$store.state.walletInfo.simpleAddress}}</span>
              <img src="@/assets/imgs/icon_copy.png"/>
            </div>
          </div>
        </div>
        </div>
      <div class="assets">
        <div class="fdata">
          <div class="assdesc">Total Crypto Asset Value  on Arbitrum</div>
          <div class="flex assdetail">
            <div class="asstext">~ US$ 0</div>
            <div class="flex synass">
              <div class="point"></div>
              <div class="synced">SYNCED</div>
              <div class="synicon"><img src="@/assets/imgs/icon_refresh.png"/></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="flex proftab">
        <div @click="curTab='trans'" :class="{'ptitem':true, 'curptitem':curTab=='trans'}">Transactions</div>
        <div :class="{'ptitem':true, 'curptitem':curTab=='overview'}">Overview</div>
      </div>

      <div class="translist" v-if="curTab=='trans'">
        <div class="transctx" v-if="transList.length>0">
          <div :class="{'flex':true, 'tranitemctx':true, 'evenline':index%2!=0}" v-for="(item, index) in transList" :key="index">
            <div class="flex tranitem">
              <div class="tdate">{{item.timestamp}}</div>
              <div class="tcontent">{{item.desc}}</div>
            </div>
            <div class="scan" @click="toScan(item.txnHash)"><img src="@/assets/imgs/icon_more.png"/></div>
          </div>
        </div>
        <div class="flex nodata" v-if="transList.length<=0">
          <div class="nodataicon"><img src="@/assets/imgs/icon_nodata.png"/></div>
          <div class="nodatadesc">You have no transaction records yet</div>
        </div>
      </div>

      <div class="overview" v-if="curTab=='overview'">
        <div class="flex chains">
          <div class="flex chainitem curchainitem">
            <div class="chainlogo"><img src="@/assets/imgs/icon_allchain.png"/></div>
            <div class="chainname">All</div>
          </div>
          <div class="flex chainitem">
            <div class="chainlogo"><img src="@/assets/imgs/icon_chaineth.png"/></div>
            <div class="chainname">Ethereum</div>
          </div>
          <div class="flex chainitem">
            <div class="chainlogo"><img src="@/assets/imgs/icon_chainarbitrum.png"/></div>
            <div class="chainname">Arbitrum</div>
          </div>
          <div class="flex chainitem">
            <div class="chainlogo"><img src="@/assets/imgs/icon_chainpolygon.png"/></div>
            <div class="chainname">Polygon</div>
          </div>
          <div class="flex chainitem">
            <div class="chainlogo"><img src="@/assets/imgs/icon_chainbsc.png"/></div>
            <div class="chainname">BNB Chain</div>
          </div>
          <div class="flex chainitem">
            <div class="chainlogo"><img src="@/assets/imgs/icon_chainoptimistic.png"/></div>
            <div class="chainname">Optimism</div>
          </div>
        </div>

        <div class="assetstitle">Wallet</div>
        <div class="wallettable">
          <div class="wtheader">
            <div class="wtr">
              <div class="wth">Token</div>
              <div class="wth">Balance</div>
              <div class="wth">Price</div>
              <div class="wth">Value</div>
            </div>
          </div>
          <div class="wtbody">

          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import {mapState, mapActions} from 'vuex';
import { createWatcher } from "makerdao-multicall";

import * as stringUtils from "@/assets/js/stringutils";
import {arrChainInfo} from "@/assets/js/chaininfo.js";
import {assetsTokens} from "@/config/tokenAssets.js";

export default {
  name:"profile",
  data(){
    return {
      curTab:"trans",
      transList:[],
      // watch:{},
    }
  },
  computed: {
    ...mapState(['walletInfo'])
  },
  watch:{
    walletInfo: function (walletInfo) {
      this.initCopyAddress();
      this.loadTransList();

      this.loadAssets();
    },
  },
  mounted(){
    this.initCopyAddress();

    this.loadTransList();

    this.loadAssets();
  },
  methods:{
    initCopyAddress(){
      const ClipboardJS=require("clipboard");
      var copyLinkBtn =new ClipboardJS('#copyAddress',{text:(trigger)=>{
          if(stringUtils.isEmpty(this.$store.state.walletInfo.address)){
            this.$store.dispatch("showMsg","Please connect wallet.");
            return "";
          }
          return this.$store.state.walletInfo.address;
        }
      });
      copyLinkBtn.on("success", (e)=>{
        this.$store.dispatch("showMsg","Copy succeeded.");
      });
    },
    loadTransList(){
      if(stringUtils.isEmpty(this.$store.state.walletInfo.address)){
        return ;
      }
      this.transList=[];
      let transRecordsInStore=localStorage.getItem("transRecords"+this.$store.state.walletInfo.chainCode+this.$store.state.walletInfo.address);
      if(!stringUtils.isEmpty(transRecordsInStore)){
        let transRecords=JSON.parse(transRecordsInStore);
        for(var i=0;i<transRecords.length;i++){
          var record=transRecords[i];
          this.transList.push({"txnHash":record.txnHash, "timestamp":stringUtils.toUsDateTime(record.timestamp), "desc":"Swap "+record.swapTokenAAmount+" "+record.swapTokenA.symbol+" to "+record.swapTokenBAmount+" "+record.swapTokenB.symbol});
        }
      }
    },
    toScan(txnHash){
      window.open(arrChainInfo[this.$store.state.walletInfo.chainCode].info.blockExplorerUrls[0]+"/tx/"+txnHash);
    },
    async loadAssets(){
      if(stringUtils.isEmpty(this.$store.state.walletInfo.address)){
        return ;
      }
      for(var i=0;i<assetsTokens.length;i++){
        let queryList=[];
        let chainTokens=assetsTokens[i];
        chainTokens.watcher={};
        console.info("begin to load chain:"+chainTokens.chainCode+" assets");
        for(var j=0;j<chainTokens.tokens.length;j++){
          let tokenInfo=chainTokens.tokens[j];
          if(tokenInfo.address=='0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'){
            continue;
          }
          queryList.push({target:tokenInfo.address, call:['balanceOf(address)(uint256)', this.$store.state.walletInfo.address],returns:[[j, val => val / 10 ** tokenInfo.decimals]]})
        }
        let chainInfo=arrChainInfo[chainTokens.chainCode];
        let multicallConfig={"multicallAddress":chainInfo.multicall, "rpcUrl":chainInfo.info.rpcUrls[0]};

        chainTokens.watcher=createWatcher(queryList,multicallConfig);
        await chainTokens.watcher.start();
        chainTokens.watcher.batch().subscribe((updates)=>{
          for (const item of updates) {
            chainTokens.tokens[item.type].balance=item.value;
          }
        });
        await chainTokens.watcher.stop();
        console.info("chain:"+chainTokens.chainCode+" assets complete.============================");
      }
    }

  }
}
</script>
<style lang="less" scoped>
.profile {
  margin-left: 45px;
  .data {
    width: 860px;
    .proinfo {
      width: 420px;
      height: 150px;
      background: #1D1D3C;
      border-radius: 21px;
      .ptitle{
        display: none;
      }
      .proindetail{
        width: 100%;
        height: 100%;
        padding-left: 20px;
        justify-content: flex-start;
        .portrait {
          > img {
            width: 64px;
          }
        }
        .addrinfo {
          margin-left: 20px;
          font-size: 13px;
          > div {
            height: 32px;
          }
          .address {
            cursor: pointer;
            > img {
              margin-left: 10px;
              width: 26px;
            }
          }
        }
      }

    }
    .assets{
      width: 420px;
      height: 150px;
      padding: 40px 20px;
      background: #1D1D3C;
      border-radius: 21px;
      .fdata{
        .assdesc{
          color: #5F5FA3;
          font-size: 13px;
        }
        .assdetail{
          margin-top: 10px;
          .asstext{
            font-size: 19px;
            line-height: 32px;
          }
          .synass{
            .point{
              width: 10px;
              height: 10px;
              border-radius: 5px;
              background: #3AEDFD;
              box-shadow: 0px 0px 6px 0px rgba(169,255,254,0.5);
            }
            .synced{
              margin-left: 10px;
              font-size: 15px;
            }
            .synicon{
              margin-left: 6px;
              cursor: pointer;
              >img{
                width: 25px;
              }
            }
          }
        }
      }
    }
  }
  .content{
    padding: 32px 32px 10px 32px;
    margin-top: 32px;
    background: #1D1D3C;
    border-radius: 21px;
    .proftab{
      justify-content: flex-start;
      .ptitem{
        width: 240px;
        height: 50px;
        text-align: center;
        font-size: 20px;
        cursor: pointer;
      }
      .ptitem:hover{
        color: #36BAC6;
      }
      .curptitem{
        font-size: 22px;
        color: #36BAC6;
        border-bottom: 3px solid #36BAC6;
      }
    }
    .translist{
      min-height: 400px;
      margin-top: 20px;
      margin-bottom: 20px;
      padding-bottom: 18px;
      border-radius: 21px;
      background: rgba(#141425,0.2);
      border-radius: 21px;
      .transctx{
        min-height: 320px;
        max-height: calc(~"100vh - 380px");
        overflow-y: auto;
        padding-left: 10px;
        overflow-y: auto;
        .tranitemctx{
          flex-direction: row;
          background: rgba(#141425, 0.2);
          .tranitem{
            height: 32px;
            line-height: 32px;
            font-size: 13px;
            justify-content: flex-start;
            overflow-x: hidden;
            .tdate{
              width: 200px;
              color: #7676B1;
            }
          }
          .scan{
            display: none;
          }
        }
        .evenline{
          background: rgba(#1D1D3C,0.2);
        }
      }
      .tranitemctx:hover{
        background: rgba(#414183,0.2);
      }
      .nodata{
        margin-top: 100px;
        flex-direction: column;
        .nodataicon{
          >img{
            width: 64px;
          }
        }
        .nodatadesc{
          line-height: 32px;
        }
      }

    }

    .overview{
      margin-top: 20px;
      .chains{
        width: 430px;
        .chainitem{
          padding-left: 5px;
          padding-right: 5px;
          text-align: center;
          color: #7676B1;
          cursor: pointer;
          flex-direction: column;
          .chainlogo{
            width: 46px;
            height: 46px;
            background: #2F2F5A;
            border-radius: 8px;
            >img{
              margin-top: 8px;
              width: 30px;
            }
          }
          .chainname{
            margin-top: 8px;
          }
        }
        .chainitem:hover{
          color: rgba(#FFFFFF,0.8);
          .chainlogo{
            background: rgba(#5F5FA3,0.5);
          }
        }
        .curchainitem{
          color: #FFFFFF;
          .chainlogo{
            background: #5F5FA3 !important;
          }
        }
      }
    }
  }

}
@media only screen and (max-width: 670px) {
.profile{
  width: 100%;
  margin-left: 0;
  .data{
    width: 100%;
    .proinfo{
      width: 100%;
      height: max-content;
      background: none;
      .ptitle{
        width: 100%;
        height: 40px;
        margin-bottom: 20px;
        line-height: 20px;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        display: block;
      }

    }
    .assets{
      display: none;
    }
  }
  .content{
    width: 100%;
    margin-top: 30px;
    padding: 0;
    background: none;
    .proftab{
      width: 100%;
      height: 40px;
      margin-top: 20px;
      font-size: 16px;
      color: #5F5FA3;
      line-height: 50px;
      text-align: center;
      display: flex;
      .ptitem{
        font-size: 14px;
        width: 50%;
      }
      .curptitem{
        font-size: 16px;
        color: #36BAC6;
        font-weight: bold;
        border-bottom: 3px solid #36BAC6;
      }
    }
    .translist{
      @screenWidth: calc(~"100vw - 40px");
      width: @screenWidth;
      margin: 30px auto 0;
      height: 550px;
      background: #1D1D3C;
      border-radius: 10px;
      .ttitle{
        display: none;
      }
      .transctx{
        height: 500px;
        max-height: max-content;
        padding-right: 0;
        .tranitemctx{
          .tranitem{
            height: max-content;
            flex-direction: column;
            align-items: flex-start;
          }
          .scan{
            width: 30px;
            display: block;
            >img{
              width: 24px;
            }
          }
        }
      }
    }
  }


}
}
</style>

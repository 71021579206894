<template>
  <div class="confirmaddliquidity">
    <div class="cpshader" @click="$emit('close')"></div>
    <div class="dialog">
      <div class="flex dheader">
        <div class="close" @click="$emit('close')"><img src="@/assets/imgs/icon_close.png"/></div>
      </div>
      <div class="dbody">
        <div class="title">You will receive</div>
        <div class="lpinfo">
          <span>{{lpAmount}}</span>
          <img :src="liquidityTokenA.logoURI"/>
          <img :src="liquidityTokenB.logoURI"/>
        </div>
        <div class="lppool">{{liquidityTokenA.symbol}}/{{ liquidityTokenB.symbol }} Pool Tokens</div>
        <div class="lpdesc">Output is estimated. You will receive at least 64.6228 {{liquidityTokenA.symbol}}-{{ liquidityTokenB.symbol }}-LP or the transaction will revert. </div>
        <div class="lpadded">
          <div class="flex lpaitem">
            <div class="lpaititle">{{liquidityTokenA.symbol}} Deposited</div>
            <div class="lpaivalue">{{liquidityTokenAAmount}}</div>
          </div>
          <div class="flex lpaitem">
            <div class="lpaititle">{{ liquidityTokenB.symbol }} Deposited </div>
            <div class="lpaivalue">{{liquidityTokenBAmount}}</div>
          </div>
          <div class="flex lpaitem">
            <div class="lpaititle">Rates</div>
            <div class="lpaivalue">
              <p>1 {{liquidityTokenA.symbol}} = {{price}} {{liquidityTokenB.symbol}}</p>
              <p>1 {{liquidityTokenB.symbol}} = {{revertPrice}} {{liquidityTokenA.symbol}}</p>
            </div>
          </div>
          <div class="flex lpaitem">
            <div class="lpaititle">Share of Pool </div>
            <div class="lpaivalue">{{lpPer}}%</div>
          </div>
        </div>
        <div :class="{'btnconfirm':true,'grayBtn':sendingTransFlag}" @click="$emit('sendTrans')">Confirm Supply</div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name:"ConfirmAddLiquidity",
  props: ["liquidityTokenA", "liquidityTokenB", "liquidityTokenAAmount", "liquidityTokenBAmount","price", "revertPrice", "sendingTransFlag", "lpPer", "lpAmount"],
  data:function (){
    return {
    }
  },
  methods:{
  }
}
</script>
<style lang="less" scoped>
.confirmaddliquidity {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  z-index: 100;

  .cpshader {
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(#141425, 0.65);
  }

  .dialog {
    width: 560px;
    min-height: 630px;
    margin: 0 auto;
    background: #1D1D38;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    border: 1px solid #414183;
    padding: 10px;
    z-index: 110;

    .dheader {
      flex-direction: row-reverse;
      .close {
        cursor: pointer;
        > img {
          width: 30px;
        }
      }
    }
    .dbody {
      width: 400px;
      margin: 20px auto 0;
      .title{
        font-size: 20px;
        color: #E6E6FF;
      }
      .lpinfo{
        height: 60px;
        margin-top: 15px;
        background: #24244A;
        border-radius: 6px;
        padding: 15px 10px;
        font-size: 30px;
        line-height: 30px ;
        >img{
          margin-left: 10px;
          width: 30px;
        }
      }
      .lppool{
        margin-top: 20px;
        font-size: 20px;
        color: #E6E6FF;
      }
      .lpdesc{
        margin-top: 20px;
        font-size: 14px;
        color: #5F5FA3;
      }
      .lpadded{
        margin-top: 20px;
        background: rgba(#414183,0.2);
        border-radius: 6px;
        font-size: 14px;
        padding: 10px;
        .lpaitem{
          line-height: 30px;
          align-items: flex-start;
          .lpaititle{
            color: #5F5FA3;
          }
          .lpaivalue{
            color: #FFFFFF;
            text-align: right;
          }
        }
      }
      .btnconfirm{
        height: 60px;
        margin-top: 30px;
        background: #2B97A1;
        border-radius: 12px;
        font-size: 20px;
        text-align: center;
        line-height: 60px;
        cursor: pointer;
      }
    }
  }
}
@media only screen and (max-width: 670px) {
.confirmaddliquidity{
  .dialog{
    width: 335px;
    .dbody{
      width: 300px;
    }
  }
}
}
</style>

<template>
  <div class="swapSubmitted">
    <div class="cpshader" @click="$emit('close')"></div>
    <div class="dialog">
      <div class="flex dheader">
        <div class="close" @click="$emit('close')"><img src="@/assets/imgs/icon_close.png"/></div>
      </div>
      <div class="dbody">
        <div class="loadingicon"><img src="@/assets/imgs/icon_success.png"/></div>
        <div class="title">Transaction Submitted</div>
        <div class="swapdesc"><a @click="toScan">View on block explorer</a></div>
        <div class="btnclose" @click="close()">Close</div>
      </div>
    </div>
  </div>
</template>
<script>
import {arrChainInfo} from "@/assets/js/chaininfo";
export default {
  name:"TransSubmitted",
  props:["transSubmittedKey", "transTxnHash"],
  data(){
    return{
    }
  },
  methods:{
    close(){
      this.$emit("close");
    },
    toScan(){
      window.open(arrChainInfo[this.$store.state.walletInfo.chainCode].info.blockExplorerUrls[0]+"/tx/"+this.transTxnHash);
    }
  }
}
</script>
<style lang="less" scoped>
.swapSubmitted {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  z-index: 100;

  .cpshader {
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(#141425, 0.65);
  }

  .dialog {
    width: 560px;
    min-height: 460px;
    margin: 0 auto;
    background: #1D1D38;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    border: 1px solid #414183;
    padding: 10px;
    z-index: 110;

    .dheader {
      flex-direction: row-reverse;
      .close {
        cursor: pointer;
        > img {
          width: 30px;
        }
      }
    }

    .dbody {
      width: 400px;
      margin: 0 auto;
      text-align: center;
      .loadingicon{
        width: 80px;
        margin: 65px auto 0;
        >img{
          width: 80px;
        }
      }
      .title{
        margin-top: 20px;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        color: #E6E6FF;
      }
      .swapdesc{
        cursor: pointer;
        margin-top: 10px;
        >a{
          font-size: 14px;
          line-height: 30px;
          color: #2B97A1;
          text-decoration: none;
        }
      }
      .btnclose{
        width: 400px;
        height: 60px;
        margin-top: 30px;
        background: #2B97A1;
        border-radius: 12px;
        text-align: center;
        line-height: 60px;
        font-size: 20px;
        cursor: pointer;
      }
    }
  }
}
@media only screen and (max-width: 670px) {
.swapSubmitted{
  .dialog{
    width: 335px;
    .dbody{
      width: 300px;
      .title{
        margin-top: 50px;
      }
      .btnclose{
        width: 100%;
      }
    }
  }
}
}

</style>

<template>
  <div class="ctx">
    <div class="flex banner">
      <div class="bleft"></div>
      <div class="bcenter">
        <div class="bbbottom"></div>
        <div class="slogan">Eta Finance</div>
        <div class="btnlaunchApp" @click="$router.push('/swap')">Launch App</div>
        <div class="tags">
          <div>Decentralization</div>
          <div>Integrated Financial Solutions</div>
        </div>
        <div class="flex media">
          <div class="github" onclick="window.open('https://github.com/EtaFinance')"></div>
          <div class="twitter" onclick="window.open('https://twitter.com/Eta_Finance')"></div>
          <div class="discord" onclick="window.open('https://discord.gg/KjKXXhGWz4')"></div>
          <div class="medium" onclick="window.open('https://medium.com/@EtaFinance')"></div>
        </div>
      </div>
      <div class="bright"></div>
    </div>

    <div class="content">
      <div class="flex data">
        <div class="flex dataitem">
          <div class="dicon"><img src="@/assets/imgs/icon_data1.png"/></div>
          <div class="ddesc">
            <p class="dinfo">$0</p>
            <p class="dtext">Trade Volume</p>
          </div>
        </div>
        <div class="flex dataitem">
          <div class="dicon"><img src="@/assets/imgs/icon_data2.png"/></div>
          <div class="ddesc">
            <p class="dinfo">0</p>
            <p class="dtext">Total Transactions</p>
          </div>
        </div>
        <div class="flex dataitem">
          <div class="dicon"><img src="@/assets/imgs/icon_data3.png"/></div>
          <div class="ddesc">
            <p class="dinfo">0</p>
            <p class="dtext">Addresses</p>
          </div>
        </div>
      </div>

      <div class="busidesc">
        <div class="flex busiitem">
          <div class="busitext">
            <p class="busititle">A growing network of DeFi Apps</p>
            <p class="busict">Developers, traders, and liquidity providers participate together in a financial marketplace that is open and accessible to all.</p>
          </div>
          <div class="busiicon"><img src="@/assets/imgs/busi_1.gif"/> </div>
        </div>
      </div>

      <div class="busidesc sec">
        <div class="flex busiitem">
          <div class="busiicon"><img src="@/assets/imgs/busi_2.gif"/> </div>
          <div class="busitext">
            <p class="busititle">Join Eta and Earn</p>
            <p class="busict">Join Eta to complete trade and invite friends to earn more points. Be an early bird test Eta and submit feedback so we can make it better.</p>
          </div>
        </div>
      </div>

      <div class="busidesc">
        <div class="flex busiitem">
          <div class="busitext">
            <p class="busititle">Make Ethereum Layer 2 Expansion better</p>
            <p class="busict">Based on the Ethereum Layer 2 Expansion decentralized financial ecology of the ETH Layer 2 network, Eta, as a member of Ethereum Layer 2 Expansion, lower gas fees are more conducive to more transactions and earn more.</p>
          </div>
          <div class="busiicon"><img src="@/assets/imgs/busi_3.gif"/> </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
  export default {
    name:"home",
  }
</script>
<style lang="less" scoped>
.ctx{
  .banner{
    width: 1590px;
    margin: -60px auto 0;
    align-items: start;
    .bleft{
      width: 411px;
      height: 572px;
      margin-top: 60px;
      background: url("../assets/imgs/icon_hbright.png") no-repeat;
      -webkit-background-size: cover;
      background-size: cover;
    }
    .bcenter{
      text-align: center;
      .bbbottom{
        width: 461px;
        height: 235px;
        background: url("../assets/imgs/icon_hbbottom.png") no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
      }
      .slogan{
        margin-top: -20px;
        font-size: 50px;
      }
      .btnlaunchApp{
        width: 400px;
        height: 60px;
        margin: 50px auto 0;
        background: #2B97A1;
        border-radius: 12px;
        font-size: 20px;
        line-height: 60px;
        cursor: pointer;

      }
      .btnlaunchApp:hover{
         background: #36BAC6;
       }
      .tags{
        width: 304px;
        margin: 50px auto 0;
        font-size: 20px;
        color: rgba(#FFFFFF,0.8);
        >div{
          line-height: 30px;
          cursor: pointer;
        }
        >div:hover{
          color: #FFFFFF;
        }
      }
      .media{
        width: 280px;
        margin: 50px auto 0;
        >div{
          width: 30px;
          height: 30px;
          cursor: pointer;
        }
        .github{
          background: url("../assets/imgs/icon_github.png") no-repeat;
          -webkit-background-size: cover;
          background-size: cover;
        }
        .github:hover{
          background: url("../assets/imgs/icon_github2.png") no-repeat;
          -webkit-background-size: cover;
          background-size: cover;
        }
        .twitter{
          background: url("../assets/imgs/icon_twitter.png") no-repeat;
          -webkit-background-size: cover;
          background-size: cover;
        }
        .twitter:hover{
          background: url("../assets/imgs/icon_twitter2.png") no-repeat;
          -webkit-background-size: cover;
          background-size: cover;
        }
        .discord{
          background: url("../assets/imgs/icon_discord.png") no-repeat;
          -webkit-background-size: cover;
          background-size: cover;
        }
        .discord:hover{
          background: url("../assets/imgs/icon_discord2.png") no-repeat;
          -webkit-background-size: cover;
          background-size: cover;
        }
        .medium{
          background: url("../assets/imgs/icon_medium.png") no-repeat;
          -webkit-background-size: cover;
          background-size: cover;
        }
        .medium:hover{
          background: url("../assets/imgs/icon_medium2.png") no-repeat;
          -webkit-background-size: cover;
          background-size: cover;
        }
      }
    }
    .bright{
      width: 412px;
      height: 574px;
      margin-top: 60px;
      background: url("../assets/imgs/icon_hbleft.png") no-repeat;
      -webkit-background-size: cover;
      background-size: cover;
      >img{
        width: 412px;
      }
    }
  }

  .content{
    .data{
      width: 1180px;
      margin: 0 auto;
      .dataitem{
        width: 380px;
        height: 150px;
        background: #1D1D38;
        box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.5);
        border-radius: 20px;
        border: 1px solid #414183;
        justify-content: flex-start;
        padding-left: 30px;
        .dicon{
          >img{
            width: 80px;
          }
        }
        .ddesc{
          margin-left: 20px;
          .dinfo{
            font-size: 30px;
            font-weight: 500;
            color: #FFFFFF;
          }
          .dtext{
            margin-top: 10px;
            color: #7676B1;
          }
        }
      }
    }
    .busidesc{
      .busiitem{
        height: 500px;
        width: 1180px;
        margin: 0 auto;
        .busitext{
          width: 590px;
          .busititle{
            font-size: 36px;
            font-weight: 500;
          }
          .busict{
            margin-top: 26px;
            font-size: 20px;
            color: #7676B1;
          }
        }
        .busiicon{
          margin-right: 140px;
          >img{
            width: 240px;
          }
        }
      }
    }
    .sec{
      background: #18182D;
      .busiitem{
        .busitext{
          text-align: right;
        }
      }
      .busiicon{
        margin-right: 0px;
        margin-left: 140px;
      }
    }
  }
}
@media only screen and (max-width: 670px) {
.ctx{
  .banner{
    width: 100%;
    .bleft{
      width: 170px;
      height: 280px;
      background: url("../assets/imgs/icon_mhbright.png") no-repeat;
      -webkit-background-size: cover;
      background-size: cover;
      position: absolute;
      left: 0;
      top: 48px;
    }
    .bcenter{
      width: 323px;
      margin: 0 auto;
      position: relative;
      z-index: 10;
      .bbbottom{
        width: 323px;
        height: 175px;
        background: url("../assets/imgs/icon_mhbbottom.png") no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
      }
      .slogan{
        margin-top: 50px;
        font-size: 40px;
      }
      .btnlaunchApp{
        width: 100%;
      }
      .media{
        margin: 27px auto 0;
      }
    }
    .bright{
      width: 164px;
      height: 280px;
      background: url("../assets/imgs/icon_mhbleft.png") no-repeat;
      -webkit-background-size: cover;
      background-size: cover;
      position: absolute;
      right: 0;
      top: 48px;
    }
  }

  .content{
    margin-top: 62px;
    .data{
      width: 335px;
      flex-direction: column;
      .dataitem{
        width: 100%;
        margin-top: 38px;
      }
    }

    .busidesc{
      width: 335px;
      margin: 0 auto;
      margin-bottom: 80px;
      .busiitem{
        width: 100%;
        height: max-content;
        margin-top: 50px;
        flex-direction: column-reverse;
        .busitext{
          width: 100%;
          margin-top: 50px;
          text-align: center;
          .busititle{
            font-size: 20px;
          }
          .busict{
            font-size: 16px;
          }
        }
        .busiicon{
          margin-right: 0;
        }
      }
    }
    .sec{
      .busiitem{
        flex-direction: column;
      }
      .busiicon{
        margin-left: 0;
      }
    }
  }
}
}
</style>

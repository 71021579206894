<template>
  <div class="ctx">
    <div class="feucet">
      <div class="fcontent">
        <div class="title">Request Test Tokens</div>
        <div class="desc">
          <p>Funds you receive through the Eta faucet are not real funds. Request tokens every 24h and receive 1000  TUSDC per request.</p>
          <p>We will review the published tweets as soon as possible and transfer test tokens to the wallet.</p>
        </div>
        <div class="tokens">
          <div class="ttitle">Tokens</div>
          <div class="flex tokeninfo">
            <div class="token">TETA</div>
            <div class="address">0x331C1eBE839583708E899144A2C800Ce95f64990</div>
          </div>
          <div class="flex tokeninfo">
            <div class="token">TBTC</div>
            <div class="address">0xBF9385ed2676158849aE8d234FE7f6f76Efc3B41</div>
          </div>
          <div class="flex tokeninfo">
            <div class="token">TUSDC</div>
            <div class="address">0x6c0F64f12dDf792676895fBEE8B9167486fC0500</div>
          </div>
        </div>
        <div :class="{'btnget':true, 'grayBtn':sendingTransFlag}" @click="doFeucet">
          <img v-if="sendingTransFlag" src="@/assets/imgs/icon_clock.png"/>
          <span>{{btnDesc}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as stringUtils from "@/assets/js/stringutils";
import $ from "jquery";

export default {
  name:"feucet",
  data(){
    return {
      btnDesc:"Request",
      sendingTransFlag:false,
    }
  },

  methods:{
    doFeucet(){
      const address=this.$store.state.walletInfo.address;
      if(stringUtils.isEmpty(address)){
        this.$chainFactory.connectDefaultWallet();
        return ;
      }
      $.ajax({
        url:this.$appConfig.queryCenter().url+this.$appConfig.queryUrl().getUserInfo,
        data:{"address":address},
        success:(result)=>{
          if(result.status=="200"){
            const userInfo=result.data;
            const t=parseFloat(userInfo.faucetDate)*1000+86400000;
            if(t>new Date().getTime()){
              this.countDown(t);
              return ;
            }
            this.sendingTransFlag=true;
            $.ajax({
              url:this.$appConfig.queryCenter().url+this.$appConfig.queryUrl().addFaucet,
              type:"post",
              data:{"address":address},
              success:(faucetresult)=>{
                if(faucetresult.status=="200"){
                  this.sendingTransFlag=true;
                  this.countDown(new Date().getTime()+86400000);
                }
              }
            })
          }
        }
      })
    },
    countDown(timestamp){
      var time=0;
      let now=new Date().getTime();
      time = timestamp - now;
      if (time <= 0) {
        return
      }
      const leftd = Math.floor(time / (1000 * 60 * 60 * 24));

      let h=Math.floor(time / (1000 * 60 * 60) % 24);
      const lefth = h<10?"0"+h:h;

      let m=Math.floor(time / (1000 * 60) % 60);
      const leftm = m<10?"0"+m:m;

      let s=Math.floor(time / 1000 % 60);
      const lefts = s<10?"0"+s:s;

      this.btnDesc="Wait "+lefth+"h"+leftm+"m"+lefts+"s before requesting tokens.";
      setTimeout( ()=> {
        this.countDown(timestamp)
      }, 1000)
    }
  }
}
</script>
<style lang="less" scoped>
.ctx {
  @screenHeigh: calc(~"100vh - 130px");
  height: @screenHeigh;
  display: flex;
  align-items: center;
  .feucet {
    width: 660px;
    min-height: 360px;
    height: max-content;
    margin: 0 auto;
    padding: 56px 80px;
    background: #1D1D38;
    border-radius: 13px;
    border: 1px solid #414183;
    box-shadow: 0px 7px 7px 0px rgba(0,0,0,0.5);
    .fcontent{
      .title{
        text-align: center;
        font-size: 18px;
      }
      .desc{
        text-align: center;
        margin-top: 30px;
        font-size: 14px;
        color: #7676B1;
      }
      .tokens{
        background: #24244A;
        padding: 10px;
        margin-top: 20px;
        border-radius: 8px;
        .ttitle{
          color: #7676B1;
          text-align: center;
          font-size: 16px;
        }
        .tokeninfo{
          height: 30px;
          .token{
            color: #7676B1;
          }
          .address{
            color: #E6E6FF;
          }
        }
      }
      .btnget{
        width: 100%;
        height: 45px;
        margin: 30px auto 0;
        line-height: 40px;
        font-size: 20px;
        background: #2B97A1;
        text-align: center;
        border-radius: 8px;
        cursor: pointer;
        color: #FFFFFF;
        >img{
          margin-right: 5px;
          width: 18px;
        }
      }
    }

  }
}
@media only screen and (max-width: 670px) {
.ctx{
  .feucet{
    @screenWidth: calc(~"100vw - 20px");
    width: @screenWidth;
    min-height: 400px;
    padding: 40px 0 0 0;
    .fcontent{
      width: 100%;
      padding: 0px 20px 0px 20px;
      margin: 0 auto;
      .desc{
        margin-top: 40px;
      }
      .tokens{
        .tokeninfo{
          margin-top: 10px;
          height: 40px;
          flex-direction: column;align-items: flex-start;
        }
      }
      .btnget{
        margin: 40px auto 20px;
        width: 100%;
      }
    }
  }
}
}
</style>

<template>
  <div class="copyinvitelink">
    <div class="cpshader" @click="$emit('close')"></div>
    <div class="dialog">
      <div class="flex dheader">
        <div class="close" @click="$emit('close');"><img src="@/assets/imgs/icon_close.png"/></div>
      </div>
      <div class="dbody">
        <div class="desc">Invite friends to join Eta, after a friend completes an interaction, you can get 10,000 Points and 30% of the points earned by the friend interaction.</div>
        <div class="btnCopy" id="btnCopy">Copy Link</div>
      </div>
    </div>
  </div>
</template>
<script>
import * as stringUtils from "@/assets/js/stringutils";
export default {
  name:"CopyInviteLink",
  props:["copyInviteLinkKey"],
  watch:{
    copyInviteLinkKey: function (newVal) {
      this.initCopyAddress();
    },
  },
  methods:{
    initCopyAddress(){
      const ClipboardJS=require("clipboard");
      var copyLinkBtn =new ClipboardJS('#btnCopy',{text:(trigger)=>{
          if(stringUtils.isEmpty(this.$store.state.walletInfo.address)){
            this.$store.dispatch("showMsg","Please connect wallet.");
            return "";
          }
          return "https://etafinance.com?ref="+this.$store.state.walletInfo.address;
        }
      });
      copyLinkBtn.on("success", (e)=>{
        this.$store.dispatch("showMsg","Copy success.");
      });
    },
  }
}
</script>
<style lang="less" scoped>
  .copyinvitelink {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    z-index: 100;

    .cpshader {
      width: 100%;
      height: 100%;
      position: fixed;
      background: rgba(#141425, 0.65);
    }

    .dialog {
      width: 600px;
      min-height: 320px;
      margin: 0 auto;
      background: #1D1D38;
      box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.5);
      border-radius: 20px;
      border: 1px solid #414183;
      padding: 10px;
      z-index: 110;

      .dheader {
        flex-direction: row-reverse;
        .close {
          cursor: pointer;
          > img {
            width: 30px;
          }
        }
      }

      .dbody {
        width: 450px;
        margin: 0 auto;
        text-align: center;
        color: #E6E6FF;
        .desc{
          font-size: 15px;
          margin-top: 54px;
          color: #7676B1;
          line-height: 22px;
        }
        .btnCopy{
          width: 416px;
          height: 64px;
          margin: 43px auto 0;
          background: #2B97A1;
          border-radius: 13px;
          line-height: 64px;
          font-size: 21px;
          cursor: pointer;
        }
      }
    }
  }
</style>

import $ from "jquery";
import {AppConfig} from "../../config/appConfig";
import {ChainFactory} from "./ChainFactory";
import * as stringUtils from "./stringutils";

export class TokenListUtil {

  tokenListProvider=[
    // {name:"PancakeSwap", status:"on", url:"https://tokens.pancakeswap.finance/pancakeswap-extended.json"},
    // {name:"CoinMarketCap", status:"off", url:"https://tokens.pancakeswap.finance/cmc.json"},
    // {name:"CoinGecko", status:"off", url:"https://tokens.coingecko.com/binance-smart-chain/all.json"}
    ];


  static getInstance(){
    if(!TokenListUtil.instance){
      TokenListUtil.instance= new TokenListUtil();

      TokenListUtil.instance.loadTokenList();
    }
    return TokenListUtil.instance;
  }

  getTokenListProvider(){
    return this.tokenListProvider;
  }

  getTokenListContext(url, callback){
    $.ajax({
      url: url,
      type:"get",
      dataType:"json",
      timeout:10000,
      success:(data)=>{
        callback(data);
      }
    });
  }

  loadTokenList(){
    for(let i=0;i<this.tokenListProvider.length;i++){
      let provider=this.tokenListProvider[i];
      this.getTokenListContext(provider.url, (data)=>{
        provider.data=data;
        provider.tokenMap=this.loadTokenMap(data);
      });
    }
  }

  loadTokenMap(data){
    var tokenArr={};
    const tokens=data.tokens;
    for(var i=0;i<tokens.length;i++){
      let token=tokens[i];
      tokenArr[token.address.toUpperCase()]=token;
    }
    return tokenArr;
  }

  queryTokenListByName(name){
    let added=[];
    let unadd=[];
    let reg=new RegExp(name, "i");
    for(var i=0;i<this.tokenListProvider.length;i++){
      let provider=this.tokenListProvider[i];
      if(provider.data){
        let tokens=provider.data.tokens;
        x:for(var j=0;j<tokens.length;j++){
          if(reg.test(tokens[j].symbol)){
            if("on"==provider.status){
              if(added.length>=10){
                break x;
              }
              added.push({"provider":provider.data.name, "logoURI":provider.data.logoURI,"token":tokens[j]});
            }else if("off"==provider.status){
              unadd.push({"provider":provider.data.name, "logoURI":provider.data.logoURI,"token":tokens[j]});
              if(unadd.length>=10){
                break x;
              }
            }
          }
        }
      }
    }
    return {"added":added, "unadd":unadd};
  }

  async getTokenInfoByAddress(address){
    address=address.toUpperCase();
    const defaultTokens=AppConfig.getInstance().defaultTokens();
    // search from default tokens
    for(var i=0;i<defaultTokens.length;i++){
      const token=defaultTokens[i];
      if(address==token.address.toUpperCase()){
        return token;
      }
    }
    // search from tokenlist
    for(var j=0;j<this.tokenListProvider.length;j++){
      let provider=this.tokenListProvider[j];
      if(!provider.data){
        return ;
      }
      if(provider.tokenMap[address]){
        return provider.tokenMap[address];
      }
    }
    // get tokenInfo from main network
    const chainFactory=ChainFactory.getInstance();
    const tokenInfo=await chainFactory.getTokenInfo(address);
    if(!stringUtils.isEmpty(tokenInfo.name)){
      return tokenInfo;
    }
    // return null token
    return {name:"", address:""};
  }

}

<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>


<style>
  *{
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  html, body{
    width: 100%;
    min-height: 100%;
    height: max-content;
    background: #141425;
    overflow-x: hidden;
    color: #FFFFFF;
    font-size: 12px;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
  }
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background: #414183;
    border-radius: 6px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: rgb(238 234 244) 0px 0px 5px inset;
    border-radius: 10px;
  }
  .flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .textLeft{
    text-align: left;
  }
  .textCenter{
    text-align: center;
  }
  .textRight{
    text-align: right;
  }
  .btn:hover{
    background: #36BAC6;
  }
  .grayBtn{
    color: #9B9B9B !important;
    background: #565656 !important;
  }
  .grayBtn:hover{
    background: #565656 !important;
  }
  #app {
    width: 100%;
    font-family: AlibabaPuHuiTi-Bold, AlibabaPuHuiTi;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  img{
    vertical-align: middle;
  }
  .fadein-enter-active {
    animation: fade-in .5s;
  }
  .fadein-leave-active {
    animation: fade-in .5s reverse;
  }
  @keyframes fade-in {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }

  @keyframes fade-out {
    0% {
      opacity:1;
    }
    100% {
      opacity:0;
    }
  }
</style>

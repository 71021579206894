<template>
  <div class="world">

  </div>
</template>
<script>
export default {
  name:"world",
  data(){
    return {}
  }
}
</script>
<style lang="less" scoped>

</style>

<template>
  <div class="confirmswap">
    <div class="cpshader" @click="$emit('close')"></div>
    <div class="dialog">
      <div class="flex dheader">
        <div class="close" @click="$emit('close');"><img src="@/assets/imgs/icon_close.png"/></div>
      </div>
      <div class="dbody">
        <div class="title">Confirm Swap</div>
        <div class="flex tokeninfo">
          <div class="tamount">
            <img class="tokenlogo" :src="swapTokenA.logoURI">
            <span>{{swapTokenAAmount}}</span>
          </div>
          <div class="tokenname">{{swapTokenA.symbol}}</div>
        </div>
        <div class="sfront"><img src="@/assets/imgs/icon_down.png"/></div>
        <div class="flex tokeninfo">
          <div class="tamount">
            <img class="tokenlogo" :src="swapTokenB.logoURI">
            <span>{{swapTokenBAmount}}</span>
          </div>
          <div class="tokenname">{{swapTokenB.symbol}}</div>
        </div>
        <!--<div class="flex pricewarn">-->
          <!--<div class="warninfo">-->
            <!--<img class="warnicon" src="@/assets/imgs/icon_warn.png"/>-->
            <!--<span>Price Updated</span>-->
          <!--</div>-->
          <!--<div class="btnaccessprice" @click="refreshPrice">Accept</div>-->
        <!--</div>-->
        <div class="swaptip">Output is estimated. You will receive at least {{swapTokenBAmount}} {{swapTokenB.symbol}} or the transaction will revert. </div>
        <div class="swapsetting">
          <div class="flex stitem">
            <div class="setttitle">Minimum received</div>
            <div class="settvalue">{{swapTokenBAmount}} {{swapTokenB.symbol}}</div>
          </div>
          <div class="flex stitem">
            <div class="setttitle">Slippage tolerance</div>
            <div class="flex settvalue">
              <div class="settvalue">{{slippage}}%</div>
            </div>
          </div>
          <div class="flex stitem">
            <div class="setttitle">Liquidity Provider Fee</div>
            <div class="settvalue">0.0003 TSETH</div>
          </div>
        </div>
        <div class="btnconfirmswap" @click="$emit('sendTrans');">Confirm Swap</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name:"ConfirmSwap",
  props:["confirmSwapKey", "swapTokenA", "swapTokenAAmount", "swapTokenB", "swapTokenBAmount", "slippage"],
  data(){
    return{}
  },
  methods:{
    refreshPrice(){
      const price=this.$emit('refreshPrice');
      console.info("refresh price:"+JSON.stringify(price));
    }
  }
}
</script>
<style lang="less" scoped>
.confirmswap {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  z-index: 100;
  .cpshader {
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(#141425, 0.65);
  }

  .dialog {
    width: 560px;
    min-height: 570px;
    margin: 0 auto;
    background: #1D1D38;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    border: 1px solid #414183;
    padding: 10px;
    z-index: 110;

    .dheader {
      flex-direction: row-reverse;

      .close {
        cursor: pointer;

        > img {
          width: 30px;
        }
      }
    }

    .dbody {
      width: 400px;
      margin: 0 auto;
      .title{
        margin-bottom: 15px;
        font-size: 20px;
        color: #E6E6FF;
      }
      .tokeninfo{
        height: 60px;
        margin-top: 10px;
        border-radius: 6px;
        border: 1px solid #414183;
        padding: 10px 15px;
        .tamount{
          font-size: 20px;
          .tokenlogo{
            width: 30px;
            margin-right: 10px;
          }
        }
        .tokenname{
          font-size: 14px;
          color: #5F5FA3;
        }
      }
      .sfront{
        width: 30px;
        margin: 10px auto 0;
        >img{
          width: 100%;
        }
      }
      .pricewarn{
        height: 60px;
        margin-top: 20px;
        background: rgba(#414183,0.2);
        border-radius: 6px;
        padding: 10px 15px;
        .warninfo{
          font-size: 14px;
          color: #E6E6FF;
          .warnicon{
            width: 30px;
            margin-right: 10px;
          }
        }
        .btnaccessprice{
          width: 80px;
          height: 30px;
          background: #2B97A1;
          border-radius: 6px;
          font-size: 14px;
          text-align: center;
          line-height: 30px;
          cursor: pointer;
        }
      }
      .swaptip{
        margin-top: 20px;
        font-size: 14px;
        color: #5F5FA3;
        line-height: 20px;
      }
      .swapsetting{
        width: 100%;
        height: max-content;
        margin-top: 20px;
        border-radius: 6px;
        background: rgba(#414183, 0.2);
        padding: 10px;
        .stitem{
          height: 30px;
          line-height: 30px;
          font-size: 12px;
          .setttitle{
            color: #5F5FA3;
          }
          .settvalue{
            color: rgba(#FFFFFF,0.8);
            .sliitem{
              width: 50px;
              height: 24px;
              margin-left: 10px;
              border-radius: 6px;
              border: 1px solid #5F5FA3;
              text-align: center;
              line-height: 22px;
              color: #5F5FA3;
              cursor: pointer;
            }
            .cursliitem{
              background: #2B97A1;
              color: #FFFFFF;
            }
            .sliinput{
              width: 70px;
              height: 24px;
              line-height: 22px;
              margin-left: 10px;
              border: 1px solid #5F5FA3;
              border-radius: 6px;
              >input{
                width: 100%;
                height: 100%;
                padding-left: 15px;
                color: rgba(#FFFFFF,0.8);
                border: none;
                outline: none;
                background: transparent;
              }
            }
          }
        }
      }
      .btnconfirmswap{
        width: 100%;
        height: 60px;
        margin-top: 20px;
        background: #2B97A1;
        border-radius: 12px;
        font-size: 20px;
        text-align: center;
        line-height: 60px;
        cursor: pointer;
      }
    }
  }
}
@media only screen and (max-width: 670px) {
.confirmswap{
  .dialog{
    width: 335px;
    .dbody{
      width: 300px;
    }
  }
}
}
</style>

/**
 * is null
 *
 * @param data
 * @returns {boolean}
 */
export const isEmpty = data => {
  var flag = true;
  if (data != null && data != undefined && data != "" && data != "null" && data != "nil" && data != "undefined") {
    flag = false;
  }
  return flag;
}

export const formatMoney=(amount)=>{
  return String(amount).replace(/(?=(?!(\b))(\d{3})+$)/g,",");
}

export const getQueryParam=(name)=> {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  var r = window.location.search.substr(1).match(reg);
  if (r != null)
    return unescape(r[2]);
  return null;
}

export const formatDateTime=(timestamp)=>{
  let date=new Date(timestamp);
  var Y = date.getFullYear() + '-';
  var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
  var D = date.getDate() + ' ';
  var h = date.getHours() + ':';
  var m = date.getMinutes() + ':';
  var s = date.getSeconds();
  return Y+M+D+h+m+s;
}

export const formatAddress=(address)=>{
  if(isEmpty(address)){
    return ;
  }
  return address.substring(0,8)+"..."+address.substring(address.length-4,address.length);
}

const monthArr=["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Now", "Dec"];
export const toUsDateTime=(timestamp)=>{
  if(isEmpty(timestamp)){
    return "";
  }
  const d=new Date(timestamp);
  return monthArr[d.getMonth()]+" "+d.getDate()+","+d.getFullYear()+" "+d.getHours()+":"+d.getMinutes()+":"+d.getSeconds()
  // console.info(d.getFullYear()+"-"+(d.getMonth()+1)+"-"+d.getDate());
}




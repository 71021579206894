import { render, staticRenderFns } from "./addLiquidity.vue?vue&type=template&id=63635b3e&scoped=true&"
import script from "./addLiquidity.vue?vue&type=script&lang=js&"
export * from "./addLiquidity.vue?vue&type=script&lang=js&"
import style0 from "./addLiquidity.vue?vue&type=style&index=0&id=63635b3e&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63635b3e",
  null
  
)

export default component.exports
<template>
  <div class="ctx">
    <div class="flex welcontent">
      <div class="logoctx"><img src="@/assets/imgs/logo.png"/></div>
      <div class="flex btns">
        <div class="btn whitelist" @click="whitelistCpFlag=true">Whitelist</div>
        <div class="btn tweet" onclick="window.open('https://twitter.com/intent/tweet?text=To make room for more expression, we will now count all emojis as equal—including those with gender‍‍‍ ‍‍and skin tone modifiers 👍🏻👍🏽👍🏿. This is now reflected in Twitter-Text, our Open Source library. Using Twitter-Text? @Eta_Finance See the forum post for detail: https://t.co/Nx1XZmRCXA &hashtags=#abc')">Tweet!</div>
      </div>

      <div class="flex media">
        <div class="github" onclick="window.open('https://github.com/EtaFinance')"></div>
        <div class="twitter" onclick="window.open('https://twitter.com/Eta_Finance')"></div>
        <div class="discord" onclick="window.open('https://discord.gg/KjKXXhGWz4')"></div>
        <div class="medium" onclick="window.open('https://medium.com/@EtaFinance')"></div>
      </div>
    </div>

    <transition name="fadein" v-on:enter="++whitelistKey">
      <whitelist v-show="whitelistCpFlag" v-on:close="whitelistCpFlag=false" :whitelistKey="whitelistKey"/>
    </transition>
  </div>
</template>
<script>
import Whitelist from "../components/WhiteList";
export default {
  name:"welcome",
  components:{Whitelist},
  data(){
    return {
      whitelistCpFlag:false,
      whitelistKey:0,
    }
  }
}
</script>
<style lang="less" scoped>
.ctx{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-size: auto;
  background: url("../assets/imgs/bg_welcome.png") no-repeat center;
  display: flex;
  align-items: center;
  .welcontent{
    width: 400px;
    margin: 0 auto;
    flex-direction: column;
    .btns{
      width: 100%;
      margin-top: 60px;
      .btn{
        width: 190px;
        height: 60px;
        border-radius: 12px;
        font-size: 20px;
        line-height: 60px;
        text-align: center;
        cursor: pointer;
      }
      .whitelist{
        background: #2B97A1;
      }
      .tweet{
        background: #E7FDFF;
        color: #2B97A1;
      }
    }
    .media{
      width: 280px;
      margin: 60px auto 0;
      >div{
        width: 30px;
        height: 30px;
        cursor: pointer;
      }
      .github{
        background: url("../assets/imgs/icon_github.png") no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
      }
      .github:hover{
        background: url("../assets/imgs/icon_github2.png") no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
      }
      .twitter{
        background: url("../assets/imgs/icon_twitter.png") no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
      }
      .twitter:hover{
        background: url("../assets/imgs/icon_twitter2.png") no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
      }
      .discord{
        background: url("../assets/imgs/icon_discord.png") no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
      }
      .discord:hover{
        background: url("../assets/imgs/icon_discord2.png") no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
      }
      .medium{
        background: url("../assets/imgs/icon_medium.png") no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
      }
      .medium:hover{
        background: url("../assets/imgs/icon_medium2.png") no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
      }
    }
  }

}
@media only screen and (max-width: 670px) {
  .ctx{
    .welcontent{
      width: 100%;
      .logoctx{
        >img{
          width: 300px;
        }
      }
      .btns{
        flex-direction: column;
        .btn{
          width: 300px;
          margin-bottom: 20px;
        }
      }
    }

  }
}
</style>


<template>
  <div class="ctx">
    <div class="rkcontent">
      <div class="title">Top 100 Of Eta</div>
      <div class="table">
        <div class="theader">
          <div class="flex tr">
            <div class="th rank">Rank</div>
            <div class="th address textCenter">Address</div>
            <div class="th invitePoints textCenter">Invite Points</div>
            <div class="th transactionPoints textCenter">Transaction Points</div>
            <div class="th chat textRight">Chat</div>
          </div>
        </div>
        <div class="tbody">
          <div class="flex tr" v-for="(item, index) in rankList" :key="index">
            <div class="td rank">{{item.rank}}</div>
            <div class="td address textCenter">
              <a class="addrhref" href="#">
                <span class="domain">{{item.ens}}</span>
                <span class="addr">{{item.simpleAddress}}</span>
              </a>
            </div>
            <div class="td invitePoints textCenter">{{item.invitePoint}}</div>
            <div class="td transactionPoints textCenter">{{item.transPoint}}</div>
            <div class="td chat textRight"><img class="chaticon" src="@/assets/imgs/icon_chat.png"/></div>
          </div>
        </div>
      </div>

      <div class="mtable">
        <div class="flex mrankitem" v-for="(item, index) in rankList" :key="index">
          <div class="mrank">{{item.rank}}</div>
          <div class="flex mrankinfo">
            <div class="mrankcontent">
              <div class="maddress">
                <span class="mens">{{item.ens}}</span>
                <span class="maddr">{{item.simpleAddress}}</span>
              </div>
              <div class="flex mkdata">
                <div class="mrankdatait">
                  <div class="mrdata">{{item.invitePoint}}</div>
                  <div class="mrtitle">Invite Points</div>
                </div>
                <div class="mrankdatait" style="margin-left: 40px;">
                  <div class="mrdata">{{item.transPoint}}</div>
                  <div class="mrtitle">Transaction Points</div>
                </div>
              </div>
            </div>
            <div class="mchat"><img src="@/assets/imgs/icon_chat.png"/></div>
          </div>
        </div>
      </div>
  </div>
  </div>
</template>
<script>
import $ from "jquery";

import * as stringUtils from "@/assets/js/stringutils";
export default {
  name: "rank",
  data(){
    return {
      rankList:[]
    }
  },
  mounted(){
    this.getRankList(1);
  },
  methods:{
    getRankList(pageNum){
      const address=this.$store.state.walletInfo.address;
      if(stringUtils.isEmpty(address)){
        return ;
      }
      $.ajax({
        url:this.$appConfig.queryCenter().url+this.$appConfig.queryUrl().getRankList,
        data:{"pageNum":pageNum},
        success:(result)=>{
          if(result.status=="200"){
            const resultData=result.data;
            for(var i=0;i<resultData.length;i++){
              const x=resultData[i];
              x.simpleAddress=stringUtils.formatAddress(x.userAddress);
              this.rankList.push(x);
            }
          }
        }
      })
    },
  }
}
</script>
<style lang="less" scoped>
.ctx{
  @screenHeigh: calc(~"100vh - 150px");
  @contentHeigh:calc(@screenHeigh - 20px);
  @tbodyHeigh:calc(@contentHeigh - 80px);
  min-height: @screenHeigh;
  .rkcontent{
    width: 1180px;
    height: @contentHeigh;
    padding: 22px 20px;
    margin: 20px auto 20px;
    background: rgba(#1D1D38,0.7);
    border-radius: 20px;
    border: 1px solid #414183;
    box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.5);
    .title{
      font-size: 20px;
      font-weight: 500;
      text-align: center;
    }
    .table{
      .tbody{
        height: @tbodyHeigh;
        overflow-y: auto;
        .tr:hover{
          background: rgba(#414183,0.2);
        }
      }
      .theader, .tbody{
        padding-right: 8px;
        .tr{
          height: 30px;
          font-size: 12px;
          .th{
            color: #7676B1;
          }
          .td{
            .addrhref{
              text-decoration: none;
              .domain{
                color: #ffffff;
              }
              .addr{
                margin-left: 8px;
                color: #5F5FA3;
              }
            }
            >img{
              width: 18px;
              cursor: pointer;
            }
          }
          .rank{
            width: 5%;
          }
          .address{
            width: 40%;
          }
          .invitePoints, .transactionPoints, .chat{
            width: 15%;
          }
        }
      }
    }
    .mtable{
      display: none;
    }
  }
}
@media only screen and (max-width: 670px) {
.ctx{
  .rkcontent{
    width: calc(~"100vw - 20px");
    height: 100%;
    padding-right: 0px;
    .title{
      margin-bottom: 20px;
    }
    .table{
      display: none;
    }
    .mtable{
      display: block;
      height: calc(~"100vh - 200px");
      padding-right: 13px;
      overflow-y: auto;
      .mrankitem{
        height: 80px;
        margin-bottom: 10px;
        background: rgba(#414183,0.2);
        align-items: flex-start;
        justify-content: flex-start;
        .mrank{
          width: 30px;
          line-height: 80px;
          text-align: center;
          background: #2F2F5A;
        }
        .mrankinfo{
          width: calc(100% - 30px);
          padding: 10px 10px 5px 10px;
          .mrankcontent{
            .maddress{
              width: 100%;
              .mens{
                margin-right: 10px;
              }
              .maddr{
                color: #5F5FA3;
              }
            }
            .mkdata{
              margin-top: 5px;
              .mrankdatait{
                line-height: 20px;
                .mrdata{
                  font-size: 14px;
                  font-weight: 500;
                }
                .mrtitle{
                  color: #7676B1;
                }
              }
            }
          }
          .mchat{
            >img{
              width: 18px;
            }
          }
        }
      }
    }

  }
}
}
</style>

<template>
  <div class="ctx">
    <div class="swap">
      <div class="flex swaptab">
        <div class="curtab">Swap</div>
        <div @click="$router.push('/liquidity')">Liquidity</div>
      </div>
      <div class="swapctx">
        <div :class="{'stokena':true, 'curtoken':curtoken=='tokenA'}">
          <div class="flex stainfo">
            <div>From</div>
            <div>Balance: {{ swapTokenA.balance }}</div>
          </div>
          <div class="flex stainfo2">
            <div class="samounta"><input @focus="curtoken='tokenA'" @blur="curtoken=''" class="amoutinput" placeholder="0.0" v-model.number="swapTokenAAmount"/></div>
            <div v-if="!swapTokenA.address || swapTokenA.address ==''" class="selecttoken" @click="showTokenSelector('swapTokenA')">Select a token</div>
            <div class="smax" v-if="swapTokenA.address && swapTokenA.address !=''" @click="swapTokenAAmount=swapTokenA.balance">Max</div>
            <div class="flex staname" v-if="swapTokenA.address && swapTokenA.address !=''" @click="showTokenSelector('swapTokenA')">
              <div class="staicon"><img :src="swapTokenA.logoURI"/></div>
              <div class="stasymbol">{{ swapTokenA.symbol }}</div>
              <div class="stafdown"><img src="@/assets/imgs/icon_fdown.png"/></div>
            </div>
          </div>
        </div>
        <div class="sfront" @click="convertSwapToken()"><img src="@/assets/imgs/icon_down.png"/></div>
        <div :class="{'stokenb':true,'curtoken':curtoken=='tokenB'}">
          <div class="flex stbinfo">
            <div>To</div>
            <div>Balance: {{swapTokenB.balance}}</div>
          </div>
          <div class="flex stbinfo2">
            <div class="samountb"><input @focus="curtoken='tokenB'" @blur="curtoken=''" readonly placeholder="0.0" v-model="swapTokenBAmount"/></div>
            <div v-if="!swapTokenB.address || swapTokenB.address ==''" class="selecttoken" @click="showTokenSelector('swapTokenB')">Select a token</div>
            <div v-if="swapTokenB.address && swapTokenB.address !=''" class="flex stbname" @click="showTokenSelector('swapTokenB')">
              <div class="staicon"><img :src="swapTokenB.logoURI"/></div>
              <div class="stasymbol">{{ swapTokenB.symbol }}</div>
              <div class="stafdown"><img src="@/assets/imgs/icon_fdown.png"/></div>
            </div>
          </div>
        </div>
        <div class="swapbtns">
          <div v-if="$store.state.walletInfo.address==''" class="btnswap">Connect Wallet</div>
          <div v-if="$store.state.walletInfo.address!=''" class="flex unapprove">
            <div :class="{'unappbtn':true, 'grayBtn':swapTokenAApprovedComplete || sendingTran}" @click="doApprove">Approve</div>
            <div :class="{'unappbtn':true, 'grayBtn':!swapTokenAApprovedComplete || !swapTokenA.symbol || !swapTokenB.symbol || swapTokenAAmount<=0 || swapTokenBAmount<=0 || sendingTran}" @click="toConfirmSwap">Swap</div>
          </div>
        </div>
        <div class="swapsetting">
          <div class="flex stitem">
            <div class="setttitle">Minimum received</div>
            <div class="settvalue">{{swapTokenBAmount}} {{swapTokenB.symbol}}</div>
          </div>
          <div class="flex stitem slippage">
            <div class="setttitle">Slippage tolerance</div>
            <div class="flex settvalue">
              <div :class="{'sliitem':true, cursliitem:slippage=='0.1'}" @click="slippage=0.1">0.1%</div>
              <div :class="{'sliitem':true, cursliitem:slippage=='0.2'}" @click="slippage=0.2">0.2%</div>
              <div :class="{'sliitem':true, cursliitem:slippage=='0.3'}" @click="slippage=0.3">0.3%</div>
              <div class="sliinput"><input v-model="slippage"/></div>
            </div>
          </div>
          <div class="flex stitem">
            <div class="setttitle">Liquidity Provider Fee</div>
            <div class="settvalue">0.0003 TSETH</div>
          </div>
        </div>
      </div>
    </div>

    <transition name="fadein" v-on:enter="++selectTokenKey">
      <select-token v-show="selectTokenCpFlag" v-on:close="selectTokenCpFlag=false" v-on:selectToken="selectToken($event)" :selectTokenSource="selectTokenSource" :selectTokenKey="selectTokenKey"/>
    </transition>

    <transition name="fadein" v-on:enter="++confirmSwapKey">
      <confirm-swap v-show="confirmSwapCpFlag" :confirmSwapKey="selectTokenKey" :swapTokenA="swapTokenA" :swapTokenAAmount="swapTokenAAmount" :swapTokenB="swapTokenB" :swapTokenBAmount="swapTokenBAmount" :slippage="slippage" v-on:close="confirmSwapCpFlag=false" v-on:sendTrans="doSwap" v-on:refreshPrice="refreshPrice"/>
    </transition>

    <transition name="fadein" v-on:enter="++transConfirmationKey">
      <trans-confirmation v-show="transConfirmationCpFlag" v-on:close="transConfirmationCpFlag=false" :transDesc="transDesc" :transConfirmationKey="transConfirmationKey"/>
    </transition>
    <transition name="fadein" v-on:enter="++transSubmittedKey">
      <trans-submitted v-show="transSubmittedCpFlag" v-on:close="transSubmittedCpFlag=false" :transSubmittedKey="transSubmittedKey" :transTxnHash="transTxnHash"/>
    </transition>
  </div>
</template>
<script>
import $ from "jquery";
import BigNumber from "bignumber.js";
import {mapState, mapActions} from 'vuex'

import * as bignumberUtils from "../assets/js/bignumberUtils";
import * as stringUtils from "../assets/js/stringutils"

import SelectToken from "../components/SelectToken";
import ConfirmSwap from "../components/ConfirmSwap";
import TransConfirmation from "../components/TransConfirmation";
import TransSubmitted from "@/components/TransSubmitted";

export default {
  name:"swap",
  components: {TransSubmitted, SelectToken, ConfirmSwap, TransConfirmation},
  data(){
    return {
      slippage:0.1,

      swapTokenA: {},
      swapTokenAAmount: "",
      swapTokenB: {},
      swapTokenBAmount: "",
      swapTokenAApprovedComplete:false,
      swapPrice:0,
      revertSwapPrice:0,
      swapAmountFlag:false,

      selectTokenCpFlag:false,
      selectTokenSource:"",
      selectTokenKey:0,

      confirmSwapCpFlag:false,
      confirmSwapKey:0,

      transConfirmationCpFlag:false,
      transConfirmationKey:0,
      transTxnHash:"",
      transDesc:"",

      transSubmittedCpFlag:false,
      transSubmittedKey:0,

      swapStatus:0,

      sendingTran:false,

      curtoken:"",

    }
  },
  computed: {
    ...mapState(['walletInfo'])
  },
  watch:{
    walletInfo: function (walletInfo) {
      this.selectToken({"source":"swapTokenA", "token":this.$appConfig.defaultTokens()[0]});
    },
    swapTokenAAmount:function (swapTokenAAmount) {
      // reset swapTokenBAmount when swapTokenAAmount updated.
      this.getSwapTokenAAllowance(false);
      this.resetSwapInfo();
    },
    // swapTokenBAmount:function (swapTokenBAmount) {
    //   if(this.swapAmountFlag){
    //     this.swapAmountFlag=false;
    //   }else {
    //     this.swapAmountFlag=true;
    //
    //     this.getSwapTokenAAllowance(false);
    //     this.resetSwapInfo();
    //     if (stringUtils.isEmpty(swapTokenBAmount) || swapTokenBAmount == 0) {
    //       this.swapTokenAAmount = "";
    //     } else {
    //       let slippage=this.$appConfig.swapSetting().slippage;
    //       this.swapTokenAAmount = new BigNumber(new BigNumber(this.revertSwapPrice).multipliedBy(swapTokenBAmount).multipliedBy(new BigNumber(1+(slippage/100))).toFixed(6));
    //     }
    //   }
    // },
    swapTokenA:function (swapTokenA) {
      this.swapTokenAApprovedComplete=false;
      this.getSwapTokenAAllowance(false);

      this.resetSwapInfo();

    },
    swapTokenB:function (swapTokenB) {
      this.getSwapTokenAAllowance(false);
      this.resetSwapInfo();
    }
  },
  mounted(){
    this.slippage=this.$appConfig.swapSetting().slippage;
    // init params
    if(this.$route.params.swapTokenA){
      this.swapTokenA=this.$route.params.swapTokenA;
    }
    if(this.$route.params.swapTokenA){
      this.swapTokenB=this.$route.params.swapTokenB;
    }

    // init token price and swap info
    // this.resetSwapInfo();

    this.selectToken({"source":"swapTokenA", "token":this.$appConfig.defaultTokens()[0]});
  },
  methods:{
    toConfirmSwap(){
      if(!this.swapTokenAApprovedComplete || !this.swapTokenA.symbol || !this.swapTokenB.symbol || this.swapTokenAAmount<=0 || this.swapTokenBAmount<=0 || this.sendingTran){
        return ;
      }
      this.confirmSwapCpFlag=true;
    },

    refreshPrice(){
      this.resetSwapInfo();
    },

    showTokenSelector(source){
      this.selectTokenSource=source;
      this.selectTokenCpFlag=true;
    },
    selectToken(data){
      if(stringUtils.isEmpty(this.$store.state.walletInfo.address)){
        return ;
      }
      this.selectTokenCpFlag=false;
      if("swapTokenA"==data.source){
        this.swapTokenA=data.token;
      }else if("swapTokenB"==data.source){
        this.swapTokenB=data.token;
      }
      // query token balance
      let walletInfo=this.$store.state.walletInfo;
      if(!stringUtils.isEmpty(walletInfo.address)){
        this.$chainFactory.getTokenBalance(walletInfo.address, data.token, this.$appConfig.defaultABI(), {},(balance)=>{
          if("swapTokenA"==data.source){
            this.swapTokenA.balance=balance;
          }else if("swapTokenB"==data.source){
            this.swapTokenB.balance=balance;
          }
        });
      }
    },
    convertSwapToken(){
      let tempToken=this.swapTokenA;
      this.swapTokenA=this.swapTokenB;
      this.swapTokenB=tempToken;
    },
    getSwapTokenAAllowance(init){
      this.$chainFactory.getTokenAllowance(this.$store.state.walletInfo.address, this.swapTokenA, this.$appConfig.defaultABI(), this.$appConfig.swapAllowance().contract, {}, (allowance)=>{
        let bigTokenAAmount=new BigNumber(stringUtils.isEmpty(this.swapTokenAAmount)?0:this.swapTokenAAmount);
        if(allowance>0 && bigTokenAAmount.isLessThanOrEqualTo(allowance)){
          this.swapTokenAApprovedComplete=true;
        }
      });
    },
    resetSwapInfo(){
      if(stringUtils.isEmpty(this.swapTokenA.symbol) || stringUtils.isEmpty(this.swapTokenB.symbol)){
        this.swapPrice=0;
        this.revertSwapPrice=0;
        return ;
      }
      $.ajax({
        url:this.$appConfig.queryCenter().url+this.$appConfig.queryUrl().getSwapInfo,
        type:"get",
        // async:false,
        data:{"assetTo":this.$store.state.walletInfo.address, "fromToken":this.swapTokenA.address, "toToken":this.swapTokenB.address, "amount":bignumberUtils.toWei(this.swapTokenAAmount, this.swapTokenA.decimals).toString()},
        success:(result)=> {
          if(result.status=="200"){
            let swapInfo=result.data;
            this.swapPrice=bignumberUtils.fromWei(swapInfo.returnAmount, this.swapTokenB.decimals);
            // this.revertSwapPrice=new BigNumber(new BigNumber(1).dividedBy(this.swapPrice).toFixed(10));
            if(!stringUtils.isEmpty(this.swapTokenAAmount)){
              let slippage=this.$appConfig.swapSetting().slippage;
              // this.swapTokenBAmount=new BigNumber(new BigNumber(this.swapPrice).multipliedBy(this.swapTokenAAmount).multipliedBy(new BigNumber(1-(slippage/100))).toFixed(10));
              this.swapTokenBAmount=this.swapPrice.multipliedBy(new BigNumber(1-(slippage/100))).toFixed(10);
            }else{
              this.swapTokenBAmount="";
            }
          }
        }
      })
    },
    doApprove(){
      if(this.swapTokenAApprovedComplete){
        return ;
      }
      let swapTokenAContract=this.$chainFactory.getContract(this.$appConfig.defaultABI(), this.swapTokenA.address);
      this.sendingTran=true;
      this.$chainFactory.approve(swapTokenAContract, this.$appConfig.swapAllowance().contract, "max", {}, (contract, toAddress, data)=>{
        this.sendingTran=true;
      }, (contract, toAddress, data)=>{
        this.swapTokenAApprovedComplete=true;
        this.sendingTran=false;
      }, ()=>{
        this.sendingTran=false;
      })
    },
    doSwap(){
      // no approve
      if(!this.swapTokenAApprovedComplete){
        return ;
      }
      // swap token not init
      if(stringUtils.isEmpty(this.swapTokenA.symbol) || stringUtils.isEmpty(this.swapTokenB.symbol)){
        return ;
      }
      this.confirmSwapCpFlag=false;
      // swapInfo init
      let tempSwapTokenA=this.swapTokenA;
      let tempSwapTokenB=this.swapTokenB;
      let tempSwapAmount=stringUtils.isEmpty(this.swapTokenAAmount)?0:this.swapTokenAAmount;
      $.ajax({
        url:this.$appConfig.queryCenter().url+this.$appConfig.queryUrl().getSwapInfo,
        type:"get",
        data:{"assetTo":this.$store.state.walletInfo.address, "fromToken":tempSwapTokenA.address, "toToken":tempSwapTokenB.address, "amount":bignumberUtils.toWei(tempSwapAmount, tempSwapTokenA.decimals).toString()},
        success:(result)=> {
          if(result.status=="200"){
            let tempSwapInfo=result.data;
            /*** send transaction **/
              // deadline
            let deadlineSeconds=this.$appConfig.swapSetting().deadline*60;
            deadlineSeconds=tempSwapInfo.timestampNow+deadlineSeconds;
            // slippage
            let slippage=this.$appConfig.swapSetting().slippage;
            let realReturnAmount = new BigNumber(tempSwapInfo.returnAmount).multipliedBy(new BigNumber(1-(slippage/100))).toFixed(0);
            this.sendingTran=true;
            this.$chainFactory.mixSwap(tempSwapTokenA, tempSwapTokenB, tempSwapInfo.fromTokenAmount, realReturnAmount, tempSwapInfo.mixAdapters, tempSwapInfo.mixPairs, tempSwapInfo.assetTo, tempSwapInfo.directions, deadlineSeconds, {}, (data)=>{
              this.sendingTran=true;
              this.transDesc="Swapping "+this.swapTokenAAmount+" "+this.swapTokenA.symbol+" for "+this.swapTokenBAmount+" "+this.swapTokenB.symbol;
              this.transTxnHash=data.txnHash;
              this.transConfirmationCpFlag=true;
            }, (data)=>{
              this.swapTokenAAmount="";
              this.sendingTran=false;
              this.transConfirmationCpFlag=false;
              this.transTxnHash=data.txnHash;
              this.transSubmittedCpFlag=true;
              this.storeTransDetail(tempSwapTokenA, tempSwapAmount, tempSwapTokenB, bignumberUtils.fromWei(data.returnValue, tempSwapTokenB.decimals), data.txnHash);
            }, ()=>{
              this.sendingTran=false;
            });
          }
        }
      })

    },
    // store trans record into localstore
    storeTransDetail(swapTokenA, swapTokenAAmount, swapTokenB, swapTokenBAmount, txnHash){
      let arrRecords=[{"transType": "swap","swapTokenA":swapTokenA, "swapTokenAAmount":swapTokenAAmount, "swapTokenB":swapTokenB, "swapTokenBAmount":swapTokenBAmount, "txnHash":txnHash,"timestamp": Date.now()}];
      let transRecordsInStore=localStorage.getItem("transRecords"+this.$store.state.walletInfo.chainCode+this.$store.state.walletInfo.address);
      if(!stringUtils.isEmpty(transRecordsInStore)){
        let temptransRecords=JSON.parse(transRecordsInStore);
        arrRecords.push(...temptransRecords);
      }
      localStorage.setItem("transRecords"+this.$store.state.walletInfo.chainCode+this.$store.state.walletInfo.address, JSON.stringify(arrRecords));
    },
  }
}
</script>
<style lang="less" scoped>
.ctx{
  @screenHeigh: calc(~"100vh - 150px");
  min-height: @screenHeigh;
  height: max-content;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  .swap{
    width: 560px;
    margin: 0 auto;
    padding: 50px 80px;
    background: #1D1D3C;
    border-radius: 20px;
    border: 1px solid #414183;
    box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.5);
    .swaptab{
      width: 260px;
      height: 45px;
      margin: 0 auto;
      >div{
        font-size: 20px;
        color: #5F5FA3;
        cursor: pointer;
      }
      >div:hover{
        color: rgba(#FFFFFF, 0.8);
      }
      .curtab{
        font-size: 30px;
        color: #FFFFFF !important;
      }
    }
    .swapctx{
      width: 400px;
      margin: 10px auto 0;
      color: #5F5FA3;
      .stokena{
        height: 100px;
        padding: 15px 10px;
        border-radius: 12px;
        border: 1px solid #414183;
        .stainfo{
        }
        .stainfo2{
          margin-top: 20px;
          >div{
            min-height: 30px;
          }
          .samounta{
            width: 200px;
            height: 40px;
            .amoutinput{
              width: 100%;
              height: 100%;
              color: #FFFFFF;
              font-size: 20px;
              border: none;
              outline: none;
              background: transparent;
            }
          }
          .selecttoken{
            width: 120px;
            height: 30px;
            color: rgba(#FFFFFF,0.7);
            line-height: 30px;
            font-size: 14px;
            text-align: center;
            background: #2B97A1;
            border-radius: 8px;
            cursor: pointer;
          }
          .selecttoken:hover{
            background: #36BAC6;
          }
          .smax{
            width: 50px;
            text-align: center;
            color: rgba(#FFFFFF,0.7);
            line-height: 30px;
            border-radius: 8px;
            background: #2B97A1;
            cursor: pointer;
          }
          .smax:hover{
            background: #36BAC6;
          }
          .staname{
            margin-left: 10px;
            border-radius: 8px;
            border: 1px solid #414183;
            padding: 7px 9px;
            cursor: pointer;
            .staicon{
              line-height: 14px;
              margin-right: 10px;
              >img{
                width: 16px;
              }
            }
            .stasymbol{
              font-size: 14px;
              color: #FFFFFF;
            }
            .stafdown{
              line-height: 14px;
              margin-left: 8px;
              >img{
                width: 10px;
                vertical-align: top;
              }
            }
          }
          .staname:hover{
            background: rgba(#414183,0.3);
          }
        }
      }
      .sfront{
        width: 30px;
        margin: 10px auto 0;
        >img{
          transform: rotate(0deg);
          transition: transform 0.3s ease 0s;
          width: 100%;
        }
        :hover{
          transform: rotate(180deg);
          transition: transform 0.3s ease 0s;;
        }
      }
      .stokenb{
        height: 100px;
        margin-top: 10px;
        padding: 15px 10px;
        border-radius: 12px;
        border: 1px solid #414183;
        .stbinfo{
        }
        .stbinfo2{
          margin-top: 20px;
          >div{
            min-height: 30px;
          }
          .samountb{
            width: 250px;
            height: 40px;
            >input{
              width: 100%;
              height: 100%;
              color: #FFFFFF;
              font-size: 20px;
              border: none;
              outline: none;
              background: transparent;
            }
          }
          .selecttoken{
            width: 120px;
            height: 30px;
            color: rgba(#FFFFFF,0.7);
            line-height: 30px;
            font-size: 14px;
            text-align: center;
            background: #2B97A1;
            border-radius: 8px;
            cursor: pointer;
          }
          .selecttoken:hover{
            background: #36BAC6;
          }
          .stbname{
            margin-left: 10px;
            border-radius: 8px;
            border: 1px solid #414183;
            padding: 7px 9px;
            cursor: pointer;
            .staicon{
              line-height: 14px;
              margin-right: 10px;
              >img{
                width: 16px;
              }
            }
            .stasymbol{
              font-size: 14px;
              color: #FFFFFF;
            }
            .stafdown{
              line-height: 14px;
              margin-left: 8px;
              >img{
                width: 10px;
                vertical-align: top;
              }
            }
          }
          .stbname:hover{
            background: rgba(#414183,0.5);
          }
        }
      }
      .swapbtns{
        margin-top: 20px;
        .btnswap{
          width: 400px;
          height: 60px;
          color: #FFFFFF;
          font-size: 20px;
          line-height: 60px;
          text-align: center;
          background: #2B97A1;
          border-radius: 12px;
          cursor: pointer;
        }
        .unapprove{
          .unappbtn{
            width: 190px;
            height: 60px;
            background: #2B97A1;
            border-radius: 12px;
            font-size: 20px;
            text-align: center;
            line-height: 60px;
            color: #FFFFFF;
            cursor: pointer;
          }
          .unappbtn:hover{
            background: #36BAC6;
          }
          .disbtn{
            color: #9B9B9B;
            background: #565656;
          }
        }
      }
      .swapsetting{
        width: 100%;
        height: max-content;
        margin-top: 20px;
        border-radius: 6px;
        background: rgba(#414183, 0.2);
        padding: 10px;
        .stitem{
          height: 30px;
          line-height: 30px;
          font-size: 12px;
          .setttitle{
            color: #5F5FA3;
          }
          .settvalue{
            color: rgba(#FFFFFF,0.8);
            .sliitem{
              width: 50px;
              height: 24px;
              margin-right: 10px;
              border-radius: 6px;
              border: 1px solid #5F5FA3;
              text-align: center;
              line-height: 22px;
              color: #5F5FA3;
              cursor: pointer;
            }
            .sliitem:hover{
              background: rgba(#414183,0.5);
            }
            .cursliitem{
              background: #2B97A1 !important;
              color: #FFFFFF;
            }
            .sliinput{
              width: 70px;
              height: 24px;
              line-height: 22px;
              border: 1px solid #5F5FA3;
              border-radius: 6px;
              >input{
                width: 100%;
                height: 100%;
                padding-left: 15px;
                color: rgba(#FFFFFF,0.8);
                border: none;
                outline: none;
                background: transparent;
              }
            }
          }
        }
      }
    }
    .curtoken{
      border: 2px solid #7676B1 !important;
    }
  }
}
@media only screen and (max-width: 670px) {
.ctx{
  .swap{
    @screenWidth: calc(~"100vw - 20px");
    width: @screenWidth;
    padding: 58px 20px;
    .swaptab{
      width: 200px;
      >div{
        font-size: 16px;
      }
      .curtab{
        font-size: 20px;
      }
    }
    .swapctx{
      width: 100%;
      .stokena{
        .stainfo{
          font-size: 15px;
        }
        .stainfo2{
          margin-top: 16px;
          .samounta{
            width: 160px;
          }
        }
      }
      .stokenb{
        .stbinfo{
          font-size: 15px;
        }
        .stbinfo2{
          margin-top: 16px;
          .samountb{
            width: 160px;
          }
        }
      }
      .swapbtns{
        .btnswap{
          width: 100%;
        }
        .unapprove{
          .unappbtn{
            width: 130px;
            height: 50px;
            line-height: 50px;
          }
        }
      }
      .swapsetting{
        .slippage{
          margin-bottom: 30px;
          flex-direction: column;
          align-items: flex-start;
          .settvalue{
            .sliitem{
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}
}
@keyframes fadenum{
  100%{transform:rotate(180deg);}
}
</style>

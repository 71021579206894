<template>
  <div class="walletinfo">
    <div class="cpshader" @click="$emit('close')"></div>
    <div class="wctx">
      <div class="dialog">
        <div class="flex dheader">
          <div class="title">Connected Wallet</div>
          <div class="close" @click="$emit('close')"><img src="@/assets/imgs/icon_close.png"/></div>
        </div>
        <div class="dbody">
          <div class="flex walletopr">
            <div class="simpleaddress" @click="$router.push('/profile')">{{$store.state.walletInfo.simpleAddress}}</div>
            <div class="copy"><img src="@/assets/imgs/icon_copy.png"/>Copy Address</div>
            <div class="disconnect"><img src="@/assets/imgs/icon_signout.png"/> Disconnect</div>
          </div>
          <div class="wline"></div>
          <div class="translist">
            <div class="notrandesc">Your transactions will appear here...</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name:"ConnectedWallet",
}
</script>
<style lang="less" scoped>
.walletinfo {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  z-index: 100;
  .cpshader {
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(#141425, 0.65);
  }
  .wctx{
    width: 1180px;
    margin: 70px auto 0;
    display: flex;
    justify-content: flex-end;
    z-index: 110;
    .dialog {
      width: 490px;
      min-height: 160px;
      height: max-content;
      background: #1D1D38;
      box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.5);
      border-radius: 20px;
      border: 1px solid #414183;
      padding: 15px 20px;
      color: #E6E6FF;
      .dheader {
        font-size: 18px;
        .close {
          cursor: pointer;
          > img {
            width: 30px;
          }
        }
      }

      .dbody {
        margin-top: 20px;
        font-size: 14px;
        .walletopr{
          justify-content: flex-start;
          .simpleaddress{
            margin-right: 30px;
            color: #2B97A1;
            cursor: pointer;
          }
          .copy{
            margin-right: 30px;
            cursor: pointer;
            >img{
              width: 24px;
            }
          }
          .disconnect{
            cursor: pointer;
            >img{
              width: 24px;
            }
          }
        }
        .wline{
          width: 100%;
          height: 1px;
          margin-top: 20px;
          background: rgba(#414183,0.4);
        }
        .translist{
          margin-top: 10px;
          .notrandesc{
            font-size: 14px;
            color: #5F5FA3;
          }
        }
      }
    }
  }

}
</style>

<template>
  <div class="whitelist">
    <div class="cpshader" @click="$emit('close')"></div>
    <div class="dialog">
      <div class="flex dheader">
        <div class="close" @click="$emit('close')"><img src="@/assets/imgs/icon_close.png"/></div>
      </div>
      <div class="flex dbody">
        <div class="logo"><img src="@/assets/imgs/logo.png"/></div>
        <div class="desc">Sign up for our testnet</div>
        <div class="flex text" v-if="!submitedEmail">
          <div class="cinput"><input placeholder="Enter your email." v-model="email"/></div>
          <div class="btnsubmit" @click="addWhitelist">Submit</div>
        </div>
        <div class="submiteddesc" v-if="submitedEmail">
          <p>Thank you for requesting to be added to our testing whitelist.</p>
          <p>Please keep following us for updates.</p>
        </div>
        <div class="flex media">
          <div class="github" onclick="window.open('https://github.com/EtaFinance')"></div>
          <div class="twitter" onclick="window.open('https://twitter.com/Eta_Finance')"></div>
          <div class="discord" onclick="window.open('https://discord.gg/KjKXXhGWz4')"></div>
          <div class="medium" onclick="window.open('https://medium.com/@EtaFinance')"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import * as stringUtils from "../assets/js/stringutils";
export default {
  name:"Whitelist",
  props:["whitelistKey"],
  data:function () {
    return {
      submitedEmail:false,
      email:"",
    }
  },
  methods:{
    addWhitelist(){
      if(stringUtils.isEmpty(this.email)){
        return ;
      }
      $.ajax({
        url:this.$appConfig.queryCenter().url+this.$appConfig.queryUrl().addEmail,
        type:"post",
        data:{"email":this.email},
        success:(result)=>{
          if(result.status=="200"){
            this.submitedEmail=true;
          }
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.whitelist{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  z-index: 100;
  .cpshader{
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(#141425, 0.65);
  }
  .dialog{
    width: 660px;
    height: 360px;
    margin: 0 auto;
    background: #1D1D38;
    box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.5);
    border-radius: 20px;
    border: 1px solid #414183;
    padding: 10px;
    z-index: 110;
    .dheader{
      flex-direction: row-reverse;
      .close{
        cursor: pointer;
        >img{
          width: 30px;
        }
      }
    }
    .dbody{
      margin-top: 16px;
      flex-direction: column;
      .logo{
        >img{
          width: 159px;
        }
      }
      .desc{
        font-size: 20px;
        margin-top: 30px;
      }
      .text{
        margin-top: 30px;
        .cinput{
          >input{
            width: 360px;
            height: 60px;
            background: #FFFFFF;
            border-radius: 12px;
            color: #7676B1;
            font-size: 20px;
            border: none;
            outline: none;
            padding-left: 10px;
          }
        }
        .btnsubmit{
          width: 140px;
          height: 60px;
          cursor: pointer;
          margin-left: 20px;
          text-align: center;
          line-height: 60px;
          font-size: 20px;
          background: #2B97A1;
          border-radius: 12px;
        }
      }
      .submiteddesc{
        margin-top: 30px;
        text-align: center;
        line-height: 21px;
        color: #7676B1;
        font-size: 14px;
      }
      .media{
        width: 270px;
        margin-top: 30px;
        >div{
          width: 30px;
          height: 30px;
          cursor: pointer;
        }
        .github{
          background: url("../assets/imgs/icon_github.png") no-repeat;
          -webkit-background-size: cover;
          background-size: cover;
        }
        .github:hover{
          background: url("../assets/imgs/icon_github2.png") no-repeat;
          -webkit-background-size: cover;
          background-size: cover;
        }
        .twitter{
          background: url("../assets/imgs/icon_twitter.png") no-repeat;
          -webkit-background-size: cover;
          background-size: cover;
        }
        .twitter:hover{
          background: url("../assets/imgs/icon_twitter2.png") no-repeat;
          -webkit-background-size: cover;
          background-size: cover;
        }
        .discord{
          background: url("../assets/imgs/icon_discord.png") no-repeat;
          -webkit-background-size: cover;
          background-size: cover;
        }
        .discord:hover{
          background: url("../assets/imgs/icon_discord2.png") no-repeat;
          -webkit-background-size: cover;
          background-size: cover;
        }
        .medium{
          background: url("../assets/imgs/icon_medium.png") no-repeat;
          -webkit-background-size: cover;
          background-size: cover;
        }
        .medium:hover{
          background: url("../assets/imgs/icon_medium2.png") no-repeat;
          -webkit-background-size: cover;
          background-size: cover;
        }
      }
    }
  }
}
@media only screen and (max-width: 670px) {
  .whitelist{
    .dialog{
      width: 335px;
      height: max-content;
      padding-bottom: 20px;
      .dbody{
        .text{
          flex-direction: column;
          .cinput{
            >input{
              width: 300px;
            }
          }
          .btnsubmit{
            width: 300px;
            margin-top: 20px;
            margin-left: 0;
          }
        }
      }
    }
  }
}
</style>

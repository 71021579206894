import BigNumber from "bignumber.js";

export const arrDecimals=[new BigNumber("0"),new BigNumber("10"),new BigNumber("100"),new BigNumber("1000"),new BigNumber("10000"),new BigNumber("100000"),
  new BigNumber("1000000"),new BigNumber("10000000"),new BigNumber("100000000"),new BigNumber("1000000000"),new BigNumber("10000000000"),
  new BigNumber("100000000000"),new BigNumber("1000000000000"),new BigNumber("10000000000000"),new BigNumber("100000000000000"),new BigNumber("1000000000000000"),
  new BigNumber("10000000000000000"),new BigNumber("100000000000000000"),new BigNumber("1000000000000000000"),new BigNumber("10000000000000000000"),new BigNumber("100000000000000000000"),
  new BigNumber("1000000000000000000000"),new BigNumber("10000000000000000000000"),new BigNumber("100000000000000000000000"),new BigNumber("1000000000000000000000000"),new BigNumber("10000000000000000000000000"),
  new BigNumber("100000000000000000000000000"),new BigNumber("1000000000000000000000000000"),new BigNumber("10000000000000000000000000000"),new BigNumber("100000000000000000000000000000"),new BigNumber("1000000000000000000000000000000"),
];

const fmt = {
  prefix: '',
  decimalSeparator: '.',
  groupSeparator: ',',
  groupSize: 3,
  secondaryGroupSize: 0,
  fractionGroupSeparator: ' ',
  fractionGroupSize: 0,
  suffix: ''
}

export const toWei=(numStr, decimal)=>{
  if(decimal<0 || decimal>30 ||numStr==0){
    return new BigNumber(numStr)
  }
  let num=new BigNumber(numStr);
  BigNumber.config({ EXPONENTIAL_AT: 36 });
  return num.multipliedBy(arrDecimals[decimal]);
};

export const fromWei=(numStr, decimal, dig)=>{
  if(decimal<0 || decimal>30){
    return new BigNumber(numStr)
  }
  let curDig=6;
  if(dig>=0){
    curDig=dig;
  }
  let num=new BigNumber(numStr);
  BigNumber.config({ FORMAT: fmt });
  let result=num.dividedBy(arrDecimals[decimal]);
  result= result.toFixed(curDig, BigNumber.ROUND_DOWN);
  return new BigNumber(result);
}

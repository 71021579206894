<template>
  <div class="ctx">
    <div class="addliquidity">
      <div class="aheader" @click="$router.push('/liquidity')"><img src="@/assets/imgs/icon_back.png"/></div>
      <div class="abody">
        <div class="title">
          <span>Add Liquidity</span>
          <img  @click="showTokenTip($event)" src="@/assets/imgs/icon_qusmark.png"/>
        </div>
        <div class="tokentip">
          <div class="direction"></div>
          <div class="ttctx">Find the token by the name or by pasting the contract address below.</div>
        </div>
        <div :class="{'tokeninfo':true, 'curtoken':curtoken=='tokenA'}">
          <div class="flex tititle">
            <div class="titdesc">Input</div>
            <div class="titbalance">Balance: {{liquidityTokenA.balance}}</div>
          </div>
          <div class="flex ticontent">
            <div class="tiinput"><input @focus="curtoken='tokenA'" @blur="curtoken=''" placeholder="0.00" v-model="liquidityTokenAAmount"/></div>
            <div v-if="!liquidityTokenA.address || liquidityTokenA.address ==''" class="selecttoken" @click="showTokenSelector('liquidityTokenA')">Select a token</div>
            <div v-if="liquidityTokenA.address && liquidityTokenA.address !=''" class="flex titname" @click="showTokenSelector('liquidityTokenA')">
              <div class="staicon"><img :src="liquidityTokenA.logoURI"/></div>
              <div class="stasymbol">{{ liquidityTokenA.symbol }}</div>
              <div class="stafdown"><img class="tokenmore" src="@/assets/imgs/icon_fdown.png"/></div>
            </div>
          </div>
        </div>
        <div class="plus"><img src="@/assets/imgs/icon_plus.png"/></div>
        <div :class="{'tokeninfo':true, 'curtoken':curtoken=='tokenB'}">
          <div class="flex tititle">
            <div class="titdesc">Input</div>
            <div class="titbalance">Balance: {{liquidityTokenB.balance}}</div>
          </div>
          <div class="flex ticontent">
            <div class="tiinput"><input @focus="curtoken='tokenB'" @blur="curtoken=''" placeholder="0.00" v-model="liquidityTokenBAmount"/></div>
            <div v-if="!liquidityTokenB.address || liquidityTokenB.address ==''" class="selecttoken" @click="showTokenSelector('liquidityTokenB')">Select a token</div>
            <div v-if="liquidityTokenB.address && liquidityTokenB.address !=''" class="flex titname"  @click="showTokenSelector('liquidityTokenB')">
              <div class="staicon"><img :src="liquidityTokenB.logoURI"/></div>
              <div class="stasymbol">{{liquidityTokenB.symbol}}</div>
              <div class="stafdown"><img src="@/assets/imgs/icon_fdown.png"/></div>
            </div>
          </div>
        </div>
        <div class="btns">
          <div v-if="$store.state.walletInfo.address==''" class="btnconnect">Connect Wallet</div>
          <div v-if="$store.state.walletInfo.address!=''" class="flex connectedbtns">
            <div :class="{'btn':true, 'grayBtn':(tokenAApprovedComplete && tokenBApprovedComplete) ||(liquidityTokenAAmount=='' || liquidityTokenBAmount=='' || liquidityTokenAAmount<=0 || liquidityTokenBAmount<=0) || sendingTransFlag }" @click="approve">Approve</div>
            <div :class="{'btn':true, 'grayBtn':!tokenAApprovedComplete || !tokenBApprovedComplete || liquidityTokenAAmount=='' || liquidityTokenBAmount=='' || liquidityTokenAAmount<=0 || liquidityTokenBAmount<=0  || sendingTransFlag}" @click="toConfirm">Add Liquidity</div>
          </div>
        </div>
      </div>
    </div>

    <transition name="fadein" v-on:enter="++selectTokenKey">
      <select-token v-show="selectTokenCpFlag" v-on:close="selectTokenCpFlag=false" v-on:selectToken="selectToken($event)" :selectTokenSource="selectTokenSource" :selectTokenKey="selectTokenKey"/>
    </transition>

    <transition name="fadein" v-on:enter="++confirmAddLiquidityKey">
      <confirm-add-liquidity v-show="confirmAddLiquidityCpFalg" v-on:close="confirmAddLiquidityCpFalg=false" :confirmAddLiquidityKey="confirmAddLiquidityKey" :liquidityTokenA="liquidityTokenA" :liquidityTokenB="liquidityTokenB" :liquidityTokenAAmount="liquidityTokenAAmount" :liquidityTokenBAmount="liquidityTokenBAmount" :price="price" :revertPrice="revertPrice" :sendingTransFlag="sendingTransFlag" :lpPer="lpPer" :lpAmount="lpAmount" v-on:sendTrans="addLiquidityETH"></confirm-add-liquidity>
    </transition>

    <transition name="fadein" v-on:enter="++transConfirmationKey">
      <trans-confirmation v-show="transConfirmationCpFlag" v-on:close="transConfirmationCpFlag=false" :transDesc="transDesc" :transConfirmationKey="transConfirmationKey"/>
    </transition>

    <transition name="fadein" v-on:enter="++transSubmittedKey">
      <trans-submitted v-show="transSubmittedCpFlag" v-on:close="transSubmittedCpFlag=false" :transSubmittedKey="transSubmittedKey" :transTxnHash="transTxnHash"/>
    </transition>
  </div>
</template>
<script>
import $ from "jquery";
import BigNumber from "bignumber.js";
import { createWatcher } from "makerdao-multicall";

import SelectToken from "../components/SelectToken";
import ConfirmAddLiquidity from "../components/ConfirmAddLiquidity";
import TransConfirmation from "../components/TransConfirmation";
import TransSubmitted from "@/components/TransSubmitted";

import * as stringUtils from "@/assets/js/stringutils";
import * as bignumberUtils from "@/assets/js/bignumberUtils";

export default {
  name:"addLiquidity",
  components:{SelectToken, ConfirmAddLiquidity, TransConfirmation, TransSubmitted},
  data(){
    return {
      selectTokenCpFlag:false,
      selectTokenSource:"",
      selectTokenKey:0,

      confirmAddLiquidityCpFalg:false,
      confirmAddLiquidityKey:0,

      transConfirmationCpFlag:false,
      transConfirmationKey:0,
      transTxnHash:"",
      transDesc:"",

      transSubmittedCpFlag:false,
      transSubmittedKey:0,

      liquidityTokenA:{},
      liquidityTokenB:{},
      liquidityTokenAAmount:"",
      liquidityTokenBAmount:"",

      price:0,
      revertPrice:0,

      swapAmountFlag:false,

      tokenAApprovedComplete:false,
      tokenBApprovedComplete:false,

      sendingTransFlag:false,

      tokenTipFlag:false,

      curtoken:"",

      lpAmount:0,
      lpPer:0,
      watcher:{},
      chainLpInfo:{"balanceA":"a", "balanceB":"","totalSupply":""},
    }
  },
  watch:{
    liquidityTokenAAmount:function (liquidityTokenAAmount) {
      this.getSwapTokenAAllowance(this.liquidityTokenA, liquidityTokenAAmount, "tokenAApprovedComplete");
      // reset liquidityTokenBAmount when liquidityTokenAAmount updated.
      if(this.swapAmountFlag){
        this.swapAmountFlag=false;
      }else{

        if(this.price==0 || this.revertPrice==0){
          return ;
        }else if(stringUtils.isEmpty(liquidityTokenAAmount) || liquidityTokenAAmount==0){
          this.liquidityTokenBAmount="";
        }else {
          this.liquidityTokenBAmount=new BigNumber(new BigNumber(this.price).multipliedBy(liquidityTokenAAmount).toFixed(6));
        }
        this.swapAmountFlag=true;
      }
    },
    liquidityTokenBAmount:function (liquidityTokenBAmount) {
      this.getSwapTokenAAllowance(this.liquidityTokenB, liquidityTokenBAmount, "tokenBApprovedComplete");
      if(this.swapAmountFlag){
        this.swapAmountFlag=false;
      }else {
        if(this.price==0 || this.revertPrice==0){
          return ;
        }else if (stringUtils.isEmpty(liquidityTokenBAmount) || liquidityTokenBAmount == 0) {
          this.liquidityTokenAAmount = "";
        } else {
          this.liquidityTokenAAmount = new BigNumber(new BigNumber(this.revertPrice).multipliedBy(liquidityTokenBAmount).toFixed(6));
        }
        this.swapAmountFlag=true;
      }
    },
    liquidityTokenA:function (liquidityTokenA) {
      this.liquidityTokenAAmount="";
      this.liquidityTokenBAmount="";

      this.tokenAApprovedComplete=false;
      this.getSwapTokenAAllowance(liquidityTokenA, this.liquidityTokenAAmount, "tokenAApprovedComplete");
      this.resetPrice();
    },
    liquidityTokenB:function (liquidityTokenB) {
      this.liquidityTokenAAmount="";
      this.liquidityTokenBAmount="";

      this.tokenBApprovedComplete=false;
      this.getSwapTokenAAllowance(liquidityTokenB, this.liquidityTokenBAmount, "tokenBApprovedComplete");
      this.resetPrice();
    }
  },
  mounted() {
    if(this.$route.params.liquidityTokenA && this.$route.params.liquidityTokenB){
      this.liquidityTokenA=this.$route.params.liquidityTokenA;
      this.liquidityTokenB=this.$route.params.liquidityTokenB;
    }

    this.resetPrice();
  },
  methods:{
    showTokenTip(event){
      if(this.tokenTipFlag){
        return ;
      }
      $(".tokentip").show();
      this.tokenTipFlag=true;
      setTimeout(()=>{
        $(".tokentip").hide();
        this.tokenTipFlag=false;
      },3000);
    },
    showTokenSelector(source){
      this.selectTokenSource=source;
      this.selectTokenCpFlag=true;
    },
    selectToken(data){
      if(stringUtils.isEmpty(this.$store.state.walletInfo.address)){
        return ;
      }
      this.selectTokenCpFlag=false;
      this[data.source]=data.token;
      // query token balance
      let walletInfo=this.$store.state.walletInfo;
      if(!stringUtils.isEmpty(walletInfo.address)){
        this.$chainFactory.getTokenBalance(walletInfo.address, data.token, this.$appConfig.defaultABI(), {},(balance)=>{
          this[data.source].balance=balance;
        });
      }
    },
    toConfirm(){
      if(stringUtils.isEmpty(this.liquidityTokenA.address) || stringUtils.isEmpty(this.liquidityTokenB.address)
          || stringUtils.isEmpty(this.liquidityTokenAAmount) || stringUtils.isEmpty(this.liquidityTokenBAmount)
          || this.liquidityTokenAAmount<=0 || this.liquidityTokenBAmount<=0){
        return ;
      }
      this.calLpTokenCount();
      this.confirmAddLiquidityCpFalg=true;
    },
    resetPrice(){
      if(stringUtils.isEmpty(this.liquidityTokenA.symbol) || stringUtils.isEmpty(this.liquidityTokenB.symbol)){
        return ;
      }
      this.$chainFactory.getReservesRate(this.liquidityTokenA.address, this.liquidityTokenB.address, {}, (data)=>{
        this.price=data.rate;
        this.revertPrice=new BigNumber(new BigNumber(1).dividedBy(this.price).toFixed(10));
      });
    },

    getSwapTokenAAllowance(token, amount, completeState){
      this.$chainFactory.getTokenAllowance(this.$store.state.walletInfo.address, token, this.$appConfig.defaultABI(), this.$appConfig.swapAllowance().contract, {}, (allowance)=>{
        let bigTokenAAmount=new BigNumber(stringUtils.isEmpty(amount)?0:amount);
        if(allowance>0 && bigTokenAAmount.isLessThanOrEqualTo(allowance)){
          this[completeState]=true;
        }
      });
    },
    approve(){
      const walletInfo=this.$store.state.walletInfo;
      if(stringUtils.isEmpty(walletInfo.address)){
        return ;
      }
      if(stringUtils.isEmpty(this.liquidityTokenA.address) || stringUtils.isEmpty(this.liquidityTokenB.address) || stringUtils.isEmpty(this.liquidityTokenAAmount)|| stringUtils.isEmpty(this.liquidityTokenBAmount)|| this.liquidityTokenAAmount<=0 || this.liquidityTokenBAmount<=0){
        return ;
      }
      this.sendingTransFlag=true;
      this.$chainFactory.getTokenAllowance(walletInfo.address, this.liquidityTokenA, this.$appConfig.defaultABI(), this.$appConfig.swapAllowance().contract, {}, (allowance)=>{
        let bigTokenAAmount=new BigNumber(stringUtils.isEmpty(this.liquidityTokenAAmount)?0:this.liquidityTokenAAmount);
        if(allowance>0 && bigTokenAAmount.isLessThanOrEqualTo(allowance)){
          this.tokenAApprovedComplete=true;
          //do approve liquidityTokenB
          this.$chainFactory.getTokenAllowance(walletInfo.address, this.liquidityTokenB, this.$appConfig.defaultABI(), this.$appConfig.swapAllowance().contract, {}, (allowance)=> {
            let bigTokenAAmount = new BigNumber(stringUtils.isEmpty(this.liquidityTokenBAmount) ? 0 : this.liquidityTokenBAmount);
            if (allowance > 0 && bigTokenAAmount.isLessThanOrEqualTo(allowance)) {
              this.tokenBApprovedComplete = true;
            } else {
              const tokenBContract=this.$chainFactory.getContract(this.$appConfig.defaultABI(), this.liquidityTokenB.address);
              this.$chainFactory.approve(tokenBContract, this.$appConfig.swapAllowance().contract, "max", {}, (contract, toAddress, data)=>{},(contract, toAddress, data)=> {
                this.tokenBApprovedComplete = true;
                this.sendingTransFlag=false;
              });
            }
          });
        }else{
          const tokenAContract=this.$chainFactory.getContract(this.$appConfig.defaultABI(), this.liquidityTokenA.address);
          this.$chainFactory.approve(tokenAContract, this.$appConfig.swapAllowance().contract, "max", {}, (contract, toAddress, data)=>{},(contract, toAddress, data)=>{
            this.tokenAApprovedComplete=true;
            this.sendingTransFlag=false;

            //do approve liquidityTokenB
            this.$chainFactory.getTokenAllowance(walletInfo.address, this.liquidityTokenB, this.$appConfig.defaultABI(), this.$appConfig.swapAllowance().contract, {}, (allowance)=> {
              let bigTokenAAmount = new BigNumber(stringUtils.isEmpty(this.liquidityTokenBAmount) ? 0 : this.liquidityTokenBAmount);
              if (allowance > 0 && bigTokenAAmount.isLessThanOrEqualTo(allowance)) {
                this.tokenBApprovedComplete = true;
              } else {
                const tokenBContract=this.$chainFactory.getContract(this.$appConfig.defaultABI(), this.liquidityTokenB.address);
                this.$chainFactory.approve(tokenBContract, this.$appConfig.swapAllowance().contract, "max", {}, (contract, toAddress, data)=>{},(contract, toAddress, data)=> {
                  this.tokenBApprovedComplete = true;
                  this.sendingTransFlag=false;
                });
              }
            });
          }, (data, error)=>{
            this.sendingTransFlag=false;
          });
        }
      });
    },
    addLiquidityETH(){
      let walletInfo=this.$store.state.walletInfo;
      if(stringUtils.isEmpty(this.liquidityTokenA.address) || stringUtils.isEmpty(this.liquidityTokenB.address) || this.liquidityTokenAAmount==0 || this.liquidityTokenBAmount==0 || stringUtils.isEmpty(walletInfo.address)){
        return ;
      }
      this.sendingTransFlag=true;
      this.$chainFactory.getReservesRate(this.liquidityTokenA.address, this.liquidityTokenB.address, {}, (data)=>{
        let tempPrice=data.rate;
        // let tempRevertPrice=new BigNumber(new BigNumber(1).dividedBy(tempPrice).toFixed(6));
        let tempTokenBAmount=0;
        let tempTokenBAmountMin;
        if(tempPrice==0){
          tempTokenBAmount=this.liquidityTokenBAmount;
          tempTokenBAmountMin=new BigNumber(new BigNumber(this.liquidityTokenBAmount).multipliedBy(new BigNumber(1-(this.$appConfig.swapSetting().slippage/100))).toFixed(6));
        }else{
          tempTokenBAmount=new BigNumber(new BigNumber(tempPrice).multipliedBy(this.liquidityTokenAAmount).toFixed(6));
          tempTokenBAmountMin=new BigNumber(new BigNumber(tempPrice).multipliedBy(this.liquidityTokenAAmount).multipliedBy(new BigNumber(1-(this.$appConfig.swapSetting().slippage/100))).toFixed(6));
        }
        tempTokenBAmount=bignumberUtils.toWei(tempTokenBAmount, this.liquidityTokenB.decimals);
        tempTokenBAmountMin=bignumberUtils.toWei(tempTokenBAmountMin, this.liquidityTokenB.decimals);

        let tempTokenAAmount=bignumberUtils.toWei(this.liquidityTokenAAmount, this.liquidityTokenA.decimals);
        let tempTokenAAmountMin=new BigNumber( tempTokenAAmount.multipliedBy(new BigNumber(1-(this.$appConfig.swapSetting().slippage/100))).toFixed(0));

        let tempTokenAAddress=this.liquidityTokenA.address;
        let tempTokenBAddress=this.liquidityTokenB.address;
        if("0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE"==this.liquidityTokenA.address){
          // revert liquidityTokenA<>liquidityTokenB
          let tempAmount=tempTokenAAmount;
          let tempAmountMin=tempTokenAAmountMin;

          tempTokenAAddress=this.liquidityTokenB.address;
          tempTokenAAmount=tempTokenBAmount;
          tempTokenAAmountMin=tempTokenBAmountMin;

          tempTokenBAddress=this.liquidityTokenA.address;
          tempTokenBAmount=tempAmount;
          tempTokenBAmountMin=tempAmountMin;
        }

        this.$chainFactory.addLiquidity(tempTokenAAddress, tempTokenBAddress, tempTokenAAmount+"", tempTokenBAmount+"", tempTokenAAmountMin+"", tempTokenBAmountMin+"", {},
            (sendResult)=>{
              this.confirmAddLiquidityCpFalg=false;

              this.transDesc="Supplying "+this.liquidityTokenAAmount+" "+this.liquidityTokenA.symbol+" and "+this.liquidityTokenBAmount+" "+this.liquidityTokenB.symbol;
              this.transTxnHash=sendResult.txnHash;
              this.transConfirmationCpFlag=true;

            }, (confirmResult)=>{
              this.transConfirmationCpFlag=false;
              this.sendingTransFlag=false;
              this.transSubmittedCpFlag=true;
              this.transTxnHash=confirmResult.txnHash;
            }, (errorResult)=>{
              console.info("add liquidity error:"+JSON.stringify(errorResult));
              this.sendingTransFlag=false;
            });
      })
    },
    async calLpTokenCount(){
      if(stringUtils.isEmpty(this.liquidityTokenA.address) || stringUtils.isEmpty(this.liquidityTokenB.address)){
        return ;
      }
      try {
        const lpAddress=await this.$chainFactory.pairFor(this.liquidityTokenA, this.liquidityTokenB);
        var queryList=[];
        queryList.push({target:this.liquidityTokenA.address, call:['balanceOf(address)(uint256)', lpAddress],returns:[['balanceA']]});
        queryList.push({target:this.liquidityTokenB.address, call:['balanceOf(address)(uint256)', lpAddress],returns:[['balanceB']]});
        queryList.push({target:lpAddress, call:['totalSupply()(uint256)'],returns:[['totalSupply']]});

        this.watcher=createWatcher(queryList, this.$appConfig.multicallConfig());
        await this.watcher.start();
        this.watcher.batch().subscribe((updates)=>{
          for (const item of updates) {
            this.chainLpInfo[item.type]=item.value._hex;
          }
          this.calLptokenPer();
        });
        await this.watcher.stop();
      }catch (e) {
        console.info(e);
      }

    },
    calLptokenPer(){
      let bigTokenAAmount=bignumberUtils.toWei(this.liquidityTokenAAmount, this.liquidityTokenA.decimals);
      let tokenAPer=bigTokenAAmount.div(bigTokenAAmount.plus(new BigNumber(this.chainLpInfo.balanceA)));
      let lpTokenReceive=tokenAPer*new BigNumber(this.chainLpInfo.totalSupply).div(new BigNumber(1).minus(tokenAPer));

      let lpTokenPer=tokenAPer.times(new BigNumber(100));
      if(lpTokenPer<0.001){
        this.lpPer="<0.001";
      }else{
        this.lpPer=lpTokenPer.toFixed(4);
      }
      this.lpAmount=bignumberUtils.fromWei(lpTokenReceive, 18, 6);
    }
  }
}
</script>
<style lang="less" scoped>
.ctx {
  @screenHeigh: calc(~"100vh - 130px");
  height: @screenHeigh;
  display: flex;
  align-items: center;
  .addliquidity {
    width: 560px;
    min-height: 520px;
    height: max-content;
    margin: 0 auto;
    background: #1D1D3C;
    border-radius: 20px;
    border: 1px solid #414183;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.5);
    .aheader{
      cursor: pointer;
      >img{
        width: 30px;
        margin-top: 10px;
        margin-left: 10px;
      }
    }
    .abody{
      width: 400px;
      margin: 10px auto 0;
      .title{
        text-align: center;
        font-size: 30px;
        margin-bottom: 10px;
        >img{
          cursor: pointer;
          width: 16px;
          margin-left: 10px;
        }
      }
      .tokeninfo{
        height: 100px;
        padding: 15px 10px;
        border-radius: 12px;
        border: 1px solid #414183;
        .tititle{
          font-size: 14px;
          color: #5F5FA3;
        }
        .ticontent{
          margin-top: 15px;
          .tiinput{
            >input{
              width: 230px;
              height: 40px;
              background: transparent;
              border-radius: 12px;
              color: #FFFFFF;
              font-size: 20px;
              border: none;
              outline: none;
            }
          }
          .selecttoken{
            width: 120px;
            height: 30px;
            color: rgba(#FFFFFF,0.7);
            line-height: 30px;
            font-size: 14px;
            text-align: center;
            background: #2B97A1;
            border-radius: 8px;
            cursor: pointer;
          }
          .selecttoken:hover{
            background: #36BAC6;
          }
          .titname{
            margin-left: 10px;
            border-radius: 8px;
            border: 1px solid #414183;
            padding: 7px 9px;
            cursor: pointer;
            .staicon{
              line-height: 14px;
              margin-right: 10px;
              >img{
                width: 16px;
              }
            }
            .stasymbol{
              font-size: 14px;
              color: #FFFFFF;
            }
            .stafdown{
              line-height: 14px;
              margin-left: 8px;
              >img{
                width: 10px;
                vertical-align: top;
              }
            }
          }
          .titname:hover{
            background: rgba(#414183,0.3);
            /*.stasymbol{*/
              /*font-size: 15px;*/
            /*}*/
          }
        }
      }
      .plus{
        width: 20px;
        margin: 15px auto 15px;
        >img{
          width: 20px;
        }
      }
      .btns{
        margin-top: 20px;
        .btnconnect{
          width: 400px;
          height: 60px;
          color: #FFFFFF;
          font-size: 20px;
          line-height: 60px;
          text-align: center;
          background: #2B97A1;
          border-radius: 12px;
          cursor: pointer;
        }
        .btnconnect:hover{
          background: #36BAC6;
        }
        .connectedbtns{
          .btn{
            width: 190px;
            height: 60px;
            background: #2B97A1;
            border-radius: 12px;
            font-size: 20px;
            text-align: center;
            line-height: 60px;
            color: #FFFFFF;
            cursor: pointer;
          }
          .btn:hover{
            background: #36BAC6;
          }
        }
      }
      .btnAdd{
        height: 60px;
        margin-top: 20px;
        background: #2B97A1;
        border-radius: 8px;
        text-align: center;
        line-height: 60px;
        font-size: 20px;
        cursor: pointer;
      }
      .disbtn{
        background: #565656;
        color: #9B9B9B;
      }
    }
  }
  .curtoken{
    border: 2px solid #7676B1 !important;
  }
  .tokentip{
    display: none;
    margin-left: 150px;
    margin-top: 5px;
    position: absolute;
    font-size: 12px;
    z-index: 110;
    .direction{
      width: 0;
      height: 0;
      margin-left: 140px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #414183;
    }
    .ttctx{
      width: 240px;
      height: 56px;
      padding: 10px;
      background: #414183;
      border-radius: 6px;
    }
  }
}
@media only screen and (max-width: 670px) {
.ctx{
  .addliquidity{
    @screenWidth: calc(~"100vw - 20px");
    width: @screenWidth;
    .abody{
      width: 100%;
      padding: 0px 20px 0px 20px;
      .tokeninfo{
        .ticontent{
          .tiinput{
            >input{
              width: 160px;
            }
          }
        }
      }
      .plus{
        width: 30px;
        >img{
          width: 30px;
        }
      }
      .btns{
        .btnconnect{
          width: 100%;
          height: 50px;
          line-height: 50px;
        }
        .connectedbtns{
          .btn{
            width: 140px;
            height: 50px;
            font-size: 17px;
            line-height: 50px;
          }
        }
      }
    }
  }
  .tokentip{
    margin-left: 70px;
    .direction{
      margin-left: 170px;
    }
  }
}
}
</style>

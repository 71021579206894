import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    walletInfo:{chainCode:'',simpleAddress:'', address:'', },
    msg:"",
  },
  mutations: {
    initWalletInfo(state, info){
      state.walletInfo=info;
    },
    showMsg(state, info){
      state.msg=info;
    }
  },
  actions: {
    initWalletInfo({ commit, state }, walletInfo){
      commit('initWalletInfo', walletInfo);
    },
    showMsg({ commit, state }, msg){
      commit('showMsg', msg);
      setTimeout(()=>{
        commit('showMsg', "");
      },2500)
    },
  },

});

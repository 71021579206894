<template>
  <div class="sign">
    <div class="cpshader"></div>
    <div class="dialog">
      <div class="flex dheader">
        <div class="close" @click="$emit('close')"><img src="@/assets/imgs/icon_close.png"/></div>
      </div>
      <div class="dbody">
        <div class="title">Verify your account</div>
        <div class="desc">To finish connecting, you must sign amessage in your wallet to verify that you arethe owner of this account</div>
        <div class="flex btns">
          <div :class="{'btn':true, 'grayBtn':sendingTransFlag}" @click="doSign()">Send message</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";

import * as stringUtils from "@/assets/js/stringutils";
export default {
  name:"Sign",
  data:function () {
    return {
      sendingTransFlag:false,
    }
  },
  methods:{
    doSign(){
      const walletInfo=this.$store.state.walletInfo;
      if(stringUtils.isEmpty(walletInfo.address)){
        return ;
      }
      const inviter=stringUtils.getQueryParam("ref");
      var signMsg="https://etafinance.com wants you to sign in withyour Ethereum.\naccount:"+walletInfo.address;
      signMsg=stringUtils.isEmpty(inviter)?signMsg:signMsg+"\ninviter:"+inviter;
      signMsg+="\n\nSign in with ethereum to EtaFinance";

      this.sendingTransFlag=true;
      this.$chainFactory.ethSign(signMsg, (error, signText, hexMsg)=>{
        this.sendingTransFlag=false;
        $.ajax({
          url:this.$appConfig.queryCenter().url+this.$appConfig.queryUrl().checkSign,
          type:"post",
          data:{"address":walletInfo.address, "inviterAddress":inviter, "data":hexMsg.substr(2,hexMsg.length), "signHex":signText},
          success:(result)=>{
            console.info("result:"+JSON.stringify(result));
            if(result.status=="200"){
              this.$emit('close');
            }
          }
        })
      });
    }
  }
}
</script>
<style lang="less" scoped>
  .sign {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    z-index: 100;

    .cpshader {
      width: 100%;
      height: 100%;
      position: fixed;
      background: rgba(#141425, 0.65);
    }
    .dialog {
      width: 460px;
      min-height: 330px;
      margin: 0 auto;
      background: #1D1D38;
      box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.5);
      border-radius: 20px;
      border: 1px solid #414183;
      padding: 10px;
      z-index: 110;

      .dheader {
        flex-direction: row-reverse;
        .close {
          cursor: pointer;
          > img {
            width: 30px;
          }
        }
      }
      .dbody {
        width: 400px;
        margin: 20px auto 0;
        .title{
          text-align: center;
          font-size: 20px;
        }
        .desc{
          text-align: center;
          margin-top: 20px;
          font-size: 14px;
          color: #7676B1;
        }
        .btns{
          .btn{
            width: 300px;
            height: 40px;
            margin: 80px auto 0;
            font-size: 16px;
            text-align: center;
            line-height: 40px;
            background: #2B97A1;
            border-radius: 8px;
            cursor: pointer;
          }
        }
      }
    }
  }
</style>

<template>
  <div class="max">
    <div class="flex header">
      <div class="flex hleft">
        <div class="menuicon" @click="mmenuFlag=!mmenuFlag"><img src="@/assets/imgs/icon_menu.png"/></div>
        <div class="logo" @click="toMenu('home')"><img src="@/assets/imgs/logo.png"/></div>
        <div class="flex menus">
          <div :class="{'menu':true, 'curMenu':$route.name=='home'}" @click="toMenu('home')">Home</div>
          <div :class="{'menu':true, 'curMenu':$route.name=='swap' || $route.name=='liquidity'}" @click="toMenu('swap')">Swap</div>
          <div :class="{'menu':true, 'curMenu':$route.name=='bridge'}">Bridge</div>
          <div :class="{'menu':true, 'curMenu':$route.name=='feucet'}" @click="toMenu('feucet')">Faucet</div>
          <div :class="{'menu':true, 'curMenu':$route.name=='docs'}" @click="toMenu('docs')">Docs</div>
          <div :class="{'menu':true, 'curMenu':$route.name=='rank'}" @click="toMenu('rank')">Rank</div>
        </div>
      </div>
      <div class="btns">
        <div v-if="$store.state.walletInfo.simpleAddress==''" class="launchApp">Connect Wallet</div>
        <div v-if="$store.state.walletInfo.simpleAddress!=''" class="flex connectedbtns">
          <div class="chain">
            <img class="chainlogo" src="@/assets/imgs/icon_chainscroll.png"/>
            <span class="chainname">Scroll Alpha</span>
<!--            <img class="morechain" src="@/assets/imgs/icon_fdown.png"/>-->
          </div>
          <div class="simpleaddress" @click="toMenu('profile')">{{$store.state.walletInfo.simpleAddress}}</div>
        </div>
      </div>
    </div>
    <div class="line"></div>

    <router-view/>

<!--    <div class="line"></div>-->
<!--    <div class="footer">-->
<!--      <div class="flex fcontent">-->
<!--        <span>Ecosystem</span>-->
<!--        <span>Community</span>-->
<!--        <span>Governance</span>-->
<!--        <span>Developers</span>-->
<!--        <span>Blog</span>-->
<!--        <span>FAQPrivacy</span>-->
<!--        <span>Policy</span>-->
<!--        <span>PolicySecurity</span>-->
<!--      </div>-->
<!--    </div>-->

    <div class="flex msgcontainer" v-if="$store.state.msg!=''">
      <div class="msg">{{$store.state.msg}}</div>
    </div>


      <div class="mmenuctx" v-show="mmenuFlag">
        <div class="cpshader" @click="mmenuFlag=false"></div>
        <transition name="fadein">
          <div class="mmenus" v-show="mmenuFlag">
            <div :class="{'mmenuitem':true,  'curmmenuitem':$route.name=='home'}" @click="toMenu('home')">
              <span class="mmtext">Home</span>
            </div>
            <div :class="{'mmenuitem':true,  'curmmenuitem':$route.name=='swap' || $route.name=='liquidity'}" @click="toMenu('swap')">
              <span class="mmtext">Swap</span>
            </div>
            <div :class="{'mmenuitem':true,  'curmmenuitem':$route.name=='bridge'}">
              <span class="mmtext">Bridge</span>
            </div>
            <div :class="{'mmenuitem':true,  'curmmenuitem':$route.name=='feucet'}" @click="toMenu('feucet')">
              <span class="mmtext">Faucet</span>
            </div>
            <div :class="{'mmenuitem':true,  'curmmenuitem':$route.name=='docs'}">
              <span class="mmtext">Docs</span>
            </div>
            <div :class="{'mmenuitem':true,  'curmmenuitem':$route.name=='rank'}" @click="toMenu('rank')">
              <span class="mmtext">Rank</span>
            </div>
            <div :class="{'mmenuitem':true,  'curmmenuitem':$route.name=='profile'}" @click="toMenu('profile')">
              <span class="mmtext">Profile</span>
            </div>
            <div :class="{'mmenuitem':true,  'curmmenuitem':$route.name=='points'}" @click="toMenu('points')">
              <span class="mmtext">Points</span>
            </div>
            <div :class="{'mmenuitem':true,  'curmmenuitem':$route.name=='world'}" @click="toMenu('world')">
              <span class="mmtext">Eta World</span>
            </div>
            <div :class="{'mmenuitem':true,  'curmmenuitem':$route.name=='nfts'}">
              <span class="mmtext">NFTs</span>
            </div>
            <div :class="{'mmenuitem':true,  'curmmenuitem':$route.name=='search'}">
              <span class="mmtext">Search</span>
            </div>
          </div>
        </transition>
      </div>

    <transition name="fadein" v-on:enter="++connectedWalletKey">
      <connected-wallet v-show="connectedWalletCpFlag" v-on:close="connectedWalletCpFlag=false" :connectedWalletKey="connectedWalletKey"></connected-wallet>
    </transition>

    <transition name="fadein" v-on:enter="++signKey">
      <sign v-show="signCpFlag" v-on:close="signCpFlag=false" :signKey="signKey"/>
    </transition>
  </div>
</template>
<script>
import $ from "jquery";
import * as stringUtils from "../assets/js/stringutils";
import ConnectedWallet from "../components/ConnectedWallet";
import Sign from "../components/Sign";

// import { configureChains, createClient } from "@wagmi/core";
// import { arbitrum, mainnet, polygon } from "@wagmi/core/chains";
// import { Web3Modal } from "@web3modal/html";
// import {EthereumClient, modalConnectors, walletConnectProvider,} from "@web3modal/ethereum";

export default {
  name:"index",
  components: {ConnectedWallet, Sign},
  data(){
    return {
      curMenu:"home",

      mmenuFlag:false,

      connectedWalletCpFlag:false,
      connectedWalletKey:0,

      signCpFlag:false,
      signKey:0,
    }
  },
  mounted(){
    this.$chainFactory.connectDefaultWallet(()=>{
      $.ajax({
        url: this.$appConfig.queryCenter().url + this.$appConfig.queryUrl().getUserInfo,
        data: {"address": this.$store.state.walletInfo.address},
        success: (result) => {
          if (result.status == "200") {
            const userInfo = result.data;
            if ("Y" != userInfo.verified) {
              this.signCpFlag = true;
            }
          }
        }
      })
    });
  },
  methods:{
    toMenu(menu){
      this.mmenuFlag=false;
      this.curMenu=menu;
      if("home"==menu){
        this.$router.push('/');
      }else if("docs"==menu){
        window.open("https://docs.etafinance.com");
      }else{
        this.$router.push("/"+menu);
      }
    },
    // toWalletConnect(){
    //   const chains = [arbitrum, mainnet, polygon];
    //
    //   // Wagmi Core Client
    //   const { provider } = configureChains(chains, [
    //     walletConnectProvider({ projectId: "2c79651ad8c53109387d202d9b78d5f1" }),
    //   ]);
    //   const wagmiClient = createClient({
    //     autoConnect: true,
    //     connectors: modalConnectors({
    //       projectId: "2c79651ad8c53109387d202d9b78d5f1",
    //       version: "2", // or "2"
    //       appName: "web3Modal",
    //       chains,
    //     }),
    //     provider,
    //   });
    //
    //   // Web3Modal and Ethereum Client
    //   const ethereumClient = new EthereumClient(wagmiClient, chains);
    //   const web3modal = new Web3Modal(
    //       { projectId: "2c79651ad8c53109387d202d9b78d5f1" },
    //       ethereumClient
    //   );
    // }
  }
}
</script>
<style lang="less" scoped>
.max{
  .header{
    width: 1180px;
    height: 60px;
    margin: 0 auto;
    z-index: 1000;
    position: relative;
    .menuicon{
      display: none;
    }
    .logo{
      cursor: pointer;
      >img{
        width: 81px;
      }
    }
    .menus{
      width: 360px;
      margin-left: 40px;
      font-size: 14px;
      .menu{
        color: #5F5FA3;
        cursor: pointer;
      }
      .menu:hover{
        color: #FFFFFF;
      }
      .curMenu{
        color: #FFFFFF;
      }
    }
    .btns{
      .launchApp{
        width: 120px;
        height: 30px;
        background: #2B97A1;
        border-radius: 8px;
        line-height: 30px;
        text-align: center;
        font-size: 14px;
        cursor: pointer;
      }
      .launchApp:hover{
        background: #36BAC6;
      }
      .connectedbtns{
        color: #E6E6FF;
        .chain{
          height: 40px;
          padding-left: 10px;
          padding-right: 10px;
          background: #1D1D3C;
          border-radius: 13px;
          border: 1px solid #414183;
          margin-right: 15px;
          line-height: 40px;
          cursor: pointer;
          .chainlogo{
            width:  20px;
            margin-right: 5px;
          }
          .chainname{
            margin-right: 5px;
          }
          .morechain{
            width: 16px;
          }
        }
        .chain:hover{
          background: rgba(#414183,0.7);
        }
        .simpleaddress{
          height: 40px;
          padding-left: 10px;
          padding-right: 10px;
          background: #1D1D3C;
          border-radius: 8px;
          border: 1px solid #414183;
          cursor: pointer;
          color: #5F5FA3;
          line-height: 40px;
        }
        .simpleaddress:hover{
          background: rgba(#414183,0.7);
        }
      }
    }
  }
  .line{
    width: 100%;
    height: 1px;
    background: #1F1F55;
  }

  .footer{
    height: 20px;
    .fcontent{
      width: 774px;
      margin: 20px auto 0;
      font-size: 14px;
      color: #7676B1;
      >span{
        cursor: pointer;
      }
      :hover{
        color: #FFFFFF;
      }
    }
  }
  .msgcontainer{
    flex-direction: column;
    .msg{
      z-index: 200;
      padding: 5px 10px 5px 10px;
      background: #414183;
      color: #E6E6FF;
      position: fixed;
      top: 10%;
      font-size: 15px;
      border-radius: 6px;
    }
  }
  .mmenuctx{
    display: none;
  }
}
@media only screen and (max-width: 670px) {
.max{
  .header{
    width: 100%;
    background: rgba(20,20,37,0.2);
    .menuicon{
      display: block;
      margin-left: 20px;
      margin-right: 20px;
      >img{
        width: 20px;
      }
    }
    .menus{
      display: none;
    }
    .btns{
      margin-right: 20px;
      .connectedbtns{
        .chain{
          .chainname{
            display: none;
          }
        }
      }
    }
  }
  .line{
    display: none;
  }
  .footer{
    display: none;
    height: max-content;
    .fcontent{
      width: 300px;
      margin-bottom: 20px;
      flex-wrap: wrap;
      >span{
        width: 100px;
        margin-top: 5px;
      }
    }
  }
  .mmenuctx{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    z-index: 150;
    .cpshader {
      width: 100%;
      height: 100%;
      position: fixed;
      background: rgba(#141425, 0.65);
    }
    .mmenus{
      width: 200px;
      height: 600px;
      padding: 20px;
      margin-top: 60px;
      margin-left: 10px;
      border-radius: 10px;
      background: rgba(29,30,61);
      justify-content: flex-end;
      z-index: 160;
      .mmenuitem{
        width: 100%;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 20px;
        color: #5F5FA3;
        border-radius: 10px;
      }
      .curmmenuitem{
        color: rgba(67,176,193);
        background: rgba(48,47,91);
      }
      .subMenus{
        padding-left: 20px;
        .subMenuItem{
          font-size: 16px;
          height: 40px;
          line-height: 40px;
          text-align: center;
        }
      }
    }
  }
}
}
</style>

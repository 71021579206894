export const assetsTokens = [{
  chainCode: "ETH",
  tokens: [
    {'name': 'ETH', 'symbol': 'ETH', 'address': '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/ethereum/0x0000000000000000000000000000000000000000.png', 'balance': ''},
    {'name': 'Tether USD', 'symbol': 'USDT', 'address': '0xdAC17F958D2ee523a2206206994597C13D831ec7', 'decimals': 18, 'logoURI': 'https://etherscan.io/token/images/tethernew_32.png', 'balance': ''},
    {'name': 'BNB', 'symbol': 'BNB', 'address': '0xB8c77482e45F1F44dE1745F52C74426C631bDD52', 'decimals': 18, 'logoURI': 'https://etherscan.io/token/images/bnb_28_2.png', 'balance': ''},
    {'name': 'USD Coin', 'symbol': 'USDC', 'address': '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', 'decimals': 18, 'logoURI': 'https://etherscan.io/token/images/centre-usdc_28.png', 'balance': ''},
    {'name': 'HEX', 'symbol': 'HEX', 'address': '0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39', 'decimals': 18, 'logoURI': 'https://etherscan.io/token/images/hex_32.png', 'balance': ''},
    {'name': 'Matic Token', 'symbol': 'MATIC', 'address': '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0', 'decimals': 18, 'logoURI': 'https://etherscan.io/token/images/polygonnew_32.png', 'balance': ''},
    {'name': 'SHIBA INU', 'symbol': 'SHIB', 'address': '0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE', 'decimals': 18, 'logoURI': 'https://etherscan.io/token/images/shibatoken_32.png', 'balance': ''},
    {'name': 'Binance USD', 'symbol': 'BUSD', 'address': '0x4Fabb145d64652a948d72533023f6E7A623C7C53', 'decimals': 18, 'logoURI': 'https://etherscan.io/token/images/binanceusd_32.png', 'balance': ''},
    {'name': 'Arbitrum', 'symbol': 'ARB', 'address': '0xB50721BCf8d664c30412Cfbc6cf7a15145234ad1', 'decimals': 18, 'logoURI': 'https://etherscan.io/token/images/arbitrumone2_32.png', 'balance': ''},
    {'name': 'ANY Litecoin', 'symbol': 'anyLTC', 'address': '0x0aBCFbfA8e3Fda8B7FBA18721Caf7d5cf55cF5f5', 'decimals': 18, 'logoURI': 'https://etherscan.io/token/images/anylitecoin_32.png', 'balance': ''},
    {'name': 'TrueUSD', 'symbol': 'TUSD', 'address': '0x0000000000085d4780B73119b644AE5ecd22b376', 'decimals': 18, 'logoURI': 'https://etherscan.io/token/images/trueusd_32.png?v=2', 'balance': ''},
    {'name': 'Fantom Token', 'symbol': 'FTM', 'address': '0x4E15361FD6b4BB609Fa63C81A2be19d873717870', 'decimals': 18, 'logoURI': 'https://etherscan.io/token/images/fantomtoken_32.png', 'balance': ''},
    {'name': 'Theta Token', 'symbol': 'THETA', 'address': '0x3883f5e181fccaF8410FA61e12b59BAd963fb645', 'decimals': 18, 'logoURI': 'https://etherscan.io/token/images/theta_28.png', 'balance': ''},
    {'name': 'ChainLink Token', 'symbol': 'LINK', 'address': '0x514910771AF9Ca656af840dff83E8264EcF986CA', 'decimals': 18, 'logoURI': 'https://etherscan.io/token/images/chainlinktoken_32.png?v=6', 'balance': ''},
    {'name': 'Mask Network', 'symbol': 'MASK', 'address': '0x69af81e73A73B40adF4f3d4223Cd9b1ECE623074', 'decimals': 18, 'logoURI': 'https://etherscan.io/token/images/mask_32.png', 'balance': ''},
    {'name': 'dYdX', 'symbol': 'DYDX', 'address': '0x92D6C1e31e14520e676a687F0a93788B716BEff5', 'decimals': 18, 'logoURI': 'https://etherscan.io/token/images/dydx2_32.png', 'balance': ''},
    {'name': 'Wrapped BTC', 'symbol': 'WBTC', 'address': '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599', 'decimals': 18, 'logoURI': 'https://etherscan.io/token/images/wbtc_28.png?v=1', 'balance': ''},
    {'name': 'Gala', 'symbol': 'GALA', 'address': '0x15D4c048F83bd7e37d49eA4C83a07267Ec4203dA', 'decimals': 18, 'logoURI': 'https://etherscan.io/token/images/gala_32.png?v=3', 'balance': ''},
    {'name': 'Dai Stablecoin', 'symbol': 'DAI', 'address': '0x6B175474E89094C44Da98b954EedeAC495271d0F', 'decimals': 18, 'logoURI': 'https://etherscan.io/token/images/MCDDai_32.png', 'balance': ''},
    {'name': 'SAND', 'symbol': 'SAND', 'address': '0x3845badAde8e6dFF049820680d1F14bD3903a5d0', 'decimals': 18, 'logoURI': 'https://etherscan.io/token/images/sand_32.png', 'balance': ''},
    {'name': 'ApeCoin', 'symbol': 'APE', 'address': '0x4d224452801ACEd8B2F0aebE155379bb5D594381', 'decimals': 18, 'logoURI': 'https://etherscan.io/token/images/apecoin_32.png', 'balance': ''},
    {'name': 'SingularityNET Token', 'symbol': 'AGIX', 'address': '0x5B7533812759B45C2B44C19e320ba2cD2681b542', 'decimals': 18, 'logoURI': 'https://etherscan.io/token/images/singularitynet_28.png', 'balance': ''},
    {'name': 'SPACE ID', 'symbol': 'ID', 'address': '0x2dfF88A56767223A5529eA5960Da7A3F5f766406', 'decimals': 18, 'logoURI': 'https://etherscan.io/token/images/spaceid_32.png', 'balance': ''},
    {'name': 'Lido DAO Token', 'symbol': 'LDO', 'address': '0x5A98FcBEA516Cf06857215779Fd812CA3beF1B32', 'decimals': 18, 'logoURI': 'https://etherscan.io/token/images/lido-dao_32.png', 'balance': ''},
    {'name': 'NEAR', 'symbol': 'NEAR', 'address': '0x85F17Cf997934a597031b2E18a9aB6ebD4B9f6a4', 'decimals': 18, 'logoURI': 'https://etherscan.io/token/images/near_32.png?v=3', 'balance': ''},
    {'name': 'LQTY', 'symbol': 'LQTY', 'address': '0x6DEA81C8171D0bA574754EF6F8b412F2Ed88c54D', 'decimals': 18, 'logoURI': 'https://etherscan.io/token/images/liquity_32.png', 'balance': ''},
    {'name': 'OMG Network', 'symbol': 'OMG', 'address': '0xd26114cd6EE289AccF82350c8d8487fedB8A0C07', 'decimals': 18, 'logoURI': 'https://etherscan.io/token/images/OMGNetwork_32.png', 'balance': ''},
    {'name': 'Wrapped Decentraland MANA', 'symbol': 'wMANA', 'address': '0xFd09Cf7cFffa9932e33668311C4777Cb9db3c9Be', 'decimals': 18, 'logoURI': 'https://etherscan.io/token/images/decentraland_32.png?v=1', 'balance': ''},
    {'name': 'Graph Token', 'symbol': 'GRT', 'address': '0xc944E90C64B2c07662A292be6244BDf05Cda44a7', 'decimals': 18, 'logoURI': 'https://etherscan.io/token/images/TheGraph_32.png', 'balance': ''},
    {'name': 'chiliZ', 'symbol': 'CHZ', 'address': '0x3506424F91fD33084466F402d5D97f05F8e3b4AF', 'decimals': 18, 'logoURI': 'https://etherscan.io/token/images/chiliz_28.png', 'balance': ''},
    {'name': 'SushiToken', 'symbol': 'SUSHI', 'address': '0x6B3595068778DD592e39A122f4f5a5cF09C90fE2', 'decimals': 18, 'logoURI': 'https://etherscan.io/token/images/sushitoken_32.png', 'balance': ''},
    {'name': 'Uniswap', 'symbol': 'UNI', 'address': '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984', 'decimals': 18, 'logoURI': 'https://etherscan.io/token/images/uniswap_32.png', 'balance': ''},
    {'name': 'Synthetix Network Token', 'symbol': 'SNX', 'address': '0xC011a73ee8576Fb46F5E1c5751cA3B9Fe0af2a6F', 'decimals': 18, 'logoURI': 'https://etherscan.io/token/images/SynthetixSNX_32.png', 'balance': ''},
    {'name': 'OKB', 'symbol': 'OKB', 'address': '0x75231F58b43240C9718Dd58B4967c5114342a86c', 'decimals': 18, 'logoURI': 'https://etherscan.io/token/images/okex_28.png', 'balance': ''},
    {'name': 'CelerToken', 'symbol': 'CELR', 'address': '0x4F9254C83EB525f9FCf346490bbb3ed28a81C667', 'decimals': 18, 'logoURI': 'https://etherscan.io/token/images/celer_28.png', 'balance': ''},
    {'name': 'Blur', 'symbol': 'BLUR', 'address': '0x5283D291DBCF85356A21bA090E6db59121208b44', 'decimals': 18, 'logoURI': 'https://etherscan.io/token/images/blurio_32.png', 'balance': ''},
    {'name': 'ConstitutionDAO', 'symbol': 'PEOPLE', 'address': '0x7A58c0Be72BE218B41C608b7Fe7C5bB630736C71', 'decimals': 18, 'logoURI': 'https://etherscan.io/token/images/constitutiondao_32.png', 'balance': ''},
    {'name': 'Wrapped Ether', 'symbol': 'WETH', 'address': '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', 'decimals': 18, 'logoURI': 'https://etherscan.io/token/images/weth_28.png', 'balance': ''},
    {'name': 'renDOGE', 'symbol': 'renDOGE', 'address': '0x3832d2F059E55934220881F831bE501D180671A7', 'decimals': 18, 'logoURI': 'https://etherscan.io/token/images/rendoge_32.png?=v1', 'balance': ''},
    {'name': 'Polygon', 'symbol': 'MATIC', 'address': '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/ethereum/0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0.png', 'balance': ''},
  ]
}, {
  chainCode: "Polygon",
  tokens: [
    {'name': 'Matic Token', 'symbol': 'MATIC', 'address': '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', 'decimals': 18, 'logoURI': 'https://polygonscan.com/token/images/matic_32.png', 'balance': ''},
    {'name': '(PoS) Tether USD', 'symbol': 'USDT', 'address': '0xc2132D05D31c914a87C6611C10748AEb04B58e8F', 'decimals': 18, 'logoURI': 'https://polygonscan.com/token/images/tether_32.png', 'balance': ''},
    {'name': 'BNB', 'symbol': 'BNB', 'address': '0x3BA4c387f786bFEE076A58914F5Bd38d668B42c3', 'decimals': 18, 'logoURI': 'https://polygonscan.com/token/images/bnb_28_2.png', 'balance': ''},
    {'name': 'USD Coin (PoS)', 'symbol': 'USDC', 'address': '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174', 'decimals': 18, 'logoURI': 'https://polygonscan.com/token/images/centre-usdc_32.png', 'balance': ''},
    {'name': 'binance-usd', 'symbol': 'BUSD', 'address': '0xdAb529f40E671A1D4bF91361c21bf9f0C9712ab7', 'decimals': 18, 'logoURI': 'https://polygonscan.com/token/images/binanceusd_32.png', 'balance': ''},
    {'name': 'SHIBA INU (PoS)', 'symbol': 'SHIB', 'address': '0x6f8a06447Ff6FcF75d803135a7de15CE88C1d4ec', 'decimals': 18, 'logoURI': 'https://polygonscan.com/token/images/shibainu_32.png', 'balance': ''},
    {'name': 'Avalanche Token', 'symbol': 'AVAX', 'address': '0x2C89bbc92BD86F8075d1DEcc58C7F4E0107f286b', 'decimals': 18, 'logoURI': 'https://polygonscan.com/token/images/avaxbtt_32.png', 'balance': ''},
    {'name': '(PoS) Dai Stablecoin', 'symbol': 'DAI', 'address': '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063', 'decimals': 18, 'logoURI': 'https://polygonscan.com/token/images/mcdDai_32.png', 'balance': ''},
    {'name': 'Uniswap (PoS)', 'symbol': 'UNI', 'address': '0xb33EaAd8d922B1083446DC23f610c2567fB5180f', 'decimals': 18, 'logoURI': 'https://polygonscan.com/token/images/uniswap_32.png', 'balance': ''},
    {'name': '(PoS) Wrapped BTC', 'symbol': 'WBTC', 'address': '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6', 'decimals': 18, 'logoURI': 'https://polygonscan.com/token/images/wBTC_32.png', 'balance': ''},
    {'name': 'ChainLink Token', 'symbol': 'LINK', 'address': '0xb0897686c545045aFc77CF20eC7A532E3120E0F1', 'decimals': 18, 'logoURI': 'https://polygonscan.com/token/images/chainlinktoken_32.png', 'balance': ''},
    {'name': 'itfinex LEO Token', 'symbol': 'LEO', 'address': '0x06D02e9D62A13fC76BB229373FB3BBBD1101D2fC', 'decimals': 18, 'logoURI': 'https://polygonscan.com/token/images/leo_28_2.png', 'balance': ''},
    {'name': 'TrueUSD', 'symbol': 'TUSD', 'address': '0x2e1AD108fF1D8C782fcBbB89AAd783aC49586756', 'decimals': 18, 'logoURI': 'https://polygonscan.com/token/images/trueusd_32.png', 'balance': ''},
    {'name': 'Lido DAO Token (PoS)', 'symbol': 'LDO', 'address': '0xC3C7d422809852031b44ab29EEC9F1EfF2A58756', 'decimals': 18, 'logoURI': 'https://polygonscan.com/token/images/lidodao_32.png', 'balance': ''},
    {'name': 'Cronos Coin', 'symbol': 'CRO', 'address': '0xAdA58DF0F643D959C2A47c9D4d4c1a4deFe3F11C', 'decimals': 18, 'logoURI': 'https://polygonscan.com/token/images/cro_32.png', 'balance': ''},
    {'name': 'ApeCoin (PoS)', 'symbol': 'APE', 'address': '0xB7b31a6BC18e48888545CE79e83E06003bE70930', 'decimals': 18, 'logoURI': 'https://polygonscan.com/token/images/apecoinpoly_32.png', 'balance': ''},
    {'name': 'Graph Token', 'symbol': 'GRT', 'address': '0x5fe2B58c013d7601147DcdD68C143A77499f5531', 'decimals': 18, 'logoURI': 'https://polygonscan.com/token/images/TheGraph_32.png', 'balance': ''},
    {'name': 'Fantom Token', 'symbol': 'FTM', 'address': '0xC9c1c1c20B3658F8787CC2FD702267791f224Ce1', 'decimals': 18, 'logoURI': 'https://polygonscan.com/token/images/fantomtoken_32.png', 'balance': ''},
    {'name': 'SAND', 'symbol': 'SAND', 'address': '0xBbba073C31bF03b8ACf7c28EF0738DeCF3695683', 'decimals': 18, 'logoURI': 'https://polygonscan.com/token/images/sand_32.png', 'balance': ''},
    {'name': 'Frax', 'symbol': 'FRAX', 'address': '0x45c32fA6DF82ead1e2EF74d17b76547EDdFaFF89', 'decimals': 18, 'logoURI': 'https://polygonscan.com/token/images/frax_32.png', 'balance': ''},
    {'name': 'Decentraland MANA', 'symbol': 'MANA', 'address': '0xA1c57f48F0Deb89f569dFbE6E2B7f46D33606fD4', 'decimals': 18, 'logoURI': 'https://polygonscan.com/token/images/decentraland_32.png', 'balance': ''},
    {'name': 'Aave (PoS)', 'symbol': 'AAVE', 'address': '0xD6DF932A45C0f255f85145f286eA0b292B21C90B', 'decimals': 18, 'logoURI': 'https://polygonscan.com/token/images/aave_32.png', 'balance': ''},
    {'name': 'Theta Token (PoS)', 'symbol': 'THETA', 'address': '0xB46E0ae620EFd98516f49bb00263317096C114b2', 'decimals': 18, 'logoURI': 'https://polygonscan.com/token/images/theta_28.png', 'balance': ''},
    {'name': 'Rocket Pool', 'symbol': 'RPL', 'address': '0x7205705771547cF79201111B4bd8aaF29467b9eC', 'decimals': 18, 'logoURI': 'https://polygonscan.com/token/images/Rocketpool_32.png', 'balance': ''},
    {'name': 'Wrapped Ether', 'symbol': 'WETH', 'address': '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619', 'decimals': 18, 'logoURI': 'https://polygonscan.com/token/images/wETH_32.png', 'balance': ''},
    {'name': 'Mask Network', 'symbol': 'MASK', 'address': '0x2B9E7ccDF0F4e5B24757c1E1a80e311E34Cb10c7', 'decimals': 18, 'logoURI': 'https://polygonscan.com/token/images/mask_32.png', 'balance': ''},
    {'name': 'LoopringCoin V2', 'symbol': 'LRC', 'address': '0x84e1670F61347CDaeD56dcc736FB990fBB47ddC1', 'decimals': 18, 'logoURI': 'https://polygonscan.com/token/images/lrc_32.png', 'balance': ''},
    {'name': '1INCH Token', 'symbol': '1INCH', 'address': '0x9c2C5fd7b07E95EE044DDeba0E97a665F142394f', 'decimals': 18, 'logoURI': 'https://polygonscan.com/token/images/1inch_32.png', 'balance': ''},
    {'name': 'Wootrade Network', 'symbol': 'WOO', 'address': '0x1B815d120B3eF02039Ee11dC2d33DE7aA4a8C603', 'decimals': 18, 'logoURI': 'https://polygonscan.com/token/images/wootradepoly_32.png', 'balance': ''},
    {'name': 'Synthetix Network Token (PoS)', 'symbol': 'SNX', 'address': '0x50B728D8D964fd00C2d0AAD81718b71311feF68a', 'decimals': 18, 'logoURI': 'https://polygonscan.com/token/images/synthetix-snx_32.png', 'balance': ''},
    {'name': 'Decentralized USD (PoS)', 'symbol': 'USDD', 'address': '0xFFA4D863C96e743A2e1513824EA006B8D0353C57', 'decimals': 18, 'logoURI': 'https://polygonscan.com/token/images/usddtron_32.png', 'balance': ''},
    {'name': 'chiliZ', 'symbol': 'CHZ', 'address': '0xf1938Ce12400f9a761084E7A80d37e732a4dA056', 'decimals': 18, 'logoURI': 'https://polygonscan.com/token/images/chiliz_28.png?v=2', 'balance': ''},
    {'name': 'Gnosis', 'symbol': 'GNO', 'address': '0x5FFD62D3C3eE2E81C00A7b9079FB248e7dF024A8', 'decimals': 18, 'logoURI': 'https://polygonscan.com/token/images/gnosans_32.png', 'balance': ''},
  ]
},{
  chainCode: "Arbitrum",
  tokens: [
    {'name': 'ETH', 'symbol': 'ETH', 'address': '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/arbitrum/0x0000000000000000000000000000000000000000.png', 'balance': ''},
    {'name': 'USD Coin (Arb1)', 'symbol': 'USDC', 'address': '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8', 'decimals': 18, 'logoURI': 'https://polygonscan.com/token/images/centre-usdc_32.png', 'balance': ''},
    {'name': 'MAGIC', 'symbol': 'MAGIC', 'address': '0x539bde0d7dbd336b79148aa742883198bbf60342', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/arbitrum/0x539bde0d7dbd336b79148aa742883198bbf60342.png', 'balance': ''},
    {'name': 'Tether', 'symbol': 'USDT', 'address': '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/arbitrum/0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9.png', 'balance': ''},
    {'name': 'WETH', 'symbol': 'WETH', 'address': '0x82af49447d8a07e3bd95bd0d56f35241523fbab1', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/arbitrum/0x82af49447d8a07e3bd95bd0d56f35241523fbab1.png', 'balance': ''},
    {'name': 'Arbitrum', 'symbol': 'ARB', 'address': '0x912ce59144191c1204e64559fe8253a0e49e6548', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/arbitrum/0x912ce59144191c1204e64559fe8253a0e49e6548.png', 'balance': ''},
    {'name': 'TrueUSD', 'symbol': 'TUSD', 'address': '0x4d15a3a2286d883af0aa1b3f21367843fac63e07', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/arbitrum/0x4d15a3a2286d883af0aa1b3f21367843fac63e07.png', 'balance': ''},
    {'name': 'Chainlink', 'symbol': 'LINK', 'address': '0xf97f4df75117a78c1a5a0dbb814af92458539fb4', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/arbitrum/0xf97f4df75117a78c1a5a0dbb814af92458539fb4.png', 'balance': ''},
    {'name': 'Dai', 'symbol': 'DAI', 'address': '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/arbitrum/0xda10009cbd5d07dd0cecc66161fc93d7c9000da1.png', 'balance': ''},
    {'name': 'Wrapped Bitcoin', 'symbol': 'WBTC', 'address': '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/arbitrum/0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f.png', 'balance': ''},
    {'name': 'JOE', 'symbol': 'JOE', 'address': '0x371c7ec6d8039ff7933a2aa28eb827ffe1f52f07', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/arbitrum/0x371c7ec6d8039ff7933a2aa28eb827ffe1f52f07.png', 'balance': ''},
    {'name': 'Lido DAO', 'symbol': 'LDO', 'address': '0x13ad51ed4f1b7e9dc168d8a00cb3f4ddd85efa60', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/arbitrum/0x13ad51ed4f1b7e9dc168d8a00cb3f4ddd85efa60.png', 'balance': ''},
    {'name': 'Graph Token', 'symbol': 'GRT', 'address': '0x23a941036ae778ac51ab04cea08ed6e2fe103614', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/arbitrum/0x23a941036ae778ac51ab04cea08ed6e2fe103614.png', 'balance': ''},
    {'name': 'GMX', 'symbol': 'GMX', 'address': '0xfc5a1a6eb076a2c7ad06ed22c90d7e710e35ad0a', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/arbitrum/0xfc5a1a6eb076a2c7ad06ed22c90d7e710e35ad0a.png', 'balance': ''},
    {'name': 'SUSHI', 'symbol': 'Sushi', 'address': '0xd4d42f0b6def4ce0383636770ef773390d85c61a', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/arbitrum/0xd4d42f0b6def4ce0383636770ef773390d85c61a.png', 'balance': ''},
    {'name': 'UNI', 'symbol': 'Uniswap', 'address': '0xfa7f8980b0f1e64a2062791cc3b0871572f1f7f0', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/arbitrum/0xfa7f8980b0f1e64a2062791cc3b0871572f1f7f0.png', 'balance': ''},
    {'name': 'CRV', 'symbol': 'Curve DAO', 'address': '0x11cdb42b0eb46d95f990bedd4695a6e3fa034978', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/arbitrum/0x11cdb42b0eb46d95f990bedd4695a6e3fa034978.png', 'balance': ''},
    {'name': 'LRC', 'symbol': 'Loopring', 'address': '0x46d0ce7de6247b0a95f67b43b589b4041bae7fbe', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/arbitrum/0x46d0ce7de6247b0a95f67b43b589b4041bae7fbe.png', 'balance': ''},
    {'name': 'CELR', 'symbol': 'Celer Network', 'address': '0x3a8b787f78d775aecfeea15706d4221b40f345ab', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/arbitrum/0x3a8b787f78d775aecfeea15706d4221b40f345ab.png', 'balance': ''},
    {'name': 'YFI', 'symbol': 'yearn.finance', 'address': '0x82e3a8f066a6989666b031d916c43672085b1582', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/arbitrum/0x82e3a8f066a6989666b031d916c43672085b1582.png', 'balance': ''},
    {'name': 'USDD', 'symbol': 'USDD', 'address': '0x680447595e8b7b3aa1b43beb9f6098c79ac2ab3f', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/arbitrum/0x680447595e8b7b3aa1b43beb9f6098c79ac2ab3f.png', 'balance': ''},
    {'name': 'LUSD', 'symbol': 'Liquity USD', 'address': '0x93b346b6bc2548da6a1e7d98e9a421b42541425b', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/arbitrum/0x93b346b6bc2548da6a1e7d98e9a421b42541425b.png', 'balance': ''},
    {'name': 'STG', 'symbol': 'Stargate Finance', 'address': '0x6694340fc020c5e6b96567843da2df01b2ce1eb6', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/arbitrum/0x6694340fc020c5e6b96567843da2df01b2ce1eb6.png', 'balance': ''},
    {'name': 'RDNT', 'symbol': 'Radiant Capital', 'address': '0x0c4681e6c0235179ec3d4f4fc4df3d14fdd96017', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/arbitrum/0x0c4681e6c0235179ec3d4f4fc4df3d14fdd96017.png', 'balance': ''},
    {'name': 'VELA', 'symbol': 'Vela Token', 'address': '0x088cd8f5ef3652623c22d48b1605dcfe860cd704', 'decimals': 18, 'logoURI': 'https://zapper.xyz/zh/token/arbitrum/0x088cd8f5ef3652623c22d48b1605dcfe860cd704/VELA/details', 'balance': ''},
    {'name': 'SYN', 'symbol': 'Synapse', 'address': '0x080f6aed32fc474dd5717105dba5ea57268f46eb', 'decimals': 18, 'logoURI': 'https://zapper.xyz/zh/token/arbitrum/0x080f6aed32fc474dd5717105dba5ea57268f46eb/SYN/details', 'balance': ''},
    {'name': 'GRAIL', 'symbol': 'Camelot Token', 'address': '0x3d9907f9a368ad0a51be60f7da3b97cf940982d8', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/arbitrum/0x3d9907f9a368ad0a51be60f7da3b97cf940982d8.png', 'balance': ''},
  ]
},{
  chainCode: "BSC",
  tokens:[
    {'name': 'Binance Coin', 'symbol': 'BNB', 'address': '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/binance-smart-chain/0x0000000000000000000000000000000000000000.png', 'balance': ''},
    {'name': 'Tether', 'symbol': 'USDT', 'address': '0x55d398326f99059ff775485246999027b3197955', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/binance-smart-chain/0x55d398326f99059ff775485246999027b3197955.png', 'balance': ''},
    {'name': 'USD Coin', 'symbol': 'USDC', 'address': '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/binance-smart-chain/0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d.png', 'balance': ''},
    {'name': 'Binance USD', 'symbol': 'BUSD', 'address': '0xe9e7cea3dedca5984780bafc599bd69add087d56', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/binance-smart-chain/0xe9e7cea3dedca5984780bafc599bd69add087d56.png', 'balance': ''},
    {'name': 'ETH', 'symbol': 'WETH', 'address': '0x2170ed0880ac9a755fd29b2688956bd959f933f8', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/binance-smart-chain/0x2170ed0880ac9a755fd29b2688956bd959f933f8.png', 'balance': ''},
    {'name': 'TrueUSD', 'symbol': 'TUSD', 'address': '0x14016e85a25aeb13065688cafb43044c2ef86784', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/binance-smart-chain/0x14016e85a25aeb13065688cafb43044c2ef86784.png', 'balance': ''},
    {'name': 'Polygon', 'symbol': 'MATIC', 'address': '0xcc42724c6683b7e57334c4e856f4c9965ed682bd', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/binance-smart-chain/0xcc42724c6683b7e57334c4e856f4c9965ed682bd.png', 'balance': ''},
    {'name': 'Dogecoin', 'symbol': 'DOGE', 'address': '0x4206931337dc273a630d328da6441786bfad668f', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/binance-smart-chain/0x4206931337dc273a630d328da6441786bfad668f.png', 'balance': ''},
    {'name': 'bCFX', 'symbol': 'BSC Conflux', 'address': '0x045c4324039da91c52c55df5d785385aab073dcf', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/binance-smart-chain/0x045c4324039da91c52c55df5d785385aab073dcf.png', 'balance': ''},
    {'name': 'SXP', 'symbol': 'SXP', 'address': '0x47bead2563dcbf3bf2c9407fea4dc236faba485a', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/binance-smart-chain/0x47bead2563dcbf3bf2c9407fea4dc236faba485a.png', 'balance': ''},
    {'name': 'MASK', 'symbol': 'Mask Network', 'address': '0x2ed9a5c8c13b93955103b9a7c167b67ef4d568a3', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/binance-smart-chain/0x2ed9a5c8c13b93955103b9a7c167b67ef4d568a3.png', 'balance': ''},
    {'name': 'LINK', 'symbol': 'Chainlink', 'address': '0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/binance-smart-chain/0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd.png', 'balance': ''},
    {'name': 'SHIB', 'symbol': 'SHIBA INU', 'address': '0x2859e4544c4bb03966803b044a93563bd2d0dd4d', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/binance-smart-chain/0x2859e4544c4bb03966803b044a93563bd2d0dd4d.png', 'balance': ''},
    {'name': 'DAI', 'symbol': 'Dai', 'address': '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/binance-smart-chain/0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3.png', 'balance': ''},
    {'name': 'JOE', 'symbol': 'JOE', 'address': '0x371c7ec6d8039ff7933a2aa28eb827ffe1f52f07', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/binance-smart-chain/0x371c7ec6d8039ff7933a2aa28eb827ffe1f52f07.png', 'balance': ''},
    {'name': 'ATOM', 'symbol': 'Cosmos Hub', 'address': '0x0eb3a705fc54725037cc9e008bdede697f62f335', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/binance-smart-chain/0x0eb3a705fc54725037cc9e008bdede697f62f335.png', 'balance': ''},
    {'name': 'ETC', 'symbol': 'Ethereum Classic', 'address': '0x3d6545b08693dae087e957cb1180ee38b9e3c25e', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/binance-smart-chain/0x3d6545b08693dae087e957cb1180ee38b9e3c25e.png', 'balance': ''},
    {'name': 'WBNB', 'symbol': 'Wrapped BNB', 'address': '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/binance-smart-chain/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c.png', 'balance': ''},
    {'name': 'FET', 'symbol': 'Fetch.ai', 'address': '0x031b41e504677879370e9dbcf937283a8691fa7f', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/binance-smart-chain/0x031b41e504677879370e9dbcf937283a8691fa7f.png', 'balance': ''},
    {'name': 'HOOK', 'symbol': 'Hooked Protocol', 'address': '0xa260e12d2b924cb899ae80bb58123ac3fee1e2f0', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/binance-smart-chain/0xa260e12d2b924cb899ae80bb58123ac3fee1e2f0.png', 'balance': ''},
    {'name': 'AAVE', 'symbol': 'Aave', 'address': '0xfb6115445bff7b52feb98650c87f44907e58f802', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/binance-smart-chain/0xfb6115445bff7b52feb98650c87f44907e58f802.png', 'balance': ''},
    {'name': 'INJ', 'symbol': 'Injective', 'address': '0xa2b726b1145a4773f68593cf171187d8ebe4d495', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/binance-smart-chain/0xa2b726b1145a4773f68593cf171187d8ebe4d495.png', 'balance': ''},
    {'name': 'UNI', 'symbol': 'Uniswap', 'address': '0xbf5140a22578168fd562dccf235e5d43a02ce9b1', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/binance-smart-chain/0xbf5140a22578168fd562dccf235e5d43a02ce9b1.png', 'balance': ''},
    {'name': 'GMT', 'symbol': 'STEPN', 'address': '0x3019bf2a2ef8040c242c9a4c5c4bd4c81678b2a1', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/binance-smart-chain/0x3019bf2a2ef8040c242c9a4c5c4bd4c81678b2a1.png', 'balance': ''},
    {'name': 'BAT', 'symbol': 'Basic Attention Token', 'address': '0x101d82428437127bf1608f699cd651e6abf9766e', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/binance-smart-chain/0x101d82428437127bf1608f699cd651e6abf9766e.png', 'balance': ''},
    {'name': 'SUSHI', 'symbol': 'Sushi', 'address': '0x947950bcc74888a40ffa2593c5798f11fc9124c4', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/binance-smart-chain/0x947950bcc74888a40ffa2593c5798f11fc9124c4.png', 'balance': ''},
    {'name': 'AXS', 'symbol': 'Axie Infinity', 'address': '0x715d400f88c167884bbcc41c5fea407ed4d2f8a0', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/binance-smart-chain/0x715d400f88c167884bbcc41c5fea407ed4d2f8a0.png', 'balance': ''},
    {'name': 'ZEC', 'symbol': 'Zcash Token', 'address': '0x1ba42e5193dfa8b03d15dd1b86a3113bbbef8eeb', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/binance-smart-chain/0x1ba42e5193dfa8b03d15dd1b86a3113bbbef8eeb.png', 'balance': ''},
    {'name': 'YFI', 'symbol': 'yearn.finance', 'address': '0x88f1a5ae2a3bf98aeaf342d26b30a79438c9142e', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/binance-smart-chain/0x88f1a5ae2a3bf98aeaf342d26b30a79438c9142e.png', 'balance': ''},
    {'name': '1INCH', 'symbol': '1INCH', 'address': '0x111111111117dc0aa78b770fa6a738034120c302', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/binance-smart-chain/0x111111111117dc0aa78b770fa6a738034120c302.png', 'balance': ''},
    {'name': 'USDD', 'symbol': 'USDD', 'address': '0xd17479997f34dd9156deef8f95a52d81d265be9c', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/binance-smart-chain/0xd17479997f34dd9156deef8f95a52d81d265be9c.png', 'balance': ''},
    {'name': 'Cake', 'symbol': 'PancakeSwap', 'address': '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/binance-smart-chain/0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82.png', 'balance': ''},
    {'name': 'TWT', 'symbol': 'Trust Wallet', 'address': '0x4b0f1812e5df2a09796481ff14017e6005508003', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/binance-smart-chain/0x4b0f1812e5df2a09796481ff14017e6005508003.png', 'balance': ''},
    {'name': 'HFT', 'symbol': 'Hashflow', 'address': '0x44ec807ce2f4a6f2737a92e985f318d035883e47', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/binance-smart-chain/0x44ec807ce2f4a6f2737a92e985f318d035883e47.png', 'balance': ''},
    {'name': 'BETH', 'symbol': 'Binance ETH staking', 'address': '0x250632378e573c6be1ac2f97fcdf00515d0aa91b', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/binance-smart-chain/0x250632378e573c6be1ac2f97fcdf00515d0aa91b.png', 'balance': ''},
    {'name': 'STG', 'symbol': 'Stargate Finance', 'address': '0xb0d502e938ed5f4df2e681fe6e419ff29631d62b', 'decimals': 18, 'logoURI': 'https://zapper.xyz/zh/token/binance-smart-chain/0xb0d502e938ed5f4df2e681fe6e419ff29631d62b/STG/details', 'balance': ''},
    {'name': 'GAL', 'symbol': 'Galxe', 'address': '0xe4cc45bb5dbda06db6183e8bf016569f40497aa5', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/binance-smart-chain/0xe4cc45bb5dbda06db6183e8bf016569f40497aa5.png', 'balance': ''},
  ]
},{
  chainCode: "Optimistic",
  tokens:[
    {'name': 'Ether', 'symbol': 'ETH', 'address': '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/optimism/0x0000000000000000000000000000000000000000.png', 'balance': ''},
    {'name': 'Tether', 'symbol': 'USDT', 'address': '0x94b008aa00579c1307b0ef2c499ad98a8ce58e58', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/optimism/0x94b008aa00579c1307b0ef2c499ad98a8ce58e58.png', 'balance': ''},
    {'name': 'USD Coin', 'symbol': 'USDC', 'address': '0x7f5c764cbc14f9669b88837ca1490cca17c31607', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/optimism/0x7f5c764cbc14f9669b88837ca1490cca17c31607.png', 'balance': ''},
    {'name': 'BUSD', 'symbol': 'Binance USD', 'address': '0x9c9e5fd8bbc25984b178fdce6117defa39d2db39', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/optimism/0x9c9e5fd8bbc25984b178fdce6117defa39d2db39.png', 'balance': ''},
    {'name': 'WETH', 'symbol': 'WETH', 'address': '0x4200000000000000000000000000000000000006', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/optimism/0x4200000000000000000000000000000000000006.png', 'balance': ''},
    {'name': 'LINK', 'symbol': 'Chainlink', 'address': '0x350a791bfc2c21f9ed5d10980dad2e2638ffa7f6', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/optimism/0x350a791bfc2c21f9ed5d10980dad2e2638ffa7f6.png', 'balance': ''},
    {'name': 'OP', 'symbol': 'Optimism', 'address': '0x4200000000000000000000000000000000000042', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/optimism/0x4200000000000000000000000000000000000042.png', 'balance': ''},
    {'name': 'DAI', 'symbol': 'Dai', 'address': '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/optimism/0xda10009cbd5d07dd0cecc66161fc93d7c9000da1.png', 'balance': ''},
    {'name': 'WBTC', 'symbol': 'Wrapped Bitcoin', 'address': '0x68f180fcce6836688e9084f035309e29bf0a2095', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/optimism/0x68f180fcce6836688e9084f035309e29bf0a2095.png', 'balance': ''},
    {'name': 'LDO', 'symbol': 'Lido DAO', 'address': '0xfdb794692724153d1488ccdbe0c56c252596735f', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/optimism/0xfdb794692724153d1488ccdbe0c56c252596735f.png', 'balance': ''},
    {'name': 'AAVE', 'symbol': 'Aave', 'address': '0x76fb31fb4af56892a25e32cfc43de717950c9278', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/optimism/0x76fb31fb4af56892a25e32cfc43de717950c9278.png', 'balance': ''},
    {'name': 'UNI', 'symbol': 'Uniswap', 'address': '0x6fd9d7ad17242c41f7131d257212c54a0e816691', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/optimism/0x6fd9d7ad17242c41f7131d257212c54a0e816691.png', 'balance': ''},
    {'name': 'CRV', 'symbol': 'Curve DAO', 'address': '0x0994206dfe8de6ec6920ff4d779b0d950605fb53', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/optimism/0x0994206dfe8de6ec6920ff4d779b0d950605fb53.png', 'balance': ''},
    {'name': 'ENS', 'symbol': 'Ethereum Name Service', 'address': '0x65559aa14915a70190438ef90104769e5e890a00', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/optimism/0x65559aa14915a70190438ef90104769e5e890a00.png', 'balance': ''},
    {'name': 'wstETH', 'symbol': 'Wrapped stETH', 'address': '0x1f32b1c2345538c0c6f582fcb022739c4a194ebb', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/optimism/0x1f32b1c2345538c0c6f582fcb022739c4a194ebb.png', 'balance': ''},
    {'name': 'STG', 'symbol': 'Stargate Finance', 'address': '0x296f55f8fb28e498b858d0bcda06d955b2cb3f97', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/optimism/0x296f55f8fb28e498b858d0bcda06d955b2cb3f97.png', 'balance': ''},
    {'name': 'BAL', 'symbol': 'Balancer', 'address': '0xfe8b128ba8c78aabc59d4c64cee7ff28e9379921', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/optimism/0xfe8b128ba8c78aabc59d4c64cee7ff28e9379921.png', 'balance': ''},
    {'name': 'FRAX', 'symbol': 'Frax', 'address': '0x2e3d870790dc77a83dd1d18184acc7439a53f475', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/optimism/0x2e3d870790dc77a83dd1d18184acc7439a53f475.png', 'balance': ''},
    {'name': 'SYN', 'symbol': 'Synapse', 'address': '0x5a5fff6f753d7c11a56a52fe47a177a87e431655', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/optimism/0x5a5fff6f753d7c11a56a52fe47a177a87e431655.png', 'balance': ''},
    {'name': 'LUSD', 'symbol': 'Liquity USD', 'address': '0xc40f949f8a4e094d1b49a23ea9241d289b7b2819', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/optimism/0xc40f949f8a4e094d1b49a23ea9241d289b7b2819.png', 'balance': ''},
    {'name': 'VELO', 'symbol': 'Velodrome Finance', 'address': '0x3c8b650257cfb5f272f799f5e2b4e65093a11a05', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/optimism/0x3c8b650257cfb5f272f799f5e2b4e65093a11a05.png', 'balance': ''},
    {'name': 'KWENTA', 'symbol': 'Kwenta', 'address': '0x920cf626a271321c151d027030d5d08af699456b', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/optimism/0x920cf626a271321c151d027030d5d08af699456b.png', 'balance': ''},
    {'name': 'rETH', 'symbol': 'Rocket Pool ETH', 'address': '0x9bcef72be871e61ed4fbbc7630889bee758eb81d', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/optimism/0x9bcef72be871e61ed4fbbc7630889bee758eb81d.png', 'balance': ''},
    {'name': 'LYRA', 'symbol': 'Lyra Finance', 'address': '0x50c5725949a6f0c72e6c4a641f24049a917db0cb', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/optimism/0x50c5725949a6f0c72e6c4a641f24049a917db0cb.png', 'balance': ''},
    {'name': 'DOLA', 'symbol': 'Dola', 'address': '0x8ae125e8653821e851f12a49f7765db9a9ce7384', 'decimals': 18, 'logoURI': 'https://storage.googleapis.com/zapper-fi-assets/tokens/optimism/0x8ae125e8653821e851f12a49f7765db9a9ce7384.png', 'balance': ''},
  ]
}
]

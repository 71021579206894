import {EtherManagement} from './ethereum';
import store from "../../store/index";
import * as stringUtils from "@/assets/js/stringutils";

export class ChainFactory {

  chainInstance=EtherManagement.getInstance();
  walletInfo = {};

  static getInstance() {
    if(!ChainFactory.instance){
      ChainFactory.instance=new ChainFactory();
    }
    return ChainFactory.instance;
  }

  connectDefaultWallet(callback){
    if(!stringUtils.isEmpty(store.state.walletInfo.address)){
      return ;
    }
    this.connect("ScrollAlpha", callback);
  }

  /**
   * connect to wallet by wallet name,chain code and callback.
   *
   * @param chainSymbol
   * @param callback
   */
  connect(chainSymbol, callback){
    // if("Tron"==chainCode){
    //   this.chainInstance=TronManagement.getInstance();
    // }
    this.chainInstance.connect(chainSymbol, callback);
  }

  /**
   * get contract by abi and contract address
   *
   * @param abi
   * @param address
   */
  getContract(abi, address){
    return this.chainInstance.getContract(abi, address);
  }

  getNormalContract(address){
    return this.chainInstance.getNormalContract(address);
  }


  /**
   * send transaction
   * @param fromAddress
   * @param toAddress
   * @param amount
   */
  sendTransaction(fromAddress, toAddress, amount, data){
    this.chainInstance.sendTransaction(fromAddress, toAddress, amount);
  }

  /**
   * approve
   *
   * @param contract
   * @param toAddress
   * @param amount
   * @param callback
   */
  approve(contract, toAddress, amount, data, sendCallback, confirmCallback, errorCallback){
    this.chainInstance.approve(contract, toAddress, amount, data, sendCallback, confirmCallback, errorCallback);
  }


  transferToken(token, toAddress, amount, data, callback){
    this.chainInstance.transferToken(token, toAddress, amount, data, callback);
  }

  /**
   *  get token balance from main network
   * @param address
   * @param tokenInfo token contract info
   * @param tokenABI token abi
   * @param data other params
   * @param callback callback method
   */
  getTokenBalance(address, tokenInfo, tokenABI, data, callback){
    this.chainInstance.getTokenBalance(address, tokenInfo, tokenABI, data, callback);
  }

  /**
   * get token allowance from main network
   * @param address
   * @param tokenInfo token contract info
   * @param tokenABI token abi
   * @param targetAddress owner address
   * @param data other params
   * @param callback callback method
   */
  getTokenAllowance(address, tokenInfo, tokenABI, targetAddress, data, callback){
    this.chainInstance.getTokenAllowance(address, tokenInfo, tokenABI, targetAddress, data, callback);
  }

  /**
   * check is address or contract
   *
   * @param address
   * @return {*}
   */
  isAddress(address){
    return this.chainInstance.isAddress(address);
  }

  async getTokenInfo(tokenAddress){
    return this.chainInstance.getTokenInfo(tokenAddress);
  }



  async getLiquidityPairInfo(pairAddress, tokenA, tokenB){
    return this.chainInstance.getLiquidityPairInfo(pairAddress, tokenA, tokenB);
  }

  async pairFor(tokenA, tokenB){
    return this.chainInstance.pairFor(tokenA, tokenB);
  }

  ethSign(signMsg, callback){
    return this.chainInstance.ethPersonalSign(signMsg, callback);
  }

  /************************* busi methods line **************************/

  mixSwap(fromToken, toToken, fromTokenAmount, returnAmount, mixAdapters, mixPairs, assetTo, directions, deadlineSeconds, data, sendCallback, confirmCallback, errorCallback){
    return this.chainInstance.mixSwap(fromToken, toToken, fromTokenAmount, returnAmount, mixAdapters, mixPairs, assetTo, directions, deadlineSeconds, data, sendCallback, confirmCallback, errorCallback);
  }

  buyField(inviterAddress, data, sendCallback, confirmCallback, errorCallback){
    return this.chainInstance.buyField(inviterAddress, data, sendCallback, confirmCallback, errorCallback);
  }


  getReserves(tokenAAddress, tokenBAddress, data, callback){
    return this.chainInstance.getReserves(tokenAAddress, tokenBAddress, data, callback);
  }

  getReservesRate(tokenAAddress, tokenBAddress, data, callback){
    return this.chainInstance.getReservesRate(tokenAAddress, tokenBAddress, data, callback);
  }

  addLiquidity(tokenAAddress, tokenBAddress, tokenAAmountDesired, tokenBAmountDesired, tokenAAmountMin, tokenBAmountMin, data, sendCallback, confirmCallback, errorCallback){
    return this.chainInstance.addLiquidity(tokenAAddress, tokenBAddress, tokenAAmountDesired, tokenBAmountDesired, tokenAAmountMin, tokenBAmountMin, data, sendCallback, confirmCallback, errorCallback);
  }

  removeLiquidity(tokenA, tokenB, pair, liquidity, amountAMin, amountBMin, data, sendCallback, confirmCallback, errorCallback){
    return this.chainInstance.removeLiquidity(tokenA, tokenB, pair, liquidity, amountAMin, amountBMin, data, sendCallback, confirmCallback, errorCallback);
  }

  getUserInfos(address, callback){
    return this.chainInstance.getUserInfos(address, callback);
  }

  getTodayGrow(address, callback){
    return this.chainInstance.getTodayGrow(address, callback);
  }

  /**
   * get field nft level by id
   * @param fieldId
   * @param callback
   * @return {*|void}
   */
  fieldLevel(fieldId, callback){
    return this.chainInstance.fieldLevel(fieldId, callback);
  }

  /**
   * get field info by id
   *
   * @param fieldId
   * @param callback
   * @return {fieldInfo}
   */
  fieldInfos(fieldId, callback){
    return this.chainInstance.fieldInfos(fieldId, callback);
  }

  /**
   * get friends by address
   * @param address
   * @param callback
   * @return {*|void}
   */
  getMyInvites(address, callback){
    return this.chainInstance.getMyInvites(address, callback);
  }

  /**
   * get swap mining lp count
   * @param callback
   * @return {*|void}
   */
  miningInfoLength(callback){
    return this.chainInstance.miningInfoLength(callback);
  }

  /**
   * get swap mining lp info by index
   * @param index
   * @param callback
   * @return {*}
   */
  miningInfos(index, callback){
    return this.chainInstance.miningInfos(index, callback);
  }

  /**
   * get my field nfts
   * @param callback
   * @return {*|void}
   */
  getMyFields(callback){
    return this.chainInstance.getMyFields(callback);
  }

  /**
   * change main fileld
   *
   * @param fieldId
   * @param data
   * @param sendCallback
   * @param confirmCallback
   * @param errorCallback
   * @return {*|void}
   */
  changeMainField(fieldId, data, sendCallback, confirmCallback, errorCallback){
    return this.chainInstance.changeMainField(fieldId, data, sendCallback, confirmCallback, errorCallback);
  }

  /**
   * get pool pair length
   *
   * @param callback
   * @return {*|void}
   */
  poolLength(callback){
    return this.chainInstance.poolLength(callback);
  }

  /**
   * get pool info by pid
   *
   * @param pid
   * @return {Promise<*>}
   */
  async getPoolInfo(pid){
    return this.chainInstance.getPoolInfo(pid);
  }

  /**
   * pledge lp
   *
   * @param poolInfo
   * @param pledgeAmount
   * @param data
   * @param sendCallback
   * @param confirmCallback
   * @param error
   */
  depositLiquidityPair(poolInfo, pledgeAmount, data, sendCallback, confirmCallback, error){
    this.chainInstance.depositLiquidityPair(poolInfo, pledgeAmount, data, sendCallback, confirmCallback, error);
  }

  /**
   * withdraw pledge liquidity pair income
   *
   * @param pid
   * @param data
   * @param sendCallback callback when transaction sended.
   * @param confirmCallback callback when transaction confirmed
   * @param errorCallback callback when error transaction.
   */
  withdrawPledgeLpIncome(pid, data, sendCallback, confirmCallback, errorCallback){
    this.chainInstance.withdrawPledgeLpIncome(pid, data, sendCallback, confirmCallback, errorCallback);
  }

  /**
   * cancel pledge liquidity pair token
   *
   * @param poolInfo
   * @param cancelAmount
   * @param data
   * @param sendCallback
   * @param confirmCallback
   * @param errorCallback
   */
  cancelPledge(poolInfo, cancelAmount, data, sendCallback, confirmCallback, errorCallback){
    this.chainInstance.cancelPledge(poolInfo, cancelAmount, data, sendCallback, confirmCallback, errorCallback);
  }
}

<template>
  <div class="selectToken">
    <div class="cpshader" @click="$emit('close')"></div>
    <div class="dialog">
      <div class="flex dheader">
        <div class="close" @click="$emit('close')"><img src="@/assets/imgs/icon_close.png"/></div>
      </div>
      <div class="dbody">
        <div class="title">Select a token <img class="qusmark" @click="showTokenTip($event)" src="@/assets/imgs/icon_qusmark.png"/></div>
        <div class="tokentip">
          <div class="direction"></div>
          <div class="ttctx">Find the token by the name or by pasting the contract address below.</div>
        </div>
        <div class="tokenquery"><input placeholder="Search name or pasts address"/></div>
        <div class="resulttitle">Token Name</div>
        <div class="resultsctx">
          <div class="flex resultitem" @click="selectToken(tokenInfo)" v-for="(tokenInfo,index) in tokenResult" :key="index">
            <div class="ritokeninfo">
              <img class="tokenlogo" :src="tokenInfo.logoURI"/>
              <span class="tokensymbol">{{ tokenInfo.symbol }}</span>
            </div>
            <div class="ritokenbalance">{{tokenInfo.balance}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { createWatcher } from "makerdao-multicall";
import * as stringUtils from "@/assets/js/stringutils";
import {mapState} from "vuex";
export default {
  name:"SelectToken",
  props:["selectTokenKey", "selectTokenSource"],
  data(){
    return {
      tokenResult:[],
      tokenTipFlag:false,

      watcher:{},
    }
  },
  computed: {
    ...mapState(['walletInfo'])
  },
  watch:{
    walletInfo:function (walletInfo) {
      this.batchBalance();
    },
    selectTokenKey:function (newVal) {
      this.batchBalance();
    },
  },
  mounted() {
    this.tokenResult=this.$appConfig.defaultTokens();
    this.batchBalance();
  },
  methods:{
    showTokenTip(event){
      if(this.tokenTipFlag){
        return ;
      }
      $(".tokentip").show();
      this.tokenTipFlag=true;
      setTimeout(()=>{
        $(".tokentip").hide();
        this.tokenTipFlag=false;
      },3000);
    },
    async batchBalance(){
      if(stringUtils.isEmpty(this.$store.state.walletInfo.address)){
        return ;
      }
      try {
        let ethIndex=-1;
        var queryList=[];
        for(let i=0;i<this.tokenResult.length;i++){
          const token=this.tokenResult[i];
          if(token.address=='0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'){
            ethIndex=i;
            continue;
          }
          queryList.push({
            target: token.address,
            call: ['balanceOf(address)(uint256)', this.$store.state.walletInfo.address],
            returns: [[i, val => val / 10 ** token.decimals]]
          });
        }
        this.watcher=createWatcher(
            queryList,
            this.$appConfig.multicallConfig()
        );

        await this.watcher.start();
        this.watcher.batch().subscribe((updates)=>{
          for (const item of updates) {
            this.tokenResult[item.type].balance=item.value;
          }
        });
        await this.watcher.stop();
        // get eth balance
        if(ethIndex>=0){
          this.$chainFactory.getTokenBalance(this.$store.state.walletInfo.address, this.tokenResult[ethIndex], this.$appConfig.defaultABI(), {},(balance)=>{
            this.tokenResult[ethIndex].balance=balance;
          });
        }
      }catch (e){
        console.info(e);
      }
    },
    selectToken(tokenInfo){
      this.$emit('selectToken', {"source":this.selectTokenSource, "token":tokenInfo});
    }
  }
}
</script>
<style lang="less" scoped>
.selectToken {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  z-index: 100;

  .cpshader {
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(#141425, 0.65);
  }

  .dialog {
    width: 560px;
    height: 500px;
    margin: 0 auto;
    background: #1D1D38;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    border: 1px solid #414183;
    padding: 10px;
    z-index: 110;
    .dheader{
      flex-direction: row-reverse;
      .close{
        cursor: pointer;
        >img{
          width: 30px;
        }
      }
    }
    .dbody{
      width: 400px;
      margin: 0 auto;
      .title{
        margin-top: 10px;
        color: #E6E6FF;
        font-size: 20px;
        font-weight: 500;
        >img{
          width: 16px;
          margin-left: 10px;
        }
      }
      .tokenquery{
        width: 100%;
        height: 60px;
        margin-top: 10px;
        border-radius: 12px;
        border: 1px solid #414183;
        >input{
          width: 100%;
          height: 100%;
          padding-left: 15px;
          color: #414183;
          font-size: 16px;
          border: none;
          outline: none;
          background: transparent;
        }
      }
      .resulttitle{
        margin-top: 20px;
        font-size: 20px;
        font-weight: 500;
        color: #E6E6FF;
      }
      .resultsctx{
        width: 100%;
        margin-top: 7px;
        .resultitem{
          width: 100%;
          font-size: 14px;
          cursor: pointer;
          padding-left: 5px;
          padding-right: 5px;
          border-radius: 5px;
          .ritokeninfo{
            width: 100%;
            height: 50px;
            line-height: 50px;
            .tokenlogo{
              width: 16px;
            }
            .tokensymbol{
              margin-left: 8px;
            }
          }
        }
        .resultitem:hover{
          border: 1px solid #414183;
          background: rgba(#414183,0.2);
        }
      }
    }
  }
  .tokentip{
    display: none;
    margin-left: 128px;
    margin-top: 5px;
    position: absolute;
    font-size: 12px;
    z-index: 110;
    .direction{
      width: 0;
      height: 0;
      margin-left: 20px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #414183;
    }
    .ttctx{
      width: 240px;
      height: 56px;
      padding: 10px;
      background: #414183;
      border-radius: 6px;
    }
  }
}
@media only screen and (max-width: 670px) {
.selectToken{
  .dialog{
    width: 335px;
    .dbody{
      width: 300px;
    }
  }
  .tokentip{
    margin-left: 50px;
    .direction{
      margin-left: 98px;
    }
  }
}
}
</style>

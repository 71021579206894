<template>
  <div class="ctx">
    <div class="liquidity">
      <div class="flex liquiditytab">
        <div @click="$router.push('/swap')">Swap</div>
        <div class="curtab">Liquidity</div>
      </div>
      <div class="liquidityctx">
        <div class="btnaddliquidity" @click="$router.push('/addLiquidity')">Add Liquidity</div>
        <div class="title">Your Liquidity</div>
        <div v-if="liquidityList.length<=0" class="noliquidity">
          <div class="nolicon"><img src="@/assets/imgs/icon_loading.png"/></div>
          <div class="noldesc">You haven't added liquidity yet.</div>
        </div>
        <div v-if="liquidityList.length>0" class="liquidityctx">
          <div class="liquidityitem" v-for="(lp, index) in liquidityList" :key="index">
            <div :id="'lisimple_'+index" class="flex lisimple" @click="showLiquidityDetail(index)">
              <div class="liqitinfo">
                <img :src="lp.liquidityTokenA.logoURI"/>
                <img :src="lp.liquidityTokenB.logoURI"/>
                <span>{{ lp.liquidityTokenA.symbol }} / {{ lp.liquidityTokenB.symbol }}</span>
              </div>
              <div class="liqitmore"><img :id="'liqitmore_'+index" :src="positionDownIcon"/></div>
            </div>
            <div :id="'lidetail_'+index" class="lidetail">
              <div class="flex liditem">
                <div class="lidititle">Pooled {{ lp.liquidityTokenA.symbol }}</div>
                <div class="lidivalue">
                  <img class="tokenlogo" :src="lp.liquidityTokenA.logoURI"/>
                  <span>{{lp.lpInfo.tokenAIn}}</span>
                </div>
              </div>
              <div class="flex liditem">
                <div class="lidititle">Pooled {{ lp.liquidityTokenB.symbol }}</div>
                <div class="lidivalue">
                  <img class="tokenlogo" :src="lp.liquidityTokenB.logoURI"/>
                  <span>{{lp.lpInfo.tokenBIn}}</span>
                </div>
              </div>
              <div class="flex liditem">
                <div class="lidititle">Your pool tokens</div>
                <div class="lidivalue">{{lp.lpInfo.userBalance}}</div>
              </div>
              <div class="flex liditem">
                <div class="lidititle">Your pool share</div>
                <div class="lidivalue">{{lp.lpInfo.userPerText}}%</div>
              </div>
              <div class="flex lidbtns">
                <div class="lidbtn" @click="toAddLiquidityAmount(index)">Add</div>
                <div class="lidbtn" @click="toRemoveLiquidity(index)">Remove</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>
<script>
import $ from "jquery";
import {mapState, mapActions} from 'vuex';

import * as stringUtils from "../assets/js/stringutils";

import positionDownIcon from "../assets/imgs/icon_position_down.png";
import positionUpIcon from "../assets/imgs/icon_position_up.png";
export default {
  name:"liquidity",
  data(){
    return {
      positionDownIcon:positionDownIcon,
      positionUpIcon:positionUpIcon,
      liquidityList:[],

    }
  },
  mounted() {
    this.getUserLPList();
  },
  computed: {
    ...mapState(['walletInfo'])
  },
  watch: {
    walletInfo:function (walletInfo) {
      this.getUserLPList();
    }
  },
  methods:{
    getUserLPList(){
      if(stringUtils.isEmpty(this.$store.state.walletInfo.address)){
        return ;
      }
      $.ajax({
        url:this.$appConfig.queryCenter().url+this.$appConfig.queryUrl().getUserLps,
        type:"get",
        data:{"address":this.$store.state.walletInfo.address},
        success:async (result)=>{
          this.liquidityList=[];
          if("200"==result.status){
            let arrData=result.data;
            for(var i=0;i<arrData.length;i++){
              const data=arrData[i];
              var tokenA=await this.$tokenList.getTokenInfoByAddress(data.token0);
              var tokenB=await this.$tokenList.getTokenInfoByAddress(data.token1);
              var lpInfo=await this.$chainFactory.getLiquidityPairInfo(data.lpAddr, tokenA, tokenB);
              this.liquidityList.push({"liquidityTokenA":tokenA, "liquidityTokenB":tokenB, "lpInfo":lpInfo});
            }
          }
        }
      })
    },
    showLiquidityDetail(index){
      if($("#lisimple_"+index).hasClass("detailstatus")){
        $("#liqitmore_"+index).attr("src", this.positionDownIcon);
        $("#lisimple_"+index).removeClass("detailstatus");
        $("#lidetail_"+index).hide();
      }else{
        $("#liqitmore_"+index).attr("src", this.positionUpIcon);
        $("#lisimple_"+index).addClass("detailstatus");
        $("#lidetail_"+index).show();
      }
    },
    toAddLiquidityAmount(index){
      this.$router.push({
        name:"addLiquidity",
        params:{
          liquidityTokenA:this.liquidityList[index].liquidityTokenA,
          liquidityTokenB:this.liquidityList[index].liquidityTokenB,
          lpInfo:this.liquidityList[index].lpInfo,
        }
      })
    },
    toRemoveLiquidity(index){
      this.$router.push({
        name:"removeLiquidity",
        params:{
          liquidityTokenA:this.liquidityList[index].liquidityTokenA,
          liquidityTokenB:this.liquidityList[index].liquidityTokenB,
          lpInfo:this.liquidityList[index].lpInfo,
        }
      })
    },
  }
}
</script>
<style lang="less" scoped>
.ctx{
  @screenHeigh: calc(~"100vh - 150px");
  min-height: @screenHeigh;
  height: max-content;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  .liquidity{
    width: 560px;
    min-height: 500px;
    height: max-content;
    margin: 0 auto;
    padding: 50px 80px;
    background: #1D1D3C;
    border-radius: 20px;
    border: 1px solid #414183;
    box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.5);
    .liquiditytab{
      width: 260px;
      height: 45px;
      margin: 0 auto;
      >div{
        font-size: 20px;
        color: #5F5FA3;
        cursor: pointer;
      }
      >div:hover{
        color: rgba(#FFFFFF, 0.8);
       }
      .curtab{
        font-size: 30px;
        color: #FFFFFF !important;
      }
    }
    .liquidityctx{
      width: 400px;
      margin: 10px auto 0;
      .btnaddliquidity{
        width: 100%;
        height: 60px;
        margin-top: 60px;
        background: #2B97A1;
        border-radius: 12px;
        font-size: 20px;
        text-align: center;
        line-height: 60px;
        cursor: pointer;
      }
      .btnaddliquidity:hover{
        background: #36BAC6;
      }
      .title{
        margin-top: 20px;
        font-size: 14px;
        color: #5F5FA3;
      }
      .noliquidity{
        margin-top: 10px;
        text-align: center;
        font-size: 14px;
        color: #5F5FA3;
        line-height: 30px;
        .nolicon{
          width: 80px;
          margin: 45px auto 0;
          >img{
            width: 80px;
          }
        }
      }
      .liquidityctx{
        width: 100%;
        height: 300px;
        overflow-y: auto;
        overflow-x: hidden;
        .liquidityitem{
          margin-bottom: 10px;
          .lisimple{
            height: 52px;
            padding: 10px;
            border-radius: 12px;
            border: 1px solid #414183;
            box-sizing: border-box;
            line-height: 52px;
            cursor: pointer;
            .liqitinfo{
              font-size: 14px;
              font-weight: 500;
              >img{
                margin-right: 10px;
                width: 16px;
              }
            }
            .liqitmore{
              >img{
                width: 30px;
              }
            }
          }
          .lisimple:hover{
            background: rgba(#414183,0.3);
          }
          .detailstatus{
            border: none;
            border-radius: 12px 12px 0 0;
            background: #24244A;
            background: rgba(#414183,0.3);
          }
          .lidetail{
            width: 100%;
            height: 190px;
            background: #24244A;
            padding: 10px;
            border-radius: 0 0 12px 12px;
            display: none;
            .liditem{
              height: 30px;
              line-height: 30px;
              font-size: 12px;
              .lidititle{
                color: #5F5FA3;
              }
              .lidivalue{
                .tokenlogo{
                  width: 16px;
                  margin-right: 10px;
                }
              }
            }
            .lidbtns{
              margin-top: 10px;
              .lidbtn{
                width: 178px;
                height: 40px;
                border-radius: 8px;
                background: #2B97A1;
                text-align: center;
                line-height: 40px;
                font-size: 14px;
                cursor: pointer;
              }
              .lidbtn:hover{
                background: #36BAC6;
              }

            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 670px) {
.ctx{
  .liquidity{
    @screenWidth: calc(~"100vw - 20px");
    width: @screenWidth;
    min-height: 600px;
    padding: 58px 20px 20px 20px;
    .liquiditytab{
      width: 200px;
      >div{
        font-size: 16px;
      }
      .curtab{
        font-size: 20px;
      }
    }
    .liquidityctx{
      width: 300px;
      .liquidityctx{
        .liquidityitem{
          .lidetail{
            .lidbtns{
              .lidbtn{
                width: 130px;
              }
            }
          }
        }
      }
    }
  }
}
}
</style>

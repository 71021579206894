import Vue from 'vue';
import App from './App.vue';
import './rem/rem';
import router from './router/index';
import store from './store/index';


import {TokenListUtil} from "./assets/js/tokenList";
import {ChainFactory} from "./assets/js/ChainFactory";
import {AppConfig} from "./config/appConfig";

Vue.config.productionTip = false;
Vue.prototype.$tokenList=TokenListUtil.getInstance();
Vue.prototype.$chainFactory=ChainFactory.getInstance();
Vue.prototype.$appConfig=AppConfig.getInstance();

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

<template>
  <div class="swapconfirmation">
    <div class="cpshader" @click="$emit('close')"></div>
    <div class="dialog">
      <div class="flex dheader">
        <div class="close" @click="$emit('close')"><img src="@/assets/imgs/icon_close.png"/></div>
      </div>
      <div class="dbody">
        <div class="loadingicon"><img src="@/assets/imgs/icon_loading.png"/></div>
        <div class="title">Waiting For Confirmation</div>
        <div class="swapdesc">{{transDesc}}</div>
        <div class="confirmdesc">Confirm this transaction in your wallet</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name:"TransConfirmation",
  props:["transDesc", "transTxnHash"],
  data(){
    return{}
  }
}
</script>
<style lang="less" scoped>
.swapconfirmation {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  z-index: 100;

  .cpshader {
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(#141425, 0.65);
  }

  .dialog {
    width: 560px;
    min-height: 410px;
    margin: 0 auto;
    background: #1D1D38;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    border: 1px solid #414183;
    padding: 10px;
    z-index: 110;

    .dheader {
      flex-direction: row-reverse;
      .close {
        cursor: pointer;
        > img {
          width: 30px;
        }
      }
    }

    .dbody {
      width: 400px;
      margin: 0 auto;
      text-align: center;
      color: #E6E6FF;
      .loadingicon{
        width: 80px;
        margin: 65px auto 0;
        >img{
          width: 80px;
          animation: rotation 4s linear infinite;
          -webkit-animation: rotation 4s linear infinite;
        }
      }
      .title{
        margin-top: 20px;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
      }
      .swapdesc{
        margin-top: 10px;
        font-size: 14px;
        line-height: 30px;
      }
      .confirmdesc{
        margin-top: 10px;
        font-size: 12px;
        color: #5F5FA3;
        line-height: 30px;
      }
    }
  }
}
@media only screen and (max-width: 670px) {
.swapconfirmation {
  .dialog{
    width: 335px;
    .dbody{
      width: 300px;
    }
  }
}
}

@keyframes rotation {
  0%{
    transform: rotate(0deg);
    -webkit-transform:rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
    -webkit-transform:rotate(360deg);
  }
}
</style>

import Vue from 'vue';
import vueRouter from 'vue-router';

import welcome from '@/views/welcome';
import index from'@/views/index';
import home from '@/views/home';
import swap from '@/views/swap';
import liquidity from '@/views/liquidity';
import addLiquidity from '@/views/addLiquidity';
import removeLiquidity from '@/views/removeLiquidity';
import feucet from '@/views/feucet';
import rank from '@/views/rank';
import account from '@/views/account';
import profile from '@/views/profile';
import points from '@/views/points';
import world from '@/views/world';

const routes = [
  {
    path: '/',
    name: 'index',
    component: index,
    children:[
      {
        path: '/',
        name: 'home',
        component: home
      },
      {
        path: '/swap',
        name: 'swap',
        component: swap
      },
      {
        path: '/liquidity',
        name: 'liquidity',
        component: liquidity
      },
      {
        path: '/addLiquidity',
        name: 'addLiquidity',
        component: addLiquidity
      },
      {
        path: '/removeLiquidity',
        name: 'removeLiquidity',
        component: removeLiquidity
      },
      {
        path: '/feucet',
        name: 'feucet',
        component: feucet
      },
      {
        path: '/rank',
        name: 'rank',
        component: rank
      },
      {
        path: '/account',
        name: 'account',
        component: account,
        children:[
          {
            path: '/profile',
            name: 'profile',
            component: profile
          },
          {
            path: '/points',
            name: 'points',
            component: points
          },
          {
            path: '/world',
            name: 'world',
            component: world
          },
        ]
      },
    ]
  },{
    path: '/welcome',
    name: 'welcome',
    component: welcome,
  }
]

Vue.use(vueRouter)

const router = new vueRouter({
  mode:'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {x: 0, y: 0 }
  }
})

export default router

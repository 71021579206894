import {devEnv} from "./env_dev";
import {proEnv} from "./env_pro";
import store from "../store/index";

export class AppConfig {
  static env="dev";

  static envConfig={};

  static getInstance() {
    if(!AppConfig.instance){
      AppConfig.envConfig=this.env=="dev"?devEnv:proEnv;
      // store.dispatch("resetSwapSetting", AppConfig.envConfig.swapSetting);

      AppConfig.instance=new AppConfig();
    }
    return AppConfig.instance;
  }

  swapSetting(){
    return AppConfig.envConfig.swapSetting;
  }

  swapGasItems(){
    return AppConfig.envConfig.swapGasItems;
  }

  swapFactory(){
    return AppConfig.envConfig.swapFactory;
  }

  swapProxy(){
    return AppConfig.envConfig.swapProxy;
  }

  swapRouter(){
    return AppConfig.envConfig.swapRouter;
  }

  swapAllowance(){
    return AppConfig.envConfig.swapAllowance;
  }

  queryCenter(){
    return AppConfig.envConfig.queryCenter;
  }

  queryUrl(){
    return AppConfig.envConfig.queryUrl;
  }

  defaultTokens(){
    return AppConfig.envConfig.defaultTokens;
  }

  defaultABI(){
    return AppConfig.envConfig.defaultABI;
  }

  defaultPairABI(){
    return AppConfig.envConfig.defaultPairABI;
  }


  WETHToken(){
    return AppConfig.envConfig.WETHToken;
  }


  liquidityPairToken(address){
    var lpToken=AppConfig.envConfig.liquidityPairToken;
    lpToken.address=address;
    return lpToken;
  }

  appNFT(){
    return AppConfig.envConfig.appNFT;
  }

  swapLiquidity(){
    return AppConfig.envConfig.swapLiquidity;
  }

  appSwapMining(){
    return AppConfig.envConfig.appSwapMining;
  }

  appPool(){
    return AppConfig.envConfig.appPool;
  }

  multicallConfig(){
    return AppConfig.envConfig.multicallConfig;
  }

}







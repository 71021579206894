<template>
  <div class="ctx">
    <div class="removeliquidity">
      <div class="aheader" @click="$router.push('/liquidity')"><img src="@/assets/imgs/icon_back.png"/></div>
      <div class="abody">
        <div class="title">
          <span>Remove Liquidity</span>
          <img @click="showTokenTip($event)" src="@/assets/imgs/icon_qusmark.png"/>
        </div>
        <div class="tokentip">
          <div class="direction"></div>
          <div class="ttctx">Find the token by the name or by pasting the contract address below.</div>
        </div>
        <div class="rmper" ref="rmper">
          <div class="desc">Amount</div>
          <div class="pertext">{{scale}}%</div>
          <div class="pro">
            <div class="probg"></div>
            <div class="propoint" ref="proPoint"></div>
          </div>
          <div class="flex peritems">
            <div :class="{'peritem':true, 'curperitem':scale==25}" @click="scale=25">25%</div>
            <div :class="{'peritem':true, 'curperitem':scale==50}" @click="scale=50">50%</div>
            <div :class="{'peritem':true, 'curperitem':scale==75}" @click="scale=75">75%</div>
            <div :class="{'peritem':true, 'curperitem':scale==100}" @click="scale=100">100%</div>
          </div>
        </div>
        <div class="sfront"><img src="@/assets/imgs/icon_down.png"/></div>
        <div class="lpinfo">
          <div class="flex tokeninfo">
            <div class="flex amount">
              <div class="tokenlogo"><img :src="liquidityTokenA.logoURI"/></div>
              <div class="tamount">{{tokenAReceiveAmount}}</div>
            </div>
            <div class="symbol">{{liquidityTokenA.symbol}}</div>
          </div>
          <div class="flex tokeninfo">
            <div class="flex amount">
              <div class="tokenlogo"><img :src="liquidityTokenB.logoURI"/></div>
              <div class="tamount">{{tokenBReceiveAmount}}</div>
            </div>
            <div class="symbol">{{liquidityTokenB.symbol}}</div>
          </div>
          <div class="lpline"></div>
          <div class="flex price">
            <div class="pricetext">Price</div>
            <div class="parep">
              <div>1 {{liquidityTokenA.symbol}} = {{price}} {{liquidityTokenB.symbol}}</div>
              <div>1 {{liquidityTokenB.symbol}} = {{revertPrice}} {{liquidityTokenA.symbol}}</div>
            </div>
          </div>
        </div>

        <div class="flex btns">
          <div @click="doApprove" :class="{'btn':true, 'grayBtn':(approveComplete || sendingTransFlag || scale<=0)}">Approve</div>
          <div @click="doRemoveLiquidity" :class="{'btn':true, 'grayBtn':(!approveComplete || sendingTransFlag || scale<=0)}">Remove</div>
        </div>
      </div>
    </div>

    <transition name="fadein" v-on:enter="++transConfirmationKey">
      <trans-confirmation v-show="transConfirmationCpFlag" v-on:close="transConfirmationCpFlag=false" :transDesc="transDesc" :transConfirmationKey="transConfirmationKey"/>
    </transition>
    <transition name="fadein" v-on:enter="++transSubmittedKey">
      <trans-submitted v-show="transSubmittedCpFlag" v-on:close="closeTransSubmitted" :transSubmittedKey="transSubmittedKey" :transTxnHash="transTxnHash"/>
    </transition>
  </div>
</template>
<script>
import BigNumber from "bignumber.js";
import $ from "jquery";

import * as stringUtils from "@/assets/js/stringutils";
import * as bignumberUtils from "../assets/js/bignumberUtils";

import TransConfirmation from "../components/TransConfirmation";
import TransSubmitted from "@/components/TransSubmitted";

export default {
  name:"removeLiquidity",
  components: {TransConfirmation, TransSubmitted},
  data(){
    return{
      barDrag:false,
      scale:0,

      liquidityTokenA:{},
      liquidityTokenB:{},
      lpInfo:{},
      tokenAReceiveAmount:0,
      tokenBReceiveAmount:0,
      weiLpAmount:0,

      amountAMin:0,
      amountBMin:0,

      price:0,
      revertPrice:0,

      approveComplete:false,

      sendingTransFlag:false,

      transConfirmationCpFlag:false,
      transConfirmationKey:0,
      transTxnHash:"",
      transDesc:"",

      transSubmittedCpFlag:false,
      transSubmittedKey:0,

      tokenTipFlag:false,
    }
  },
  watch:{
    scale: async function (newScale) {
      let newLeft=parseFloat(newScale)/100* parseFloat($(".probg").css("width"))-parseFloat($(".propoint").css("width"))/2;
      $(".propoint").css("margin-left", newLeft+"px");

      this.weiLpAmount=new BigNumber(this.lpInfo.userWeiBalance).multipliedBy(new BigNumber(newScale)).dividedBy(new BigNumber(100)).toFixed(0);
      const weiScale=new BigNumber(this.weiLpAmount).dividedBy(new BigNumber(this.lpInfo.weiTotalSupply));
      this.amountAMin=new BigNumber(this.lpInfo.pairTokenABalance).multipliedBy(new BigNumber(weiScale)).multipliedBy(new BigNumber(0.999)).toFixed(0);
      this.tokenAReceiveAmount=bignumberUtils.fromWei(this.amountAMin, this.liquidityTokenA.decimals);

      this.amountBMin=new BigNumber(this.lpInfo.pairTokenBBalance).multipliedBy(new BigNumber(weiScale)).multipliedBy(new BigNumber(0.999)).toFixed(0);
      this.tokenBReceiveAmount=bignumberUtils.fromWei(this.amountBMin, this.liquidityTokenB.decimals);
    }
  },
  mounted(){
    if (!this.$route.params.liquidityTokenA && !this.$route.params.liquidityTokenB) {
      this.$router.push("/liquidity");
      return ;
    }
    this.liquidityTokenA = this.$route.params.liquidityTokenA;
    this.liquidityTokenB = this.$route.params.liquidityTokenB;
    this.lpInfo = this.$route.params.lpInfo;
    console.info(JSON.stringify(this.lpInfo));

    this.onMoveBar();

    this.resetPrice();

    this.checkApproved();
  },
  methods:{
    showTokenTip(event){
      if(this.tokenTipFlag){
        return ;
      }
      $(".tokentip").show();
      this.tokenTipFlag=true;
      setTimeout(()=>{
        $(".tokentip").hide();
        this.tokenTipFlag=false;
      },3000);
    },
    onMoveBar(){
      this.$refs.proPoint.onmousedown=(e)=>{
        this.barDrag=true;
        var disx=$(".probg").offset().left;
        document.onmouseup=(e)=> {
          this.barDrag=false;
        }

        document.addEventListener("mousemove", (e)=>{
          if(!this.barDrag){
            return ;
          }
          var newLeft=e.clientX-disx;
          let tempScale=Math.ceil(parseFloat(newLeft)/parseFloat($(".probg").css("width"))*100) ;
          if(tempScale>100 || tempScale<0){
            return;
          }
          this.scale=tempScale;



          // $(".propoint").css("margin-left", newLeft+"px");
        },true);
      }
    },
    resetPrice(){
      if(stringUtils.isEmpty(this.liquidityTokenA.symbol) || stringUtils.isEmpty(this.liquidityTokenB.symbol)){
        return ;
      }
      this.$chainFactory.getReservesRate(this.liquidityTokenA.address, this.liquidityTokenB.address, {}, (data)=>{
        this.price=data.rate;
        this.revertPrice=new BigNumber(new BigNumber(1).dividedBy(this.price).toFixed(10));
      });
    },
    checkApproved(){
      let walletInfo=this.$store.state.walletInfo;
      if(stringUtils.isEmpty(walletInfo.address)){
        this.approveComplete=false;
        return false;
      }
      let pairTokenInfo={"address":this.lpInfo.address, "decimals":18};
      this.$chainFactory.getTokenAllowance(walletInfo.address, pairTokenInfo, this.$appConfig.defaultPairABI(), this.$appConfig.swapAllowance().contract,{}, (allowance)=>{
        let x= new BigNumber(this.lpInfo.userBalance).multipliedBy(new BigNumber(this.scale)).dividedBy(new BigNumber(100)).toFixed(6);
        if(allowance<100 || allowance<new BigNumber(x)){
          this.approveComplete=false;
        }else{
          this.approveComplete=true;
        }
      });
    },
    doApprove(){
      if(this.approveComplete){
        return ;
      }
      this.sendingTransFlag=true;
      let lpContract=this.$chainFactory.getNormalContract(this.lpInfo.address);
      this.$chainFactory.approve(lpContract, this.$appConfig.swapAllowance().contract, "max", {}, (contract, toAddress, data)=>{
        console.info("remove liquidity approve send...")
      },(contract, toAddress, data)=>{
        this.approveComplete=true;
        this.sendingTransFlag=false
      },(contract, toAddress, data)=>{
        this.sendingTransFlag=false;
      });
    },
    async doRemoveLiquidity(){
      this.sendingTransFlag=true;
      const weiScale=new BigNumber(this.weiLpAmount).dividedBy(new BigNumber(this.lpInfo.weiTotalSupply));

      const tokenAContract=this.$chainFactory.getNormalContract(this.liquidityTokenA.address);
      const pairTokenABalance=await tokenAContract.methods.balanceOf(this.lpInfo.realAddress).call();
      this.amountAMin=new BigNumber(pairTokenABalance).multipliedBy(new BigNumber(weiScale)).multipliedBy(new BigNumber(0.999)).toFixed(0);

      const tokenBContract=this.$chainFactory.getNormalContract(this.liquidityTokenB.address);
      const pairTokenBBalance=await tokenBContract.methods.balanceOf(this.lpInfo.realAddress).call();
      this.amountBMin=new BigNumber(pairTokenBBalance).multipliedBy(new BigNumber(weiScale)).multipliedBy(new BigNumber(0.999)).toFixed(0);

      this.$chainFactory.removeLiquidity(this.liquidityTokenA.address, this.liquidityTokenB.address, this.lpInfo.address, this.weiLpAmount, this.amountAMin, this.amountBMin, {}, (data)=>{
        this.transDesc="Remove Liquidity, you will receive "+this.tokenAReceiveAmount+" "+this.liquidityTokenA.symbol+" and "+this.tokenBReceiveAmount+" "+this.liquidityTokenB.symbol+".";
        this.transTxnHash=data.txnHash;
        this.transConfirmationCpFlag=true;
      }, (data)=>{
        this.sendingTransFlag=false;
        this.transConfirmationCpFlag=false;
        this.transTxnHash=data.txnHash;
        this.transSubmittedCpFlag=true;
      }, (error)=>{
        this.sendingTransFlag=false;
      });
    },
    closeTransSubmitted(){
      this.transSubmittedCpFlag=false;
      this.$router.push("/liquidity");
    }
  }
}
</script>
<style lang="less" scoped>
.ctx {
  @screenHeigh: calc(~"100vh - 130px");
  height: @screenHeigh;
  display: flex;
  align-items: center;
  .removeliquidity {
    width: 560px;
    min-height: 520px;
    height: max-content;
    margin: 0 auto;
    background: #1D1D3C;
    border-radius: 20px;
    border: 1px solid #414183;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.5);
    .aheader {
      cursor: pointer;
      > img {
        width: 30px;
        margin-top: 10px;
        margin-left: 10px;
      }
    }
    .abody {
      width: 400px;
      margin: 10px auto 0;
      .title {
        text-align: center;
        font-size: 30px;
        margin-bottom: 10px;
        > img {
          cursor: pointer;
          width: 16px;
          margin-left: 10px;
        }
      }
      .rmper{
        width: 100%;
        background: #24244A;
        border-radius: 6px;
        padding: 10px;
        .desc{
          font-size: 14px;
          color: #5F5FA3;
          line-height: 30px;
        }
        .pertext{
          color: #FFFFFF;
          font-size: 30px;
          line-height: 50px;
        }
        .pro{
          .probg{
            width: 100%;
            height: 4px;
            margin-top: 26px;
            background: #414183;
            border-radius: 2px;
          }
          .propoint{
            width: 20px;
            height: 20px;
            margin-top: -10px;
            border-radius: 10px;
            background: #FFFFFF;
          }
        }
        .peritems{
          margin-top: 20px;
          .peritem{
            width: 86px;
            height: 30px;
            color: #7676B1;
            text-align: center;
            line-height: 30px;
            font-size: 12px;
            background: #414183;
            border-radius: 6px;
            cursor: pointer;
          }
          .curperitem{
            background: #2B97A1;
            color: #FFFFFF;
          }
        }
      }
      .sfront{
        width: 30px;
        margin: 10px auto 10px;
        >img{
          width: 30px;
        }
      }

      .lpinfo{
        background: rgba(#414183, 0.2);
        border-radius: 6px;
        padding: 10px;
        .tokeninfo{
          .amount{
            font-size: 14px;
            line-height: 30px;
            color: #FFFFFF;
            .tokenlogo{
              >img{
                width: 16px;
              }
            }
            .tamount{
              margin-left: 8px;
            }
          }
          .symbol{
            font-size: 12px;
            color: #5F5FA3;
            line-height: 30px;
          }
        }
        .lpline{
          width: 100%;
          height: 1px;
          margin: 10px 0 10px 0;
          background: #2F2F5A;
        }
        .price{
          align-items: flex-start;
          font-size: 12px;
          .pricetext{
            color: #5F5FA3;
            line-height: 30px;
          }
          .parep{
            color: #7676B1;
            >div{
              text-align: right;
              line-height: 30px;
            }
          }
        }
      }

      .btns{
        margin-top: 20px;
        margin-bottom: 20px;
        .btn{
          width: 190px;
          height: 50px;
          background: #2B97A1;
          border-radius: 12px;
          font-size: 20px;
          color: #FFFFFF;
          font-weight: 400;
          text-align: center;
          line-height: 50px;
          cursor: pointer;
        }
      }

    }
  }
  .tokentip{
    display: none;
    margin-left: 180px;
    margin-top: 5px;
    position: absolute;
    font-size: 12px;
    z-index: 110;
    .direction{
      width: 0;
      height: 0;
      margin-left: 140px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #414183;
    }
    .ttctx{
      width: 240px;
      height: 56px;
      padding: 10px;
      background: #414183;
      border-radius: 6px;
    }
  }
}
@media only screen and (max-width: 670px) {
.ctx{
  .removeliquidity{
    width: 335px;
    .abody{
      width: 300px;
      .rmper{
        .peritems{
          .peritem{
            width: 60px;
          }
        }
      }
      .btns{
        .btn{
          width: 140px;
        }
      }
    }
  }
  .tokentip{
    margin-left: 80px;
    .direction{
      margin-left: 190px;
    }
  }
}
}
</style>

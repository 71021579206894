
export const arrChainInfo = {
  ETH: {
    info:{
      chainId: '0x1',
      chainName: 'Ethereum',
      nativeCurrency: {
        name: "Ethereum",
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: ['https://cloudflare-eth.com/'],
      blockExplorerUrls: ['https://etherscan.io/'],
    },
    multicall:"0xcA11bde05977b3631167028862bE2a173976CA11",
    network: 'Ethereum',
  },
  Arbitrum: {
    info:{
      chainId: '0xA4B1',
      chainName: 'Arbitrum One',
      nativeCurrency: {
        name: "Ethereum",
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls:["https://arb1.arbitrum.io/rpc/"],
      blockExplorerUrls: ['https://arbiscan.io/'],
    },
    multicall:"0xcA11bde05977b3631167028862bE2a173976CA11",
    network: 'arbitrum',
  },
  ArbitrumGoerli:{
    info:{
      chainId: '0x66EED',
      chainName: 'Arbitrum Goerli',
      nativeCurrency: {
        name: "Arbitrum Goerli Ether",
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls:['https://goerli-rollup.arbitrum.io/rpc'],
      blockExplorerUrls: ['https://testnet.arbiscan.io/'],
    },
    multicall:"0xcA11bde05977b3631167028862bE2a173976CA11",
    network: "arbitrum-goerli",
  },
  BSC: {
    info:{
      chainId: '0x38',
      chainName: 'BNB Smart chain',
      nativeCurrency: {
        name: "BNB Smart chain",
        symbol: 'BNB',
        decimals: 18,
      },
      rpcUrls: ['https://koge-rpc-bsc.bnb48.club/'],
      blockExplorerUrls: ['https://bscscan.com/'],
    },
    multicall:"0xcA11bde05977b3631167028862bE2a173976CA11",
    network: 'BSC',
  },
  ScrollAlpha: {
    info:{
      chainId: '0x82751',
      chainName: 'Scroll Alpha Testnet',
      nativeCurrency: {
        name: "Scroll Alpha Testnet",
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: ['https://alpha-rpc.scroll.io/l2/'],
      blockExplorerUrls: ['https://blockscout.scroll.io/'],
    },
    multicall:"0x6142C5333f7Ef60f9A1D2b75A91E18Fb21D88274",
    network: 'Scroll Alpha Testnet',
  },
  Polygon: {
    info:{
      chainId: '0x89',
      chainName: 'Polygon',
      nativeCurrency: {
        name: "Matic Token",
        symbol: 'MATIC',
        decimals: 18,
      },
      rpcUrls: ['https://polygon-rpc.com/'],
      blockExplorerUrls: ['https://polygonscan.com/'],
    },
    multicall:"0xcA11bde05977b3631167028862bE2a173976CA11",
    network: 'Polygon',
  },
  Optimistic: {
    info:{
      chainId: '0xA',
      chainName: 'Optimistic Ethereum',
      nativeCurrency: {
        name: "Ethereum",
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: ['https://mainnet.optimism.io/'],
      blockExplorerUrls: ['https://optimistic.etherscan.io/'],
    },
    multicall:"0xcA11bde05977b3631167028862bE2a173976CA11",
    network: 'Optimistic Ethereum',
  },

}
